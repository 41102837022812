import React from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
// import Timeline from "@material-ui/icons/Timeline";
// import Code from "@material-ui/icons/Code";
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import Email from "@material-ui/icons/Email";
import CodeIcon from '@material-ui/icons/Code';
import Check from "@material-ui/icons/Check";

// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function RegisterPage() {
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("")
  // const [modal, setModal] = React.useState(false);
  const [confirmModal, setConfirmModal] = React.useState(false);
  // const [code, setCode] = React.useState("");
  
  const [keyError, setKeyError] = React.useState("");
  const [keyErrorState, setKeyErrorState] = React.useState(false);

  const [keyMismatch, setKeyMismatch] = React.useState("");
  const [keyMismatchState, setKeyMismatchState] = React.useState(false);

  const [validationErrors, setValidationErrors] = React.useState({});

  React.useEffect(() => {
    // console.log(props.stageId);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const validate = () => {
    const err = {};
    const requiredFields = [
      email,
      password,
      confirmPassword,
      code
    ];
    requiredFields.forEach(field => {
      if(!field) {
        err[field]="Required"
      }
    });
    if(email&&  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
     err[email] = 'Invalid email address'
    }
    setValidationErrors(err);
    if (Object.keys(err).length>0 || keyMismatchState || keyErrorState) {
      return false;
    } else {
      return true
    };
  }
  
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const hideAlert = () => {
    setAlert(null);
  };

  const cognitoRegister = async (e) => {
    if (validate()) {
      
    e.preventDefault();
    console.log("in register");
    Auth.signUp({
      username: email,
      password,
      attributes: {
        'email': email,
        'given_name': firstName,
        'family_name': lastName,
        'phone_number': phone
      },
      validationData:[{
        Name: 'code',
        Value: code
      }]
    })
    .then((user) => {
      console.log("register done");
      // console.log(user);
      console.log(email);
      // inputAlert();
      // setModal(true);
      history.push("/auth/verification-page", {email});
    })
    .catch((err) => {
      console.log(err)
      const message = err.message.split(":")
       setErrorMessage(message[message.length - 1]);
       basicAlert();
    });
  }
  }

  const basicAlert = async () => {
    let alertMessage;
    await setErrorMessage((errorMessage)=>{
      alertMessage=errorMessage;
      return errorMessage;
    }) 
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={alertMessage}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.warning}
      />
    );
  };

  const testPassword = (e)=> {
    const pass = e.target.value
    if (!/[A-Z]+/g.test(pass)){
      setKeyErrorState(true);
      setKeyError("Password must have uppercase characters.")
    } else if (!/\d+/g.test(pass)){
      setKeyErrorState(true);
      setKeyError("Password must have numbers.")
    } else if (!/[!@#$%^&*(),.?":{}|<>]+/g.test(pass)){
      setKeyErrorState(true);
      setKeyError("Password must have special characters.")
    } else if (pass.length<8) {
      setKeyErrorState(true);
      setKeyError("Password must have length greater than or equal to 8.")
    } else if(!/[a-z]+/g.test(pass)){
      setKeyErrorState(true);
      setKeyError("Password must have lowercase characters.")
    } else if (keyErrorState!==false) {
      setKeyErrorState(false);
      setKeyError("")
    }
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {alert}
      <GridContainer justify="center">
        <GridItem sm={12} md={8} lg={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter} `}
                color="quSandBox"
              >
                <h4 className={classes.cardTitle}>Register</h4>
                
              </CardHeader>
              <CardBody>

                <CustomInput
                  labelText="First Name"
                  id="first-name"
                  onChange={(e) => setFirstName(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PersonIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "text",
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  labelText="Last Name"
                  id="last-name"
                  onChange={(e) => setLastName(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PersonIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "text",
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  labelText="Email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  helperText={validationErrors[email]}
                  error={validationErrors[email]|| false}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Phone Number"
                  id="phone-number"
                  onChange={(e) => setPhone("+1"+e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PhoneIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "number",
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  onChange={(e) => {
                    testPassword(e)
                    setPassword(e.target.value)
                  }}
                  error={validationErrors[password] || keyErrorState }
                  formControlProps={{
                    fullWidth: true
                  }}
                  helperText={validationErrors[password] || keyError}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  labelText="Confirm Password"
                  id="confirm-password"
                  error={validationErrors[confirmPassword] || keyMismatchState}
                  onChange={(e) => {
                    if(password!== e.target.value) {
                      setKeyMismatch("Passwords don't match")
                      setKeyMismatchState(true);
                    }
                    else if (keyMismatchState!==false) {
                      setKeyMismatch("")
                      setKeyMismatchState(false)
                    }
                    setConfirmPassword(e.target.value)}}
                  helperText={validationErrors[confirmPassword] || keyMismatch}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Check className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  labelText="Code"
                  id="code"
                  onChange={(e) => setCode(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  helperText={validationErrors[code]}
                  error={validationErrors[code]|| false}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CodeIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button onClick={cognitoRegister} color="rose" simple size="lg" block>
                  Create Account
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>

        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall
          }}
          open={confirmModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setConfirmModal(false)}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={
              classes.modalBody + " " + classes.modalSmallBody
            }
          >
            <h3>Email verified successfully</h3>
          </DialogContent>
          <DialogActions
            className={
              classes.modalFooter + " " + classes.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                history.push("/auth/login-page");
              }}
              color="danger"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Go To Login
            </Button>
          </DialogActions>
        </Dialog>

    </div>
  );
}
