import UserAPI from "views/APIs/UserAPI.js";

const getTemplates = async (permission="access", projectId=null) => {
    try {
        const token = await UserAPI.getToken();
        var url = process.env.REACT_APP_BACK_END_URL + "/template?permission=" + permission
        if (permission == "project" && projectId) {
            url = url + '&projectId=' + projectId
        }
        const response = await fetch(url, {
            method: "GET",
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const json = await response.json();
        return json.Items
    } catch (error) {
        console.log(error);
        return []
    }
}

const getTemplate = async (id, projectId=null) => {
    try {
        const token = await UserAPI.getToken();
        var url = process.env.REACT_APP_BACK_END_URL + "/template/" + id
        if (projectId) {
            url = url + '?projectId=' + projectId
        }
        const response = await fetch(url, {
            method: "GET",
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const json = await response.json();
        return json.Items[0]
    } catch (error) {
        console.log(error);
        return []
    }
}

const createTemplate = async (template) => {
    try {
        const token = await UserAPI.getToken();
        const response = await fetch(process.env.REACT_APP_BACK_END_URL + "/template", {
            method: "POST",
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify(template)
        })
        const json = await response.json();
        console.log(json)
        return json['Items'][0]
    } catch (error) {
        console.log(error);
    }
}

const updateTemplate = async (template) => {
    try {
        const token = await UserAPI.getToken();
        const response = await fetch(process.env.REACT_APP_BACK_END_URL + "/template", {
            method: "PUT",
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify(template)
        })
        const json = await response.json();
        return json['Items'][0]
    } catch (error) {
        console.log(error);
    }
}

const deleteTemplate = async (template) => {
    try {
        const token = await UserAPI.getToken();
        const response = await fetch(process.env.REACT_APP_BACK_END_URL + "/template/" + template.SK.split('#')[1], {
            method: "DELETE",
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        return true
    } catch (error) {
        console.log(error);
        return false
    }
}

const TemplateAPI = {
    getTemplate:getTemplate,
    getTemplates: getTemplates,
    createTemplate: createTemplate,
    updateTemplate: updateTemplate,
    deleteTemplate: deleteTemplate
}

export default TemplateAPI;