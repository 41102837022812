import React from 'react';

const NotificationContext = React.createContext(null);
const ExperimentContext = React.createContext({})
const ProjectContext = React.createContext({})

export {
    NotificationContext,
    ExperimentContext,
    ProjectContext
};