import React, { useEffect } from "react";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ExtensionIcon from '@material-ui/icons/Extension';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CircularProgress,
} from "@material-ui/core";
// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { NotificationContext, ExperimentContext, ProjectContext } from "views/Components/Context.js";
// packages
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import SwipeableViews from "react-swipeable-views";

import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
import tilesStyle from "assets/jss/material-dashboard-pro-react/components/tilesStyle.js";

// import SurveyCreator from "views/Components/SurveyCreator.js";
import SummaryStage from "views/Components/Stages/SummaryStage.js";
import DataStage from "views/Components/Stages/DataStage.js";
import DeploymentStage from "views/Components/Stages/DeploymentStage.js";
import EnvironmentStage from "views/Components/Stages/EnvironmentStage.js";
import ExplainabilityStage from "views/Components/Stages/ExplainabilityStage.js";
import FairnessStage from "views/Components/Stages/FairnessStage.js";
import FindingsStage from "views/Components/Stages/FindingsStage.js";
import IssuesStage from "views/Components/Stages/IssuesStage.js";
import ModelStage from "views/Components/Stages/ModelStage.js";
import MonitoringStage from "views/Components/Stages/MonitoringStage.js";
import RecommendationsStage from "views/Components/Stages/RecommendationsStage.js";
import ScenarioTestsStage from "views/Components/Stages/ScenarioTestsStage.js";
import StressTestsStage from "views/Components/Stages/StressTestsStage.js";
import TestPlanStage from "views/Components/Stages/TestPlanStage.js";
import WhatIFAnalysisStage from "views/Components/Stages/WhatIFAnalysisStage.js";
import ScoreStage from "views/Components/Stages/ScoreStage.js";
import AuditStage from "views/Components/Stages/AuditStage.js";
import TemplateStage from "views/Components/Stages/TemplateStage.js";
import GeneralReportStage from "views/Components/Stages/GeneralReportStage.js";
import MLSecurityStage from "views/Components/Stages/MLSecurityStage.js";
import MLThreatStage from "views/Components/Stages/MLThreatStage.js";
import FailureModeStage from "views/Components/Stages/FailureModeStage.js";

import IconDict from "variables/IconDict.js"
import defaultProjectStructure from "variables/defaultProjectStructure.js"

const useStyles = makeStyles(styles);
const useTileStyles = makeStyles(tilesStyle);

function ExperimentDetails(props) {
  const images = require.context("assets/img", true);
  const classes = useStyles();
  const tileclasses = useTileStyles();

  const { showNotification } = React.useContext(NotificationContext)

  const {
    projectTemplate: projectTemplate
  } = React.useContext(ProjectContext)

  const [expStructure, setExpStructure] = React.useState([]);

  const [expLoading, setExpLoading] = React.useState(true);
  const [projectLoading, setProjectLoading] = React.useState(true);

  // #########################
  const [tileActive, setTileActive] = React.useState(0);
  const [groupsActive, setGroupsActive] = React.useState([0]);

  const { projectID, exp_id } = props.match.params;

  const [project, setProject] = React.useState({});
  const [expData, setExpData] = React.useState({});
  const [experimentJson, setExperimentJson] = React.useState();
  const [stages, setStages] = React.useState({});

  const [userType, setUserType] = React.useState("");
  const [user, setUser] = React.useState({});

  const [allNotes, setAllNotes] = React.useState({});
  const [allIssues, setAllIssues] = React.useState({});

  const setNotesHelper = (stageType, data) => {
    setAllNotes(prevState => ({
      ...prevState,
      [stageType]: data
    }))
  }

  const setIssuesHelper = (stageType, data) => {
    setAllIssues(prevState => ({
      ...prevState,
      [stageType]: data
    }))
  }

  const search = useLocation().search;
  const reportid = new URLSearchParams(search).get("report");
  const stageParam = new URLSearchParams(search).get("stage");

  const getHTML = (index, setHTML, obj, bucket = "qu-artifact-sample") => {
    console.log(obj)
    var url = `https://${bucket}.s3-us-west-2.amazonaws.com/${exp_id}/${obj.PK.split("#")[1]
      }/${obj.Reports[index]._id}.html`
    if (obj.Reports[index].ObjectName) {
      url =
        `https://${bucket}.s3-us-west-2.amazonaws.com/${obj.Reports[index].ObjectName}`
    }
    fetch(
      url,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        cache: "no-cache",
      }
    ).then((res) => {
      console.log(res)
      res.text().then((data) => {
        setHTML(data);
      });
    })
  };

  const experimentContextValues = {
    projectID: projectID,
    exp_id: exp_id,
    experimentStructure: [expStructure, setExpStructure],
    expData: [expData, setExpData],
    experimentJson: [experimentJson, setExperimentJson],
    project: [project, setProject],
    PERMISSION: ["read", "clone", "write", "audit", "own"],
    user: user,
    allNotes: [allNotes, setNotesHelper],
    allIssues: [allIssues, setIssuesHelper],
    stages: [stages, setStages],
    stageParam: stageParam,
    reportid: reportid,
    getHTML: getHTML
  }

  useEffect(() => {

    if (stageParam && expStructure) {
      console.log(expStructure)
      let counter = -1
      for (let group = 0; group < expStructure.length; group++) {
        for (let stage in expStructure[group].stages) {
          counter++;
          if (expStructure[group].stages[stage].name == stageParam) {
            setTileActive(counter)
            setGroupsActive([group])
            return
          }
        }
      }
      // setTileActive(expStructure.findIndex(o => o.name == stage))
    }
  }, [expStructure])

  useEffect(() => {
    setExpLoading(true);
    if (Object.keys(user).length > 0) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/experiment/" + exp_id, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => {
          res.json().then((data) => {
            let tmp = {}
            data.Items.forEach((item) => {
              if (item.PK.startsWith("PROJECT#")) {
                if (item.experimentTemplate) {
                  setExpStructure(JSON.parse(item.experimentTemplate));
                } else if (projectTemplate) {
                  setExpStructure(projectTemplate);
                } else {
                  setExpStructure(defaultProjectStructure);
                }
                setExpData(item);
                setExperimentJson(JSON.parse(item.json));
                let userType = user.signInUserSession.accessToken.payload["cognito:groups"] ? user.signInUserSession.accessToken.payload["cognito:groups"][0] : ""
                if (item.experimentType == 'clone' && userType !== 'admin') {
                  showNotification("The " + item.experimentName + " experiment you open is a display only demo experiment, you are unable to edit anything. Please go back and clone this experiment to try the functions", "quSandBoxColor", 10000);
                }
              } else {
                if (item.hasOwnProperty('StageType')) {
                  tmp[item.StageType] = item
                }
              }
            });
            setStages(tmp);
            setExpLoading(false);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  // ######################
  // Fetching notes
  useEffect(() => {
    setProjectLoading(true);
    if (Object.keys(user).length > 0) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + `/experiment/${exp_id}/note`, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            // console.log(data);
            // setAllNotes(data.Items);
            var tmp = {}
            data.Items.map((note) => {
              if (tmp.hasOwnProperty(note.StageType)) {
                tmp[note.StageType].push(note)
              } else {
                tmp[note.StageType] = [note]
              }
            });
            setAllNotes(tmp)
          });
        },
        function (err) {
          console.log(err);
        }
      );
    }
  }, [user]);


  // Fetching issues
  useEffect(() => {
    setProjectLoading(true);
    if (Object.keys(user).length > 0) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + `/experiment/${exp_id}/issue`, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            // setIssuesArray([...data.Items])
            var tmp = {}
            data.Items.map((issue) => {
              if (tmp.hasOwnProperty(issue.StageType)) {
                tmp[issue.StageType].push(issue)
              } else {
                tmp[issue.StageType] = [issue]
              }
            });
            setAllIssues(tmp)
          });
        },
        function (err) {
          console.log(err);
        }
      );
      // setAllIssues({'Summary':[{'PK':'a','SK':'b','aspects':'aspect','impacts':'impacts','occurrence':'3','score':'3','controls':'controls','newOccurrence':'3','newScore':'3'}]})
    }
  }, [user]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setUserType(
          user.signInUserSession.accessToken.payload["cognito:groups"] ? user.signInUserSession.accessToken.payload["cognito:groups"][0] : ""
        );
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login";
        }
      });
  }, []);


  useEffect(() => {
    setProjectLoading(true);
    if (props.location.state) {
      const { project } = props.location.state;
      setProject(project);
      setProjectLoading(false);
    } else {
      if (Object.keys(user).length > 0) {
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(
          process.env.REACT_APP_BACK_END_URL +
          `/project/${projectID}?type=project`,
          {
            method: "GET",
            mode: "cors",
            credentials: "omit",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        ).then(
          function (res) {
            res.json().then(function (data) {
              setProject(data.Items[0]);
              setProjectLoading(false);
            });
          },
          function (err) {
            console.log(err);
          }
        );
      }
    }
  }, [user]);


  const formatContent = (stage) => {
    switch (stage.type) {
      case "Summary":
        return <SummaryStage config={stage}></SummaryStage>
      case "Environment":
        return <EnvironmentStage config={stage}></EnvironmentStage>;
      case "Audit":
        return <GeneralReportStage config={stage}></GeneralReportStage>;
      // case "Explore":
      //   return ExploreCard();
      // case "Pipeline":
      //   return PipelineCard();
      case "Data":
        return <DataStage config={stage}></DataStage>;
      case "Model":
        return <ModelStage config={stage}></ModelStage>;
      case "Explainability":
        return <ExplainabilityStage config={stage}></ExplainabilityStage>;
      case "Fairness":
        return <FairnessStage config={stage}></FairnessStage>;
      case "Deployment":
        return <DeploymentStage config={stage}></DeploymentStage>;
      case "Monitoring":
        return <MonitoringStage config={stage}></MonitoringStage>;
      case "Report":
        switch (stage.name) {
          case "Risk":
            return <GeneralReportStage config={stage} index={tileActive}></GeneralReportStage>
          // case "Tuv":
          //   return <GeneralReportStage config={stage} index={tileActive}></GeneralReportStage>
          case "Security":
            return <GeneralReportStage config={stage} index={tileActive}></GeneralReportStage>
          case "Functional":
            return <GeneralReportStage config={stage} index={tileActive}></GeneralReportStage>
          case "Ethics":
            return <GeneralReportStage config={stage} index={tileActive}></GeneralReportStage>
          default:
            return <GeneralReportStage config={stage} index={tileActive}></GeneralReportStage>
        }
      case "Static":
        switch (stage.name) {
          case "Templates":
            return <TemplateStage config={stage} index={tileActive}></TemplateStage>
        }
      case "Issues":
        return <IssuesStage config={stage}></IssuesStage>;
      case "Findings":
        return <FindingsStage config={stage}></FindingsStage>;
      case "Recommendations":
        return <RecommendationsStage config={stage}></RecommendationsStage>;
      case "TestPlan":
        return <TestPlanStage config={stage}></TestPlanStage>;
      case "StressTests":
        return <StressTestsStage config={stage}></StressTestsStage>;
      case "ScenarioTests":
        return <ScenarioTestsStage config={stage}></ScenarioTestsStage>;
      case "WhatIfAnalysis":
        return <WhatIFAnalysisStage config={stage}></WhatIFAnalysisStage>;
      case "MLThreat":
        return <MLThreatStage config={stage}></MLThreatStage>;
      case "MLSecurity":
        return <MLSecurityStage config={stage}></MLSecurityStage>;
      case "FailureMode":
        return <FailureModeStage config={stage}></FailureModeStage>;
    }
  }

  const getTabs = () => {
    return [
      {
        tabButton: "Project",
        tabContent: (
          <span>
            <p className={classes.summaryTab}>
              <b>Project Name: </b>
              {project.projectName}
            </p>

            <p className={classes.summaryTab}>
              <b>Project Description: </b>
              {project.projectDescription}
            </p>
            <p className={classes.summaryTab}>
              <b>Project Brief Description: </b>
              {project.projectBrief}
            </p>
            <p className={classes.summaryTab}>
              <b>Project ID: </b>
              {projectID}
            </p>
            <p className={classes.summaryTab}>
              <b>Project Version: </b>
              {project.projectVersion}
            </p>
          </span>
        ),
      },
      {
        tabButton: "Experiment",
        tabContent: (
          <span>
            <p className={classes.summaryTab}>
              <b>Experiment Name: </b>
              {expData.experimentName}
            </p>
            <p className={classes.summaryTab}>
              <b>Experiment Description: </b>
              {expData.experimentDescription}
            </p>
            <p className={classes.summaryTab}>
              <b>Experiment Brief Description: </b>
              {expData.experimentBrief}
            </p>
            <p className={classes.summaryTab}>
              <b>Experiment ID: </b>
              {exp_id}
            </p>
            <p className={classes.summaryTab}>
              <b>Experiment Version: </b>
              {expData.experimentVersion}
            </p>
          </span>
        ),
      },
    ];
  };

  const formatSummary = (stage) => {
    return (
      stage.name == 'Summary' ? <></> :
        <Card
          className={classes.card}
        // style={{ marginBottom: "30px" }}
        >
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={9} lg={10}>
                <NavPills
                  color="quSandBoxColor"
                  horizontal={{
                    tabsGrid: { xs: 12, sm: 12, md: 4 },
                    contentGrid: { xs: 12, sm: 12, md: 8 },
                  }}
                  tabs={[
                    ...getTabs(),
                    {
                      tabButton: stage.name,
                      tabContent: <span></span>,
                    },
                  ]}
                />
              </GridItem>
              <GridItem xs={12} md={3} lg={2}>
                <h3 className={classes.cardTitle}>
                  <small>
                    <img
                      src={images("./qusandbox_logo_v2.5.png")}
                      alt=""
                      className={classes.thumbnail}
                    />
                  </small>
                </h3>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
    )
  }

  const formatIcon = (stage, counter = 0) => {
    var IconObject
    console.log(stage)
    if (stage.type == "Report") {
      let index = counter % IconDict["Report"].length
      IconObject = IconDict["Report"][index]
    } else if (IconDict.hasOwnProperty(stage.type)) {
      IconObject = IconDict[stage.type]
    } else {
      IconObject = ExtensionIcon
    }
    return (<IconObject className={tileclasses.tabIcon} />)
  }

  let summaryCounter = -1;
  let tabCounter = -1;
  let contentCounter = -1;

  // const expandGroup = (groupIndex) => {
  //   setGroupsActive([...groupsActive, groupIndex])
  // }

  // const contractGroup = (groupIndex) => {
  //   var index = groupsActive.indexOf(groupIndex);
  //   let tmp = [...groupsActive]
  //   if (index > -1) {
  //     tmp.splice(index, 1);
  //   }
  //   setGroupsActive(tmp)
  // }

  const clickGroup = (groupIndex) => {
    if (groupsActive.includes(groupIndex)) {
      var index = groupsActive.indexOf(groupIndex);
      let tmp = [...groupsActive]
      tmp.splice(index, 1);
      setGroupsActive(tmp)
    } else {

      setGroupsActive([...groupsActive, groupIndex])
    }
  }

  return (
    <div>
      {expLoading || projectLoading ? (
        <Heading title={<CircularProgress />} textAlign="center" />
      ) : (
        // <Tiles
        //   active={tileActive}
        //   color="warning"
        //   alignCenter
        //   tabs={expStructure.length != 0 ? formatLayout() : defaultLayout()}
        // />
        <>
          <SwipeableViews
            axis="x"
            index={tileActive}
            style={{ overflowY: "hidden", marginBottom: "15px" }}
            animateHeight={true}
          >
            {expStructure && expStructure.map((group, groupIndex) => {
              return (group.stages.map((stage, stageIndex) => {
                summaryCounter++
                return (
                  summaryCounter == tileActive && stage.visible ? <div className={tileclasses.tabContent} key={summaryCounter}>
                    {formatSummary(stage)}
                  </div> : <div></div>
                )
              })
              )
            }
            )}
          </SwipeableViews>
          <GridContainer spacing={3}>
            {expStructure && expStructure.map((group, groupIndex) => {
              return (<>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <h5 onClick={() => clickGroup(groupIndex)} style={{ fontWeight: "bold", textDecoration: "underline", display: "flex" }}>{group.name}:
                    {group.expand || groupsActive.includes(groupIndex) ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
                  </h5>
                </GridItem>

                {group.stages.map((stage, stageIndex) => {
                  tabCounter++
                  let counter = tabCounter
                  return (stage.visible ? <GridItem xs={12} sm={6} md={6} lg={3} key={counter}
                    hidden={!group.expand && !groupsActive.includes(groupIndex)}>
                    <Card style={{ position: "relative", marginTop: "15px", marginBottom: "15px" }}>
                      <CardActionArea
                        onClick={() => {
                          setTileActive(counter);
                        }}
                      >
                        <CardBody>
                          <GridContainer>
                            <GridItem className={tileclasses.icon}>
                              {formatIcon(stage, counter)}
                            </GridItem>
                            <GridItem>
                              <h4 className={tileclasses.cardTitle}>
                                {stage.name !== 'TestPlan' ?
                                  <b>{stage.name}</b>
                                  :
                                  <b>Test</b>
                                }
                              </h4>
                              {stage.name !== 'TestPlan' ?
                              <p>{stage.name} Board</p>:
                              <p>Test Board</p>
                            }
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </CardActionArea>
                      {tileActive === tabCounter && (
                        <CheckCircleIcon
                          style={{ position: "absolute", top: "10px", right: "10px" }}
                        />
                      )}
                    </Card>
                  </GridItem> :
                    <></>
                  )
                }
                )}
              </>
              )
            }
            )}
          </GridContainer>
          <ExperimentContext.Provider value={experimentContextValues}>
            <SwipeableViews
              axis="x"
              index={tileActive}
              style={{ overflowY: "hidden" }}
            >
              {expStructure && expStructure.map((group, groupIndex) => {
                return (group.stages.map((stage, stageIndex) => {
                  contentCounter++
                  return (
                    contentCounter == tileActive && stage.visible ? <div className={tileclasses.tabContent} key={contentCounter}>
                      {formatContent(stage)}
                    </div> : <div></div>
                  )
                })
                )
              }
              )}
            </SwipeableViews>
          </ExperimentContext.Provider>
        </>
      )}
    </div>
  );
}

export default withRouter(ExperimentDetails);