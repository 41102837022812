import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Heading from "components/Heading/Heading.js";
import {
    Card,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function StressTestsStage(props) {
    const classes = useStyles();

    const {
        projectID: projectID,  
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
        // {
        //     tabButton: "StressTests",
        //     tabContent: (
        //         <Card className={classes.card}>
        //             <CardHeader icon>
        //                 <CardIcon color="quSandBox">
        //                     <IconDict.StressTests />
        //                 </CardIcon>
        //                 <h4 className={classes.cardIconTitle}>
        //                     Stress Tests
        //                 </h4>
        //             </CardHeader>
        //             <CardBody>
        //                 <Heading title="*Coming Soon*" textAlign="center" />
        //             </CardBody>
        //         </Card>
        //     ),
        //     tabIcon: IconDict['StressTests']
        // }
    ]
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('StressTests') ? stages['StressTests'].PK : ""}
                    notes={allNotes.hasOwnProperty("StressTests")
                        ? allNotes.StressTests
                        : []}
                    stageType="StressTests"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("StressTests")
                        ? allNotes.StressTests
                        : []}
                    setNotes={setNotesHelper}
                    stageType="StressTests"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("StressTests")
                            ? allIssues.StressTests
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="StressTests"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}