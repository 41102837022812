import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// Amplify components
import { Auth } from "aws-amplify";
import Alert from "views/Components/Alert.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Heading from "components/Heading/Heading.js";

import {
  Slide,
  Tooltip,
  Radio
} from "@material-ui/core";

import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";


import AssessmentIcon from "@material-ui/icons/Assessment";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import SaveIcon from '@material-ui/icons/Save';
import { Close, ControlPointSharp } from "@material-ui/icons";

import "survey-react/survey.css";
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

import Table from "components/Table/Table.js";
import { useRouteMatch } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import { NotificationContext } from "views/Components/Context.js";
import GridContainer from "components/Grid/GridContainer";

import GridItem from "components/Grid/GridItem";
import CardIcon from "components/Card/CardIcon";
import { Survey, Model } from "survey-react";
import TagsInput from "react-tagsinput";

import TemplateAPI from "views/APIs/TemplateAPI.js"

import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import styles from "assets/jss/material-dashboard-pro-react/components/reportStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);
const usedashboardStyle = makeStyles(dashboardStyle);
const useModalStyles = makeStyles(modalStyle);
const useFormStyles = makeStyles(regularFormsStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);


export default function Assessment(props) {
  const classes = useStyles();

  const txtFocus = useRef(null);
  const [user, setUser] = React.useState({});

  const [surveyJson, setSurveyJson] = React.useState({
    focusFirstQuestionAutomatic: false,
    focusOnFirstError: false,
    clearInvisibleValues: "none",
  });

  const [newStageId, setNewStageId] = React.useState(props.stageId);
  const savedReportsRef = React.useRef([]);
  const [savedReports, setSavedReports] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  // const [model, setModel] = React.useState(false);

  const [method, setMethod] = React.useState("create");

  const [name, setName] = React.useState("");
  const [version, setVersion] = React.useState("");

  const [reportHeight, setReportHeight] = React.useState("200");
  const [reportModel, setReportModel] = React.useState('close');
  const [HTML, setHTML] = React.useState();
  const [MD5, setMD5] = React.useState();

  const [QuTrackOption, setQuTrackOption] = React.useState("save");

  const [artifactId, setArtifactId] = React.useState("");
  const [editIndex, setEditIndex] = React.useState(0);
  const [viewing, setViewing] = React.useState("report")

  const [templateType, setTemplateType] = React.useState("basic");
  const [nestedLevel, setNestedLevel] = React.useState("0");
  const [templateSample, setTemplateSample] = React.useState();
  const [templateReference, setTemplateReference] = React.useState();


  useEffect(() => {
    if (props.templateId !== "") {
      TemplateAPI.getTemplate(props.templateId, props.projectId).then(function (result) {
        console.log(props.templateId)
        if (result.templateType) {
          setTemplateType(result.templateType)
        }

        if (result.templateReference) {
          setTemplateReference(result.templateReference)
        }
        if (result.templateSample) {
          setTemplateSample(result.templateSample)
        }
        if (result.templateType === "basic" && result.nestedLevel == "0") {
          setSurveyJson({ ...surveyJson, ...JSON.parse(result.templateValue), showNavigationButtons: false, })
        } else {
          setSurveyJson({ ...surveyJson, ...JSON.parse(result.templateValue) })
        }
        if (result.nestedLevel) {
          setNestedLevel(result.nestedLevel)
        }
      });
    } else {
      setSurveyJson({
        ...surveyJson,
        showNavigationButtons: false,
        ...require('variables/survey.json'),
      });
    }
  }
    , []);

  const [formStatus, setFormStatus] = React.useState('fill')
  const [chartData, setChartData] = React.useState([])

  var model = new Model(surveyJson);

  model.onComplete.add(() => {
    var tmp = []
    for (let key in model.data) {
      if (key != 'fullMark') {
        tmp.push(
          {
            subject: key,
            A: parseInt(model.data[key]),
            fullMark: parseInt(model.data['fullMark'])
          }
        )
      }
    }
    setChartData(tmp)
    setFormStatus('complete')
  })

  return (
    <>
      <Card>
        <CardHeader icon>
          <CardIcon color="quSandBox">
            <AssessmentIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Reports</h4>
        </CardHeader>
        <CardBody>
          {/* {formStatus === 'fill' ? */}
          <Survey
            model={model}
            clearInvisibleValues="none"
            showCompletedPage={false}
          />
          {/* : */}
          {formStatus === 'complete' &&
            <GridContainer justify="center">
              <RadarChart
                outerRadius={150}
                width={800}
                height={500}
                data={chartData}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis domain={[0, model.data['fullMark']]} />
                <Radar
                  name="Mike"
                  dataKey="A"
                  stroke="#435966"
                  fill="#435966"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </GridContainer>
          }
        </CardBody>
      </Card>
    </>
  );
}
Assessment.defaultProps = {
  notes: [],
  stageId: "",
  templateId: "",
  stageType: "",
};

Assessment.propTypes = {
  notes: PropTypes.array,
  stageId: PropTypes.string,
  templateId: PropTypes.string,
  stageType: PropTypes.string,
};
