// error
import adminErrorPage from "views/Pages/AdminErrorPage.js";
import ErrorPage from "views/Pages/ErrorPage.js";
// auth
import LoginPage from "views/Pages/auth/LoginPage.js";
import VerificationPage from "views/Pages/auth/VerificationPage";
import RegisterPage from "views/Pages/auth/RegisterPage.js";
import UpdatePasswordPage from "views/Pages/auth/UpdatePasswordPage";
import ResetPasswordPage from "views/Pages/auth/ResetPasswordPage";
import AssessmentPage from "views/Pages/auth/AssessmentPage";
// user
import PricingPage from "views/Pages/user/PricingPage";
import UserProfile from "views/Pages/user/UserProfile.js";
import QuApiVault from "views/Pages/user/QuApiVault.js";
import Projects from "views/Pages/user/Projects";
import Experiments from "views/Pages/user/Experiments";
import APIDetails from "views/Pages/user/APIDetails.js";
import QUApi from "views/Pages/user/QUApi.js";
// admin
import UserTablePage from "views/Pages/admin/UserTablePage";

// @material-ui/icons
import AppsIcon from "@material-ui/icons/Apps";
import StorageIcon from "@material-ui/icons/Storage";
import ExploreIcon from "@material-ui/icons/Explore";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import BuildIcon from "@material-ui/icons/Build";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import AssessmentIcon from "@material-ui/icons/Assessment";
import TableChartIcon from "@material-ui/icons/TableChart";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WorkIcon from "@material-ui/icons/Work";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SecurityIcon from '@material-ui/icons/Security';

var dashRoutes = [
  {
    path: "/assessment",
    hidden: true,
    name: "assessment",
    component: AssessmentPage,
    layout: "/auth",
  },
  {
    path: "/error-page",
    hidden: true,
    name: "error",
    component: ErrorPage,
    layout: "/auth",
  },
  {
    path: "/error-page",
    hidden: true,
    name: "error",
    component: adminErrorPage,
    layout: "/admin",
  },
  {
    path: "/login-page",
    hidden: true,
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/verification-page",
    hidden: true,
    name: "Verification Page",
    component: VerificationPage,
    layout: "/auth",
  },
  {
    path: "/register-page",
    hidden: true,
    name: "Register Page",
    component: RegisterPage,
    layout: "/auth",
  },
  {
    path: "/reset-password-page",
    hidden: true,
    name: "Reset Password Page",
    component: ResetPasswordPage,
    layout: "/auth",
  },
  {
    path: "/update-password-page",
    hidden: true,
    name: "Update Password Page",
    component: UpdatePasswordPage,
    layout: "/auth",
  },
  {
    path: "/pricing-page",
    hidden: true,
    name: "Pricing Page",
    component: PricingPage,
    layout: "/auth",
  },
  {
    path: "/user-page",
    hidden: true,
    name: "User Profile",
    mini: "UP",
    component: UserProfile,
    layout: "/admin",
    redirect: "/auth/login",
  },
  {
    path: "/qu-api-vault",
    hidden: true,
    name: "QuApiVault",
    mini: "AV",
    component: QuApiVault,
    layout: "/admin",
    redirect: "/auth/login",
    userTypes: ["admin"],
  },
  {
    path: "/users",
    hidden: true,
    name: "Users",
    mini: "U",
    component: UserTablePage,
    layout: "/admin",
    redirect: "/auth/login",
    userTypes: ["admin"],
  },
  {
    path: "/projects/experiments/:projectID",
    name: "Experiments",
    hidden: true,
    component: Experiments,
    layout: "/admin",
  },
  {
    path: "/projects",
    name: "QuProjects",
    icon: AccountTreeIcon,
    component: Projects,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "QuToolBox",
    icon: AppsIcon,
    layout: "/admin",
    views: [
      {
        path: "/QuToolBox/Data",
        name: "Data",
        icon: StorageIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Explore",
        name: "Explore",
        icon: ExploreIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Processing",
        name: "Data Processing",
        icon: CompareArrowsIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Modeling-Tools",
        name: "Modeling Tools",
        icon: BuildIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Models",
        name: "Models",
        icon: ViewModuleIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Explain",
        name: "Explain",
        icon: AssessmentIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Fairness",
        name: "Fairness and Bias",
        icon: ThumbsUpDownIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Security",
        name: "Security",
        icon: SecurityIcon,
        component: QUApi,
        layout: "/admin",
      },
      {
        path: "/QuToolBox/Report",
        name: "Report",
        icon: TableChartIcon,
        component: QUApi,
        layout: "/admin",
      },
      // {
      //   path: "/QuToolBox/Audit-Checklist",
      //   name: "Audit Checklist",
      //   icon: PlaylistAddCheckIcon,
      //   component: APIDetails,
      //   layout: "/admin",
      // },
      {
        path: "/QuToolBox/Case-studies",
        name: "Case studies",
        icon: WorkIcon,
        component: QUApi,
        layout: "/admin",
      },
    ],
  },
];
export default dashRoutes;
