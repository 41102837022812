import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function VerificationPage(props) {
  const history = useHistory();
  const [checked, setChecked] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("")
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [validationErrors, setValidationErrors] = React.useState({});
  const [timer, setTimer] = React.useState(60);

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const hideAlert = () => {
    setAlert(null);
  };

  const validate = () => {
    const err = {};
    const requiredFields = [
     code
    ];
    requiredFields.forEach(field => {
      if(!field) {
        err[field]="Required"
      }
    });
    setValidationErrors(err);
    if (Object.keys(err).length>0) {
      return false;
    } else {
      return true
    };
  }

  const cognitoVerify = async () => {
    console.log("in verify");
    console.log(props.location)
    if (validate()) {
      Auth.confirmSignUp(props.location.state.email, code)
      .then((user) => {
        // console.log("verify done");
        // inputConfirmAlertNext(e);
        // setModal(false)
        setConfirmModal(true);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        basicAlert();
        // console.log(err);
      });
    }
  }
  
  const resendCode = async () => {
    await Auth.resendSignUp(props.location.state.email)
    .then()
    .catch((err) => {
      setErrorMessage(err.message);
        basicAlert();
    });
    resetTimer();
  }
  
  const basicAlert = async () => {
    let alertMessage;
    await setErrorMessage((errorMessage)=>{
      alertMessage=errorMessage;
      return errorMessage;
    }) 
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={alertMessage}
        onConfirm={() => hideAlert()}
        // onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
  };

  const resetTimer = () => {
    setTimer(60);
  }

  React.useEffect(() => {
    setTimeout(() => {
      setTimer((timer) => {
        if(timer===0) {return timer}
        return timer - 1 
      });
    }, 1000);
  });

  const classes = useStyles();

  if (!props.location.state) {
    return (<Redirect to="/" />)
  }
  return (
    <div className={classes.container}>
      {alert}
      <GridContainer justify="center">
        <GridItem sm={12} md={8} lg={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter} `}
                color="quSandBox"
              >
                <h4 className={classes.cardTitle}>Verify Account</h4>
                
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Verification Code"
                  id="verification-code"
                  onChange={(e) => setCode(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={!!validationErrors[code]||false}
                  helperText={validationErrors[code]}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ConfirmationNumberIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button onClick={cognitoVerify} color="rose" simple size="lg" block>
                  Verify
                </Button>
                <Button onClick={resendCode} color="rose" simple size="lg" block disabled={timer!==0}>
                  Resend Code {timer!==0 && "wait "+timer+" sec"}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall
          }}
          open={confirmModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setConfirmModal(false)}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={
              classes.modalBody + " " + classes.modalSmallBody
            }
          >
            <h3>Email verified successfully</h3>
          </DialogContent>
          <DialogActions
            className={
              classes.modalFooter + " " + classes.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                history.push("/auth/login-page");
              }}
              color="danger"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Go To Login
            </Button>
          </DialogActions>
        </Dialog>

    </div>
  );
}
