import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SearchBar from "material-ui-search-bar";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import {
  InputLabel,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";
// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Heading from "components/Heading/Heading";

import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import UserAPI from "views/APIs/UserAPI.js"

// import StructureDesigner from "views/Components/StructureDesigner.js"
import defaultProjectStructure from "variables/defaultProjectStructure.js"
import MRMProjectStructure from "variables/MRMProjectStructure.js"
import AIStructure from "variables/AIStructure.js"

import ProjectStructureEditor from "views/Components/ProjectStructureEditor.js"
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);

export default function Projects() {
  const PERMISSION = ["read", "clone", "write", "audit", "own"];
  const [json, setjson] = React.useState(
    JSON.stringify({
      stages: [
        [
          {
            status: "active",
            name: "Scikit_Learn_Audit_Demo",
            entities: [
              {
                description: "",
                type: {
                  username: "ubuntu",
                  src: "AWS",
                  gpu_enabled: false,
                  name: "Environment",
                  kernel: {
                    python: true,
                    r: false,
                    matlab: false,
                  },
                  single_project: true,
                  os: "Ubuntu",
                  region: "us-west-2",
                  start_from: "DOCKERHUB",
                  ami_id: "",
                  docker_hub_url: "jdadaptivealgo/env_1_report",
                  service_type: "jns",
                  use_ac: false,
                  config: {
                    callback: "",
                    post_execution: "",
                    pre_execution: "",
                    parameters: [
                      {
                        encoded: true,
                        EXPERIMENT_ID:
                          "EXPERIMENT#e7efea679b4b4a058e4cc5b4b57aef7a",
                      },
                      {
                        encoded: false,
                        TYPE: "Model",
                      },
                    ],
                    run_command: "",
                  },
                  duration: 1,
                  size: "t2.medium",
                },
                name: "Environment",
              },
              {
                status: "active",
                description: "",
                access_role: "public",
                type: {
                  src:
                    "https://github.com/rh-2019/Scikit_Learn_QuSandbox_Demo.git",
                  name: "Model",
                  github_branch: "",
                  src_type: "Github",
                  config: {
                    callback: "",
                    post_execution: "",
                    pre_execution: "",
                    parameters: [],
                    run_command: "",
                  },
                },
                name: "Audit",
              },
            ],
            description: "",
          },
        ],
      ],
      description: "",
      connections: [],
      name: "Scikit_Learn_Audit",
    })
  );
  const classes = useStyles();
  const extendedFormsClasses = useextendedFormsStyles();

  const history = useHistory();
  const sweetAlertClasses = usesweetAlertStyle();
  const images = require.context("assets/img", true);
  const [user, setUser] = React.useState();
  const [allProjects, setAllProjects] = React.useState([]);
  const [showProjects, setShowProjects] = React.useState([]);
  const [permanentProjects, setPermanentProjects] = React.useState([]);
  const [userType, setUserType] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [gistLink, setGistLink] = React.useState("");
  const [colabLink, setColabLink] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [name, setName] = React.useState("");
  const [version, setVersion] = React.useState("");
  const [brief, setBrief] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const { path, url } = useRouteMatch();
  const [searchValue, setSearchValue] = React.useState("");
  const { showNotification } = React.useContext(NotificationContext);
  const [loading, setLoading] = React.useState(true);

  const [filter, setFilter] = React.useState('All')

  const handleFilter = (event) => {
    setFilter(event.target.value)
  }

  const [createProjectState, setCreateProjectState] = React.useState(0);
  const [projectCategory, setProjectCategory] = React.useState(0);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (user) {
        setUser(user);
        setUserType(
          user.signInUserSession.accessToken.payload["cognito:groups"][0]
        );
      })
      .then(() => { })
      .catch((err) => {
        if (err === "not authenticated") {
          history.push("/auth/login");
        }
      });
  }, []);

  const [bookmarks, setBookMarks] = React.useState([])

  useEffect(() => {
    if (filter == 'All') {
      setShowProjects(allProjects)
    } else {
      setShowProjects(allProjects.filter(x => bookmarks.includes(x.PK)))
    }
  }, [allProjects, filter]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      UserAPI.getProfile("markedProjects").then(function (result) {
        setBookMarks(result)
      });
    }
  }, [user]);

  const bookmark = (projectId) => {
    UserAPI.bookmarkProject('PUT', projectId).then(
      function (result) {
        setBookMarks(bookmarks.concat(result))
      }
    )
  }

  const unBookmark = (projectId) => {
    UserAPI.bookmarkProject('DELETE', projectId).then(
      function (result) {
        setBookMarks(bookmarks.filter(x => !result.includes(x)))
      }
    )
  }

  useEffect(() => {
    setLoading(true);
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + "/project", {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            setAllProjects(data.Items);
            setPermanentProjects(data.Items);
            setLoading(false);
          });
        },
        function (err) {
          console.log(err);
        }
      );
    }
  }, [user]);

  const deleteAlert = (projectData) => {
    // console.log(projectData);
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteProject(projectData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.info
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.info
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this Project!
      </SweetAlert>
    );
  };

  const cloneAlert = (projectData) => {
    // console.log(projectData);
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleCloneProject(projectData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.info
        }
        cancelBtnCssClass={
          sweetAlertClasses.button + " " + sweetAlertClasses.info
        }
        confirmBtnText="Yes, clone it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will create a new Project with _copy as suffix!
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const handleDeleteProject = (projectData) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      "/project/" +
      projectData.PK.split("#")[1],
      {
        method: "DELETE",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function () {
        let data = allProjects.filter(
          (project) => project.PK !== projectData.PK
        );
        let permanentData = permanentProjects.filter(
          (project) => project.PK !== projectData.PK
        );
        setAllProjects(data);
        setPermanentProjects(permanentData);
        showNotification("Project deleted successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Delete Failed.", "danger");
        console.log(error);
      });
    hideAlert();
  };

  const handleCloneProject = (projectData) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      "/project?id=" +
      projectData.PK.split("#")[1],
      {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.hasOwnProperty("Items")) {
          setAllProjects((prevState) => [...prevState, data.Items[0]]);
          setPermanentProjects((prevState) => [...prevState, data.Items[0]]);
          showNotification("Project cloned successfully.", "quSandBoxColor");
        } else {
          showNotification("Clone Failed.", "danger");
        }
        return data;
      })
      .catch((error) => {
        showNotification("Clone Failed.", "danger");
        console.log(error);
      });
    hideAlert();
  };

  // const toggleFilter = () => {
  //   if(filter){
  //     setFilter(false)
  //   }
  //   else{
  //     setFilter(true)
  //   }
  // }
  // let dataList = Object.keys(data);
  // const { factset, moodys, refinitive, sp } = data;
  // const handleChange = (event) => {
  //   setData({ ...data, [event.target.name]: event.target.checked });
  // };

  const createProject = () => {
    setModal(true);
  };
  const handleClose = () => {
    setCreateProjectState(0);
    setModal(false);
    clearCreateModel();
  };

  const clearCreateModel = () => {
    setBrief("");
    setVersion("");
    setName("");
    setGistLink("");
    setColabLink("")
    setDescription("");
    // if (templateFetched) {
    //   clearProjectTemplate();
    // } else {
    //   setProjectTemplate({});
    // }
    setjson(
      JSON.stringify({
        stages: [
          [
            {
              status: "active",
              name: "Scikit_Learn_Audit_Demo",
              entities: [
                {
                  description: "",
                  type: {
                    username: "ubuntu",
                    src: "AWS",
                    gpu_enabled: false,
                    name: "Environment",
                    kernel: {
                      python: true,
                      r: false,
                      matlab: false,
                    },
                    single_project: true,
                    os: "Ubuntu",
                    region: "us-west-2",
                    start_from: "DOCKERHUB",
                    ami_id: "",
                    docker_hub_url: "jdadaptivealgo/env_1_report",
                    service_type: "jns",
                    use_ac: false,
                    config: {
                      callback: "",
                      post_execution: "",
                      pre_execution: "",
                      parameters: [
                        {
                          encoded: true,
                          EXPERIMENT_ID:
                            "EXPERIMENT#e7efea679b4b4a058e4cc5b4b57aef7a",
                        },
                        {
                          encoded: false,
                          TYPE: "Model",
                        },
                      ],
                      run_command: "",
                    },
                    duration: 1,
                    size: "t2.medium",
                  },
                  name: "Environment",
                },
                {
                  status: "active",
                  description: "",
                  access_role: "public",
                  type: {
                    src:
                      "https://github.com/rh-2019/Scikit_Learn_QuSandbox_Demo.git",
                    name: "Model",
                    github_branch: "",
                    src_type: "Github",
                    config: {
                      callback: "",
                      post_execution: "",
                      pre_execution: "",
                      parameters: [],
                      run_command: "",
                    },
                  },
                  name: "Audit",
                },
              ],
              description: "",
            },
          ],
        ],
        description: "",
        connections: [],
        name: "Scikit_Learn_Audit",
      })
    );
  };

  // const clearProjectTemplate = () => {
  //   setProjectTemplate({
  //     summary: templates[0].SK,
  //     audit: templates[0].SK,
  //     dataExplore: templates[0].SK,
  //     pipeline: templates[0].SK,
  //     data: templates[0].SK,
  //     model: templates[0].SK,
  //     environment: templates[0].SK,
  //     explainability: templates[0].SK,
  //     fairness: templates[0].SK,
  //     monitoring: templates[0].SK,
  //     findings: templates[0].SK,
  //     recommendations: templates[0].SK,
  //   });
  // };
  // useEffect(() => {
  //   if (templateFetched) {
  //     clearProjectTemplate();
  //   }
  // }, [templates, templateFetched]);
  // useEffect(() => {
  //   console.log(projectTemplate)
  // }, [projectTemplate]);
  const handleCreateProjectNext = () => {
    setCreateProjectState(1);
    // if (!templateFetched) {
    //   // console.log("hi");
    //   let token = user.signInUserSession.accessToken.jwtToken;
    //   fetch(process.env.REACT_APP_BACK_END_URL + "/template", {
    //     method: "GET",
    //     mode: "cors",
    //     credentials: "omit",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: token,
    //     },
    //   }).then(
    //     function (res) {
    //       res.json().then(function (data) {
    //         setTemplates(data.Items);
    //         setTemplateFetched(true);
    //       });
    //     },
    //     function (err) {
    //       console.log(err);
    //     }
    //   );
    // }
  };

  const handleCreateProject = () => {
    handleClose();
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(process.env.REACT_APP_BACK_END_URL + "/project", {
      method: "POST",
      mode: "cors",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        projectBrief: brief,
        projectVersion: version,
        projectName: name,
        projectGistLink: (gistLink && gistLink.includes("gist.github.com/")) ? gistLink.split("gist.github.com/")[1] : gistLink,
        projectColabLink: colabLink,
        projectDescription: description,
        json: json,
        projectTemplate: JSON.stringify(structure),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.hasOwnProperty("Items")) {
          setAllProjects((prevState) => [...prevState, data.Items[0]]);
          setPermanentProjects((prevState) => [...prevState, data.Items[0]]);
          showNotification(
            "New project created successfully.",
            "quSandBoxColor"
          );
        } else {
          showNotification("Project creation failed.", "danger");
        }
        setCreateProjectState(0);
        clearCreateModel();
        return data;
      })
      .catch((error) => {
        showNotification("Project creation failed.", "danger");
        console.log(error);
      });
  };

  // const showNotification = (message, status) => {
  //   setOperationStatus(status);
  //   setNotificationMessage(message);
  //   setNotification(true);
  //   setTimeout(function () {
  //     setNotification(false);
  //   }, 6000);
  // };

  const searchInputValue = (e) => {
    setSearchValue(e);
    if (e === "") {
      setAllProjects(permanentProjects);
      return;
    }
    var newList = permanentProjects.filter(
      (project) =>
        project.projectName.toLowerCase().includes(e.toLowerCase()) ||
        (project.projectBrief &&
          project.projectBrief.toLowerCase().includes(e.toLowerCase()))
    );
    setAllProjects(newList);
  };

  // const templateDropDown = (name, card) => {
  //   return (
  //     <GridItem xs={12} style={{ margin: "10px 0px 4px 0px" }}>
  //       <FormControl fullWidth className={classes.selectFormControl}>
  //         <InputLabel
  //           htmlFor="simple-select"
  //           // className={classes.selectLabel} 
  //           style={{ fontSize: "24px" }}
  //         >
  //           {name} Template
  //         </InputLabel>
  //         <Select
  //           MenuProps={{
  //             className: classes.selectMenu,
  //           }}
  //           classes={{
  //             select: classes.select,
  //           }}
  //           style={{ fontSize: "14px", paddingTop: "8px" }}
  //           onChange={(e) => {
  //             setProjectTemplate({
  //               ...projectTemplate,
  //               [card]: templates.find(
  //                 (template) => template.SK === e.target.value
  //               ).SK,
  //             });
  //           }}
  //           value={
  //             projectTemplate.hasOwnProperty(card) ? projectTemplate[card] : ""
  //             // templates.length>0?templates[0].SK:""
  //           }
  //           inputProps={{
  //             name: "templateSelect",
  //             id: "template-select",
  //           }}
  //         >
  //           {templates.map((template, ind) => {
  //             // console.log(template);
  //             return (
  //               <MenuItem
  //                 key={ind}
  //                 classes={{
  //                   root: classes.selectMenuItem,
  //                   selected: classes.selectMenuItemSelected,
  //                 }}
  //                 value={template.SK}
  //               >
  //                 {template.Name} {ind === 0 && ("(default)")}
  //               </MenuItem>
  //             );
  //           })}
  //         </Select>
  //       </FormControl>
  //     </GridItem>
  //   );
  // };

  const [structure, setStructure] = React.useState(defaultProjectStructure)
  useEffect(() => {
    if (projectCategory === 0) {
      setStructure(defaultProjectStructure)
    } else if (projectCategory === 1) {
      setStructure(MRMProjectStructure)
    } else {
      setStructure(AIStructure)
    }
  }, [projectCategory]);

  return (
    <div>
      {alert}
      {/* <Snackbar
        place="br"
        color={operationStatus}
        icon={AddAlert}
        message={notificationMessage}
        open={notification}
        closeNotification={() => setNotification(false)}
        close
      /> */}
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: "0px" }}>
          <div style={{ fontSize: "30px" }}>Create New Project</div>
        </DialogTitle>
        {createProjectState === 0 && (
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              onChange={(event) => {
                setName(event.target.value);
              }}
              value={name}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="version"
              label="Version"
              type="text"
              onChange={(event) => {
                setVersion(event.target.value);
              }}
              value={version}
              fullWidth
            />
            <FormControl
              fullWidth
              className={extendedFormsClasses.selectFormControl}
            >
              <InputLabel
                htmlFor="simple-select"
                className={extendedFormsClasses.selectLabel}
              >
                Choose category
              </InputLabel>
              <Select
                MenuProps={{
                  className: extendedFormsClasses.selectMenu,
                }}
                classes={{
                  select: extendedFormsClasses.select,
                }}
                onChange={(event) => {
                  setProjectCategory(event.target.value);
                }}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select",
                  value: projectCategory,
                }}
              >
                <MenuItem
                  key='0'
                  classes={{
                    root: extendedFormsClasses.selectMenuItem,
                    selected: extendedFormsClasses.selectMenuItemSelected,
                  }}
                  value={0}
                >
                  Algorithm Audit
                </MenuItem>
                <MenuItem
                  key='1'
                  classes={{
                    root: extendedFormsClasses.selectMenuItem,
                    selected: extendedFormsClasses.selectMenuItemSelected,
                  }}
                  value={1}
                >
                  Model Risk Management
                </MenuItem>
                <MenuItem
                  key='2'
                  classes={{
                    root: extendedFormsClasses.selectMenuItem,
                    selected: extendedFormsClasses.selectMenuItemSelected,
                  }}
                  value={2}
                >
                  AI and ML Validation
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              id="brief"
              label="Brief Description"
              type="text"
              onChange={(event) => {
                setBrief(event.target.value);
              }}
              value={brief}
              fullWidth
              multiline={true}
              rows={3}
            />

            <TextField
              margin="dense"
              id="description"
              label="Detailed desciption for this project"
              type="text"
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              fullWidth
              value={description}
              multiline={true}
              rows={3}
            />
            <TextField
              margin="dense"
              id="gistLink"
              label="Link to git gist for this project"
              type="text"
              onChange={(event) => {
                setGistLink(event.target.value);
              }}
              value={gistLink}
              fullWidth
            />
            <TextField
              margin="dense"
              id="colabLink"
              label="Link to colab for this project"
              type="text"
              onChange={(event) => {
                setColabLink(event.target.value);
              }}
              value={colabLink}
              fullWidth
            />
            <CustomInput
              labelText="Pipeline JSON"
              id="custom_key_json"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                rows: 5,
                onChange: (event) => {
                  setjson(event.target.value);
                },
                value: json,
              }}
            />
          </DialogContent>
        )}
        {createProjectState === 1 && (
          <DialogContent>
            {/* <h5>
              <b></b>
            </h5> */}
            {/* <GridContainer> */}
            {/* {templateDropDown("Summary", "summary")}
              {templateDropDown("Audit", "audit")}
              {templateDropDown("Date Explore", "dataExplore")}
              {templateDropDown("Pipeline", "pipeline")}
              {templateDropDown("Data", "data")}
              {templateDropDown("Model", "model")}
              {templateDropDown("Environment", "environment")}
              {templateDropDown("Explainability", "explainability")}
              {templateDropDown("Fairness", "fairness")}
              {templateDropDown("Monitoring", "monitoring")}
              {templateDropDown("Findings", "findings")}
              {templateDropDown("Recommendations", "recommendations")} */}
            <ProjectStructureEditor
              structure={structure}
              setStructure={setStructure}
            ></ProjectStructureEditor>
            {/* </GridContainer> */}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="quSandBoxColor">
            Cancel
          </Button>
          {/* <Button onClick={handleCreateProject} color="quSandBoxColor"> */}
          {createProjectState === 1 && (
            <Button
              onClick={() => {
                setCreateProjectState(0);
              }}
              color="quSandBoxColor"
            >
              Back
            </Button>
          )}
          {createProjectState === 0 && (
            <Button onClick={handleCreateProjectNext} color="quSandBoxColor">
              Next
            </Button>
          )}
          {createProjectState === 1 && (
            <Button onClick={handleCreateProject} color="quSandBoxColor">
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Switch>
        <Route exact path={path}>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8} lg={8}>

              <SearchBar
                value={searchValue}
                onChange={(newValue) => searchInputValue(newValue)}
                onRequestSearch={(newValue) => searchInputValue(newValue)}
              />
              <br />
            </GridItem>
            <GridItem xs={12} sm={1} md={1} lg={1}
              style={{ textAlign: "center" }}>
              <Select
                MenuProps={{
                  className: extendedFormsClasses.selectMenu,
                }}
                classes={{
                  select: extendedFormsClasses.select,
                }}
                value={filter}
                onChange={handleFilter}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select",
                  value: filter,
                }}
              >
                <MenuItem
                  key={0}
                  classes={{
                    root: extendedFormsClasses.selectMenuItem,
                    selected: extendedFormsClasses.selectMenuItemSelected,
                  }}
                  value="All"
                >
                  All
                </MenuItem>
                <MenuItem
                  key={1}
                  classes={{
                    root: extendedFormsClasses.selectMenuItem,
                    selected: extendedFormsClasses.selectMenuItemSelected,
                  }}
                  value="Saved"
                >
                  Saved
                </MenuItem>
              </Select>
            </GridItem>
            <GridItem
              xs={12}
              sm={3}
              md={3}
              lg={3}
              style={{ textAlign: "center" }}
            >
              {/* {isEdit && ( */}
              <Button color="info" onClick={createProject}>
                Create Project
              </Button>
              {/* )} */}
            </GridItem>
          </GridContainer>
          {loading ? (
            <Heading title={<CircularProgress />} textAlign="center" />
          ) : (
            <GridContainer>
              {showProjects.map((project, index) => (
                <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
                  <Link
                    to={{
                      pathname:
                        path + "/experiments/" + project.PK.split("#")[1],
                    }}
                  >
                    <Card style={{ minHeight: "225px" }}>
                      <CardHeader stats icon>
                        <CardIcon color="quSandBox">
                          {project.projectName}
                        </CardIcon>

                        <p className={classes.cardCategory}>
                          Version: {project.projectVersion}
                        </p>
                        <h3 className={classes.cardTitle}>
                          <small>
                            <img
                              src={images("./qusandbox_logo_v2.5.png")}
                              alt=""
                              className={classes.thumbnail}
                            />
                          </small>
                        </h3>
                      </CardHeader>
                      <CardBody
                        style={{ paddingTop: "0px", paddingBottom: "0px" }}
                      ></CardBody>
                      <CardFooter>
                        <h5>{project.projectBrief + "  >"}</h5>
                      </CardFooter>
                      <CardFooter stats style={{ marginTop: "0px" }}>
                        <div>
                          {PERMISSION.indexOf(project.projectPermission) >=
                            PERMISSION.indexOf("clone") && (
                              <FileCopyIcon
                                style={{ color: "#435966" }}
                                onClick={(e) =>
                                  e.preventDefault(cloneAlert(project))
                                }
                              />
                            )}
                          {bookmarks.includes(project.PK) ?
                            <BookmarkIcon style={{ color: '#435966' }} onClick={e => e.preventDefault(unBookmark(project.PK.split('#')[1]))} />
                            :
                            <BookmarkBorderIcon style={{ color: '#435966' }} onClick={e => e.preventDefault(bookmark(project.PK.split('#')[1]))} />
                          }
                        </div>
                        {PERMISSION.indexOf(project.projectPermission) >=
                          PERMISSION.indexOf("own") && (
                            <DeleteIcon
                              style={{ color: "#435966" }}
                              onClick={(e) =>
                                e.preventDefault(deleteAlert(project))
                              }
                            />
                          )}
                      </CardFooter>
                    </Card>
                  </Link>
                </GridItem>
              ))}
            </GridContainer>
          )}
        </Route>
      </Switch>
    </div>
  );
}
