import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import {
    Card,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function ModelStage(props) {
    const classes = useStyles();

    const [modelSelect, setModelSelect] = React.useState("");
    const [modelHTML, setModelHTML] = React.useState("");
    const [modelHeight, setModelHeight] = React.useState("0");


    const handleModelSimple = (event) => {
        getHTML(event.target.value, setModelHTML, stages['Model']);
        setModelSelect(event.target.value);
    };


    const resizeModelIframe = (e) => {
        const frame = e.target;
        setModelHeight(frame.contentWindow.document.documentElement.scrollHeight);
    };

    useEffect(() => {
        if (!!stageParam && !!reportid) {
            if (Object.keys(stages['Model']).length !== 0 && stageParam === "5") {
                getHTML(reportid, setModelHTML, stages['Model']);
                setModelSelect(reportid);
            }
        }
    }, [stages]);

    const {
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
        stageParam: stageParam,
        projectID: projectID,  
        reportid: reportid,
        getHTML: getHTML
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
        {
            tabButton: "Model",
            tabContent: (
                <Card className={classes.card}>
                    <CardHeader icon>
                        <CardIcon color="quSandBox">
                            <IconDict.Model />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Model</h4>
                    </CardHeader>
                    <CardBody>
                        <GridItem xs={12} sm={6} md={5} lg={5}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="simple-select"
                                    className={classes.selectLabel}
                                >
                                    Choose Report
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    value={modelSelect}
                                    onChange={handleModelSimple}
                                    inputProps={{
                                        name: "simpleSelect",
                                        id: "simple-select",
                                    }}
                                >
                                    <MenuItem
                                        disabled
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        Choose Report
                                    </MenuItem>
                                    {stages.hasOwnProperty('Model') && stages['Model'].hasOwnProperty("Reports") &&
                                        stages['Model'].Reports.map((report, index) => (
                                            <MenuItem
                                                key={index}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected:
                                                        classes.selectMenuItemSelected,
                                                }}
                                                value={index}
                                            >
                                                {report.Name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </CardBody>
                    <CardBody>
                        <GridContainer>
                            { }
                            <iframe
                                title="model"
                                id="model"
                                srcDoc={modelHTML}
                                width="100%"
                                frameBorder="0"
                                height={`${modelHeight}px`}
                                scrolling="no"
                                onLoad={resizeModelIframe}
                            ></iframe>
                        </GridContainer>
                    </CardBody>
                </Card>

            ),
            tabIcon: IconDict['Model']
        }
    ]
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('Model') ? stages['Model'].PK : ""}
                    notes={allNotes.hasOwnProperty("Model")
                        ? allNotes.Model
                        : []}
                    stageType="Model"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("Model")
                        ? allNotes.Model
                        : []}
                    setNotes={setNotesHelper}
                    stageType="Model"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("Model")
                            ? allIssues.Model
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="Model"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}