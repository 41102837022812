import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import AssessmentIcon from "@material-ui/icons/Assessment";

// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { Survey, Model } from "survey-react";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

const useStyles = makeStyles(styles);

export default function AssessmentPage(props) {

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const classes = useStyles();

  const [surveyJson, setSurveyJson] = React.useState({
    "pages": [
      {
        "name": "page1",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is Explainability reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      },
      {
        "name": "page2",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is Fairness reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      },
      {
        "name": "page3",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is What-if analysis reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      },
      {
        "name": "page4",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is Stress Test reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      },
      {
        "name": "page5",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is Scenario Test reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      },
      {
        "name": "page6",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is Security reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      },
      {
        "name": "page7",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is Threat reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      },
      {
        "name": "page8",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is Risk reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      },
      {
        "name": "page9",
        "elements": [
          {
            "type": "radiogroup",
            "name": "How is Monitoring reviewed?",
            "choices": [
              {
                "value": "1",
                "text": "No"
              },
              {
                "value": "2",
                "text": "Basic"
              },
              {
                "value": "3",
                "text": "Enough"
              },
              {
                "value": "4",
                "text": "Perfect"
              }
            ]
          }
        ]
      }
    ],
    focusFirstQuestionAutomatic: false,
    focusOnFirstError: false,
    clearInvisibleValues: "none",
  });

  const [surveyResult, setSurveyResult] = React.useState({
    "fullMark": 4,
    "How is Explainability reviewed?": "1",
    "How is Fairness reviewed?": "1",
    "How is What-if analysis reviewed?": "1",
    "How is Stress Test reviewed?": "1",
    "How is Scenario Test reviewed?": "1",
    "How is Security reviewed?": "1",
    "How is Threat reviewed?": "1",
    "How is Risk reviewed?": "1",
    "How is Monitoring reviewed?": "1"
  })

  const [formStatus, setFormStatus] = React.useState('fill')
  const [chartData, setChartData] = React.useState([])

  const onComplete = (sender) => {
    var tmp = []
    for (let key in sender.data) {
      if (key != 'fullMark') {
        tmp.push(
          {
            subject: key,
            A: parseInt(sender.data[key]),
            fullMark: 4
          }
        )
      }
    }
    setChartData(tmp)
    sender.clear(false)
    setFormStatus('complete')
  }

  const onValueChanged = (sender, options) => {
    setSurveyResult(sender.data)
    var tmp = []
    for (let key in sender.data) {
      if (key != 'fullMark') {
        tmp.push(
          {
            subject: key,
            A: parseInt(sender.data[key]),
            fullMark: 4
          }
        )
      }
    }
    setChartData(tmp)
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center" >
        <GridItem sm={12} md={12} lg={12}>
          <Card login className={classes[cardAnimaton]}>
            <CardHeader icon>
              <CardIcon color="quSandBox">
                <AssessmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Reports</h4>
            </CardHeader>
            <CardBody>
              {/* {formStatus === 'fill' ? */}
              <Survey
                json={surveyJson}
                data={surveyResult}
                onComplete={onComplete}
                onValueChanged={onValueChanged}
                clearInvisibleValues="none"
                showCompletedPage={false}
              />
              {/* : */}
              {formStatus === 'complete' &&
                <GridContainer justify="center">
                  <GridContainer justify="center">
                    <a href="https://quantuniversity.com/contact.html" target="_blank">Contact us !</a>
                  </GridContainer>
                  <GridContainer justify="center">
                    <RadarChart
                      outerRadius={150}
                      width={800}
                      height={500}
                      data={chartData}
                    >
                      <PolarGrid />
                      <PolarAngleAxis dataKey="subject" />
                      <PolarRadiusAxis domain={[0, 4]} />
                      <Radar
                        name="Mike"
                        dataKey="A"
                        stroke="#435966"
                        fill="#435966"
                        fillOpacity={0.6}
                      />
                    </RadarChart>
                  </GridContainer>
                </GridContainer>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
