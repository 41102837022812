import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/riskMetricStyle.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);
export default function RiskMetric(props) {
  const classes = useStyles();
  var cssMetric = [
    [classes.td + " " + classes.low, classes.td + " " + classes.low, classes.td + " " + classes.low, classes.td + " " + classes.low, classes.td + " " + classes.moderate,],
    [classes.td + " " + classes.low, classes.td + " " + classes.low, classes.td + " " + classes.moderate, classes.td + " " + classes.moderate, classes.td + " " + classes.high,],
    [classes.td + " " + classes.low, classes.td + " " + classes.moderate, classes.td + " " + classes.moderate, classes.td + " " + classes.high, classes.td + " " + classes.high,],
    [classes.td + " " + classes.low, classes.td + " " + classes.high, classes.td + " " + classes.high, classes.td + " " + classes.high, classes.td + " " + classes.high,],
    [classes.td + " " + classes.moderate, classes.td + " " + classes.high, classes.td + " " + classes.high, classes.td + " " + classes.high, classes.td + " " + classes.high,]
  ]
  cssMetric[props.row][props.col] = cssMetric[props.row][props.col] + " " + classes.selected
  return (
    <>
      <table className={classes.table}>
        <tr>
          <td></td>
          <td></td>
          <td colspan="5" className={classes.td}>Risk Score</td>
        </tr>
        <tr>
          <td rowspan="5" className={classes.td}>Impact</td>
          <td className={classes.td}>5</td>
          <td className={cssMetric[4][0]}>5</td>
          <td className={cssMetric[4][1]}>10</td>
          <td className={cssMetric[4][2]}>15</td>
          <td className={cssMetric[4][3]}>20</td>
          <td className={cssMetric[4][4]}>25</td>
        </tr>
        <tr>
          <td className={classes.td}>4</td>
          <td className={cssMetric[3][0]}>4</td>
          <td className={cssMetric[3][1]}>8</td>
          <td className={cssMetric[3][2]}>12</td>
          <td className={cssMetric[3][3]}>16</td>
          <td className={cssMetric[3][4]}>20</td>
        </tr>
        <tr>
          <td className={classes.td}>3</td>
          <td className={cssMetric[2][0]}>3</td>
          <td className={cssMetric[2][1]}>6</td>
          <td className={cssMetric[2][2]}>9</td>
          <td className={cssMetric[2][3]}>12</td>
          <td className={cssMetric[2][4]}>15</td>
        </tr>
        <tr>
          <td className={classes.td}>2</td>
          <td className={cssMetric[1][0]}>2</td>
          <td className={cssMetric[1][1]}>4</td>
          <td className={cssMetric[1][2]}>6</td>
          <td className={cssMetric[1][3]}>8</td>
          <td className={cssMetric[1][4]}>10</td>
        </tr>
        <tr>
          <td className={classes.td}>1</td>
          <td className={cssMetric[0][0]}>1</td>
          <td className={cssMetric[0][1]}>2</td>
          <td className={cssMetric[0][2]}>3</td>
          <td className={cssMetric[0][3]}>4</td>
          <td className={cssMetric[0][4]}>5</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td className={classes.td}>1</td>
          <td className={classes.td}>2</td>
          <td className={classes.td}>3</td>
          <td className={classes.td}>4</td>
          <td className={classes.td}>5</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td colspan="5" className={classes.td}>Likelihood of occurrence</td>
        </tr>
      </table>
      <table className={classes.table}>
        <tr>
          <td className={classes.td + " " + classes.high}></td>
          <td className={classes.td}>Red</td>
          <td className={classes.td}>High Risk</td>
        </tr>
        <tr>
          <td className={classes.td + " " + classes.moderate}></td>
          <td className={classes.td}>Yellow</td>
          <td className={classes.td}>Moderate Risk</td>
        </tr>
        <tr>
          <td className={classes.td + " " + classes.low}></td>
          <td className={classes.td}>Green</td>
          <td className={classes.td}>Low Risk</td>
        </tr>
        <tr>
          <td className={classes.td + " " + classes.selected}></td>
          <td className={classes.td}>Purple</td>
          <td className={classes.td}>Current Risk</td>
        </tr>
      </table>
    </>
  );
}
RiskMetric.propTypes = {
  row: PropTypes.number,
  col: PropTypes.number,
};
