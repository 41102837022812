import { Auth } from "aws-amplify";
const getToken = async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.accessToken.jwtToken
}

const getProfile = async (item = "markedProjects") => {
    try {
        const token = await UserAPI.getToken();
        var url = process.env.REACT_APP_BACK_END_URL + "/bookmark"
        const response = await fetch(url, {
            method: "GET",
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const json = await response.json();

        console.log(json)
        if (json.Items.length > 0 && json.Items[0].hasOwnProperty(item)) {
            return json.Items[0][item]
        } else {
            return []
        }
    } catch (error) {
        console.log(error);
        return []
    }
}

const bookmarkProject = async (type = "PUT", projectId) => {
    try {
        const token = await UserAPI.getToken();
        var url = process.env.REACT_APP_BACK_END_URL + "/bookmark?type=project&id=" + projectId
        const response = await fetch(url, {
            method: type,
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const json = await response.json();
        return ["PROJECT#" + projectId]
    } catch (error) {
        console.log(error);
        return []
    }
}

const bookmarkTool = async (type = "PUT", name, version) => {
    try {
        const token = await UserAPI.getToken();
        var url = process.env.REACT_APP_BACK_END_URL + "/bookmark?type=tool&name=" + name + "&version=" + version
        const response = await fetch(url, {
            method: type,
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const json = await response.json();
        return [name + '-' + version]
    } catch (error) {
        console.log(error);
        return []
    }
}

const UserAPI = {
    getToken: getToken,
    getProfile: getProfile,
    bookmarkTool: bookmarkTool,
    bookmarkProject: bookmarkProject
}
export default UserAPI;