import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import {
    Card,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function FairnessStage(props) {
    const classes = useStyles();

    const {
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
        projectID: projectID,
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
    ]
    if (stages.hasOwnProperty('Fairness') && (stages['Fairness'].hasOwnProperty('Link') || stages['Fairness'].hasOwnProperty('Notebook'))) {
        tmpTabs.push(
            {
                tabButton: "Fairness",
                tabContent: (

                    <Card className={classes.card}>
                        <CardHeader icon>
                            <CardIcon color="quSandBox">
                                <IconDict.Fairness />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Fairness
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="center">
                                {stages['Fairness'].hasOwnProperty('Notebook') && <GridItem xs={12} style={{ textAlign: "center" }}> <Button
                                    color="info"
                                    size="lg"
                                    onClick={() =>
                                        window.open(
                                            stages['Fairness']['Notebook']
                                        )
                                    }
                                    className={classes.buttonsTopSpace}
                                >
                                    Run on QuSandbox
                                </Button>
                                </GridItem>
                                }
                                {stages['Fairness'].hasOwnProperty('Link') &&
                                    <iframe
                                        title="Fairness"
                                        id="Fairness"
                                        src={stages['Fairness'].Link}
                                        width="100%"
                                        frameBorder="0"
                                        height={`${stages['Fairness'].IframeHeight || 1600}px`}
                                    ></iframe>
                                }
                            </GridContainer>
                        </CardBody>
                    </Card>
                ),
                tabIcon: IconDict['Fairness']
            })
    } else {
        tmpTabs.push(
            {
                tabButton: "Fairness",
                tabContent: (
                    <Card className={classes.card}>
                        <CardHeader icon>
                            <CardIcon color="quSandBox">
                                <IconDict.Fairness />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Fairness</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="center">
                                <Button
                                    color="info"
                                    size="lg"
                                    href={`${process.env.PUBLIC_URL}/admin/QuToolBox/Fairness`}
                                    target="_blank"
                                >
                                    Browse Tools
                                </Button>
                            </GridContainer>
                        </CardBody>
                    </Card>
                ),
                tabIcon: IconDict['Fairness']
            }
        )
    }
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('Fairness') ? stages['Fairness'].PK : ""}
                    notes={allNotes.hasOwnProperty("Fairness")
                        ? allNotes.Fairness
                        : []}
                    stageType="Fairness"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("Fairness")
                        ? allNotes.Fairness
                        : []}
                    setNotes={setNotesHelper}
                    stageType="Fairness"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("Fairness")
                            ? allIssues.Fairness
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="Fairness"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}