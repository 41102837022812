var availableProviders = {
    "QuSandbox": {
        "attributes": [],
        "thumbnail": "qusandbox_logo_v2.5.png"
    }, "PPML": {
        "attributes": [],
        "thumbnail": "qusandbox_logo_v2.5.png"
    }, "S&P": {
        "attributes": ["APIKey"],
        "thumbnail": "s&p_global.png"
    }, "Moody's": {
        "attributes": ["APIKey"],
        "thumbnail": "131px-Moody’s_logo.svg.png"
    }, "FactSet": {
        "attributes": ["UserName", "APIKey"],
        "thumbnail": "FactSet-Web.png"
    }, "NVIDIA": {
        "attributes": [],
        "thumbnail": "Nvidia_logo.png"
    }, "Google": {
        "attributes": ["APIKey"],
        "thumbnail": "368px-Google_2015_logo.svg.png"
    }, "AWS": {
        "attributes": ["access_key_ID", "secret_access_key"],
        "thumbnail": "Amazondotcom.png"
    }, "Azure": {
        "attributes": ["APIKey"],
        "thumbnail": "800px-Microsoft_Azure_Logo.svg.png"
    }, "IBM": {
        "attributes": ["APIKey"],
        "thumbnail": "1000px-IBM_logo.svg.png"
    }, "MathWorks": {
        "attributes": ["APIKey"],
        "thumbnail": "mathworks_logo.png"
    }, "Limit": {
        "attributes": [],
        "thumbnail": "qusandbox_logo_v2.5.png"
    }, "Custom": {
        "attributes": [],
        "thumbnail": "qusandbox_logo_v2.5.png"
    }
};

export default availableProviders;