/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-google";

import AuthLayout from "layouts/Auth.js";
// import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

// import APIDetails from "views/APIs/APIDetails.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

import Amplify, { Auth } from "aws-amplify";
// import awsconfig from './aws-exports';
Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      // OPTIONAL - Cookie path
      expires: 7
    },
  },
});
// window.LOG_LEVEL = 'DEBUG';

loadReCaptcha();

const hist = createBrowserHistory();
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* <Route path="/rtl" component={RtlLayout} /> */}
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      {/* <Redirect from="/" to="/auth/login-page" /> */}
      <Redirect from="/" to="/admin/quapi/all" />
      {/* <Redirect from="/admin/dashboard" to="/admin/quapi" /> */}

      {/* <Redirect from="/admin/qumodelstudio" to="https://example.com/1234" /> */}

      {/* <Route path="/admin/:heading" 
             render={(props) =>
              <APIDetails {...props} />
                 } /> */}
    </Switch>
  </Router>,
  document.getElementById("root")
);
