import React, { useEffect } from "react";
import PropTypes from "prop-types";
// Amplify components
import { Auth } from "aws-amplify";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Heading from "components/Heading/Heading.js";

import {
  Slide,
  Tooltip,
} from "@material-ui/core";

import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";


import AssessmentIcon from "@material-ui/icons/Assessment";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Close } from "@material-ui/icons";
import CachedIcon from '@material-ui/icons/Cached';

import "survey-react/survey.css";
import {
  Dialog,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";

import Table from "components/Table/Table.js";
import { useRouteMatch } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer";

import CardIcon from "components/Card/CardIcon";

import styles from "assets/jss/material-dashboard-pro-react/components/reportStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);
const usedashboardStyle = makeStyles(dashboardStyle);
const useModalStyles = makeStyles(modalStyle);

export default function ReportViewer(props) {
  const classes = useStyles();
  const dashboardClasses = usedashboardStyle();
  const modalClasses = useModalStyles();

  const { params } = useRouteMatch();
  const [user, setUser] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const [surveyJson, setSurveyJson] = React.useState({
    focusFirstQuestionAutomatic: false,
    focusOnFirstError: false,
    showNavigationButtons: false,
  });

  const savedReportsRef = React.useRef([]);
  const [savedReports, setSavedReports] = React.useState([]);
  const [reportHeight, setReportHeight] = React.useState("200");
  const [reportModel, setReportModel] = React.useState('close');
  const [HTML, setHTML] = React.useState();
  const [MD5, setMD5] = React.useState();
  const [viewing, setViewing] = React.useState("report")

  useEffect(() => {
    // console.log(props.stageId);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login";
        }
      });
    setSurveyJson({
      ...surveyJson,
      ...require('variables/survey.json'),
    });
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      refreshContent()
    }
  }, [user]);

  const refreshContent = () => {
    setLoading(true);
    if (props.stageId !== "" || props.stageType !== "") {
      let url = "";
      if (props.stageId !== "") {
        url =
          process.env.REACT_APP_BACK_END_URL +
          `/experiment/${params.exp_id}/stage/${props.stageId.split("#")[1]
          }/artifact`;
      } else if (props.stageType !== "") {
        url =
          process.env.REACT_APP_BACK_END_URL +
          `/experiment/${params.exp_id}/stages/${props.stageType}/artifact`;
      }
      url = url + "?method=track"
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            savedReportsRef.current = (
              data.Items.map((item, i) => {
                return helper(item, i)
              }
              )
            )
            setSavedReports(savedReportsRef.current)
            setLoading(false);
          });
        },
        function (err) {
          // showNotification("Report read failed.", "danger");
          console.log(err);
          setLoading(false);
        }
      );
    }
  }

  const downloadHTML = () => {
    var element = document.createElement("a");
    var file = new Blob([HTML], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = viewing + ".html";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const handleViewQuTrack = (item) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${item.ExperimentId.split('#')[1]}/stage/${item.PK.split("#")[1]
      }/artifact/${item.SK.split("#")[1]}?method=publish`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        console.log(data)
        setMD5(data.md5)
        setHTML(data.Artifact)
        setViewing(item.Content.report_parameters.name);
        setReportModel('publish')
      });
    });
  }
  const resizeReportIframe = (e) => {
    const frame = e.target;
    setReportHeight(
      frame.contentWindow.document.documentElement.scrollHeight
    );
  };

  const helper = (item, i) => {
    if (item.hasOwnProperty('Content')) {
      try {
        item.Content = JSON.parse(item.Content)
      } catch (e) {
        console.log('Already json')
      }
    }
    return {
      ...item,
      PK: item.SK.split("#")[1],
      UpdatedAt: new Date(item.UpdatedAt * 1000).toLocaleString(),
      actions:
        (
          <div key={i}>
            {item.QLDBReference &&
              <Tooltip
                id="tooltip-top"
                title="View on QuTrack"
                placement="bottom"
                classes={{ tooltip: dashboardClasses.tooltip }}
              >
                <Button
                  color="transparent"
                  size="sm"
                  onClick={() => handleViewQuTrack(item, i)}
                >
                  <VerifiedUserIcon />
                </Button>
              </Tooltip>

            }
          </div>
        ),
    };
  }

  return (
    <>
      <Dialog
        open={reportModel !== 'close'}
        transition={Transition}
        maxWidth="lg"
        scroll='body'
        keepMounted
        onClose={() => setReportModel('close')}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <CardHeader color="quSandBox" icon>
          <Button
            justIcon
            className={modalClasses.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setReportModel('close')}
            style={{ marginTop: "0px" }}
          >
            <Close className={modalClasses.modalClose} />
          </Button>
          {reportModel === 'view' && <h4 className={classes.cardIconTitle}>View Report</h4>}
          {reportModel === 'publish' && <h4 className={classes.cardIconTitle}>Browse QuTrack</h4>}
        </CardHeader>
        <DialogContent
          id="modal-slide-description"
          className={modalClasses.modalBody}
        >
          {MD5 && <GridContainer justify="center"><div>MD5:{MD5}</div></GridContainer>}
          {HTML && <iframe
            id="data"
            title="data"
            srcDoc={HTML}
            width="800px"
            frameBorder="0"
            height={`${reportHeight}px`}
            scrolling="no"
            onLoad={resizeReportIframe}
          ></iframe>}
          <GridContainer justify="center">
            {/* {HTML && <Button color="info" onClick={downloadPDF}>Download PDF</Button>} */}
            {HTML && <Button color="info" onClick={downloadHTML}>Download HTML</Button>}
          </GridContainer>
        </DialogContent>
      </Dialog>

      {loading && <Heading title={<CircularProgress />} textAlign="center" />}
      {savedReports && <Card>
        <CardHeader icon>
          <CardIcon color="quSandBox">
            <AssessmentIcon />
          </CardIcon>
          <Button
            justIcon
            className={modalClasses.modalCloseButton}
            key="refresh"
            aria-label="refresh"
            color="transparent"
            onClick={() => refreshContent()}
            style={{ marginTop: "0px" }}
          >
            <CachedIcon className={modalClasses.modalClose} />
          </Button>
          <h4 className={classes.cardIconTitle}>Published Reports on QuTrack</h4>
        </CardHeader>
        <CardBody>
          {savedReports.length > 0 &&
            <Table
              tableHead={["#", "Id", "Board", "Title", "Version", "Last Updated", "Actions"]}
              tableData={savedReports.map((item, id) => [
                id + 1,
                item.PK,
                item.StageType,
                item.Content.report_parameters.name,
                item.Content.report_parameters.version,
                item.UpdatedAt,
                item.actions,
              ])}
              customCellClasses={[classes.center, classes.right]}
              customClassesForCells={[0, 6]}
              customHeadCellClasses={[classes.center, classes.right]}
              customHeadClassesForCells={[0, 6]}
            />
          }
          {savedReports.length === 0 && <p>No reports published</p>}
        </CardBody>
      </Card>}

    </>
  );
}
ReportViewer.defaultProps = {
  stageId: "",
  stageType: "",
};

ReportViewer.propTypes = {
  stageId: PropTypes.string,
  stageType: PropTypes.string,
};
