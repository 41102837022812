import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import {
    Card,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";

import { Survey } from "survey-react";
import TemplateAPI from "views/APIs/TemplateAPI.js"

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function ScoreStage(props) {
    const classes = useStyles();
    const history = useHistory();

    const [API, setAPI] = React.useState('score')
    const [template, setTemplate] = React.useState({})

    const [iframeHeight, setIframeHeight] = React.useState("800");
    const { showNotification } = React.useContext(NotificationContext);

    const [surveyJson, setSurveyJson] = React.useState({
        "focusFirstQuestionAutomatic": false,
        "focusOnFirstError": false,
        showQuestionNumbers: "off"
    });
    const [HTML, setHTML] = React.useState()
    const [surveyResult, setSurveyResult] = React.useState({});

    const downloadHTML = (html) => {
        var element = document.createElement("a");
        var file = new Blob([html], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "Report.html";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const onValueChanged = (res) => {
        setSurveyResult(res.data);
    };

    const resizeIframe = (e) => {
        const frame = e.target;
        setIframeHeight(frame.contentWindow.document.documentElement.scrollHeight);
    };

    const onComplete = () => {
        showNotification("Updating score report", "info");
        let url =
            process.env.REACT_APP_BACK_END_URL +
            `/experiment/${exp_id}/${props.config.name.toLowerCase()}/`;
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(url, {
            method: "PUT",
            mode: "cors",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify(surveyResult),
        }).then(
            function (res) {
                res.json().then(function (data) {
                    history.push("?stage=" + props.config.name);
                    history.go();
                    showNotification("Score Report updated successfully", "quSandBoxColor");
                });
            },
            function (err) {
                console.log(props.stageId);
                showNotification("Report updated failed.", "danger");
                history.push("?stage=Score");
                history.go();
                console.log(err);
            }
        );
    }

    const {
        allNotes: [allNotes, setNotesHelper],
        // stages: [stages, setStages],
        exp_id: exp_id,
        projectID: projectID,  
        user: user,
    } = React.useContext(ExperimentContext)

    useEffect(() => {
        TemplateAPI.getTemplate(props.config.template).then(function (result) {
            console.log(JSON.parse(result.templateValue))
            setTemplate(result);
            setSurveyJson({...surveyJson, ...JSON.parse(result.templateValue)})
        });
    }, [props.template])

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            console.log('retrieving score report')
            let token = user.signInUserSession.accessToken.jwtToken;
            fetch(process.env.REACT_APP_BACK_END_URL + `/experiment/${exp_id}/${props.config.name.toLowerCase()}`, {
                method: "GET",
                mode: "cors",
                credentials: "omit",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }).then(
                function (res) {
                    res.json().then(function (data) {
                        if (data.hasOwnProperty('Content')) {
                            setSurveyResult(JSON.parse(data['Content']))
                        }
                        if (data.hasOwnProperty('S3URL')) {
                            fetch(
                                data['S3URL'],
                                {
                                    method: "GET",
                                    mode: "cors",
                                    credentials: "omit",
                                    cache: "no-cache",
                                }
                            ).then((res) => {
                                res.text().then((data) => {
                                    setHTML(data);
                                });
                            });
                        }
                    });
                },
                function (err) {
                    console.log(err);
                }
            );
        }
    }, [user]);

    const formatIcon = (jsx=true) => {
        var index = props.index % IconDict['Report'].length;
        var IconObject = IconDict['Report'][index]
        if (jsx) {
            return (<IconObject />)
        } else {
            return IconObject
        }
    }

    let tmpTabs = [
        {
            tabButton: props.config.name,
            tabContent: (
                <Card className={classes.card}>
                    <CardHeader icon>
                        <CardIcon color="quSandBox">
                            {formatIcon()}
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{props.config.name}</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <Survey
                                data={surveyResult}
                                json={surveyJson}
                                showCompletedPage={false}
                                onComplete={onComplete}
                                onValueChanged={onValueChanged}
                            />
                        </GridContainer>
                    </CardBody>
                </Card>
            ),
            tabIcon: formatIcon(false)
        },
        {
            tabButton: "Reports",
            tabIcon: IconDict['Reports'],
            tabContent: (<Card className={classes.card}>
                <CardHeader icon>
                    <CardIcon color="quSandBox">
                        {formatIcon()}
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>{props.config.name} Report</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer justify="center">
                        <iframe
                            title="score"
                            id="score"
                            srcDoc={HTML}
                            width="100%"
                            frameBorder="0"
                            height={`${iframeHeight}px`}
                            onLoad={resizeIframe}
                        ></iframe>
                        <Button
                            color="info"
                            size="lg"
                            onClick={() => downloadHTML(HTML)}
                        >
                            Download HTML
                        </Button>
                    </GridContainer>
                </CardBody>
            </Card>)
        },
        {
            tabButton: "Notes",
            tabIcon: IconDict['Notes'],
            tabContent: (<Notes
                notes={allNotes.hasOwnProperty(props.config.name)
                    ? allNotes[props.config.name]
                    : []}
                setNotes={setNotesHelper}
                stageType={props.config.name}
            />),
        }
    ]

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}