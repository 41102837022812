import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Heading from "components/Heading/Heading.js";
import {
    Card,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function GeneralReportStage(props) {
    const {
        allNotes: [allNotes, setNotesHelper],
        stages: [stages, setStages],
        projectID: projectID,  
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
        {
            tabButton: "Reports",
            tabIcon: IconDict['Reports'],
            tabContent: (<GeneralReportTemplate
                templateId={
                    props.config.template
                        ? props.config.template
                        : ""
                }
                projectId={projectID}
                stageId={stages.hasOwnProperty(props.config.name) ? stages[props.config.name].PK : ""}
                notes={allNotes.hasOwnProperty(props.config.name)
                    ? allNotes.Audit
                    : []}
                stageType={props.config.name}
            />)
        },
        {
            tabButton: "Notes",
            tabIcon: IconDict['Notes'],
            tabContent: (<Notes
                notes={allNotes.hasOwnProperty(props.config.name)
                    ? allNotes[props.config.name]
                    : []}
                setNotes={setNotesHelper}
                stageType={props.config.name}
            />),
        }
    ]

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}