import React, { useEffect, useState } from "react";
import {
  useHistory,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import ReactTable from "components/ReactTable/ReactTable.js";
import { Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import UserAccessControls from "views/Pages/admin/UserAccessControls";

const useStyles = makeStyles(styles);
export default function UserTablePage() {
  const { path, url, } = useRouteMatch();
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = React.useState();
  // const fields = ["User ID", "User Name", "Permissions"];

  const [columns, setColumns] = useState(
    [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "User ID",
        accessor: "userid",
      },
      {
        Header: "Email ID",
        accessor: "email",
      },
      {
        Header: "User Create Date",
        accessor: "userCreateDate",
      },  {
        Header: "User Status",
        accessor: "userStatus",
      },
      {
        Header: "Actions",
        accessor: "actions",
        // disableFilters: "true",
        disableSortBy: true,
      },
    ]
  );

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (user) {
        setUser(user);
      })
      .catch((err) => {
        // console.log(err);
        history.push("/auth/login-page");
      });
  }, []);

  useEffect(() => {
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(process.env.REACT_APP_BACK_END_URL + `/admin/user/all`, {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }).then(
        function (res) {
          res.json().then(function (data) {
            setUserData(data.Users.map((userDetail, key)=>{
              // console.log(userDetail.UserCreateDate)
              return {
                id: key+1,
                userid: userDetail.Username,
                email: userDetail.Attributes[0].Value,
                userStatus: userDetail.UserStatus,
                userCreateDate: new Date(userDetail.UserCreateDate*1000).toLocaleDateString(),
                actions:(
                          <div className="actions-center">
                      <Link to={`${path}/${userDetail.Username}/${userDetail.Attributes[0].Value}`}>
                        <Button
                          // justIcon
                          // round
                          // simple
                          // color="info"
                          className="like"
                        >
                          {" "}
                          <Edit />
                        </Button>
                      </Link>
                      </div>
                )
              }
            }));
          });
        },
        function (err) {
          console.log(err);
        }
      );
    }
  }, [user]);

  return (
    <Switch>
      <Route exact path={path}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="quSandBox" icon>
                <CardIcon color="quSandBox">
                  <Timeline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>User Access</h4>
              </CardHeader>
              <CardBody>
                <ReactTable columns={columns} data={userData} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Route>
      <Route path={`${path}/:userid/:userEmail`}>
        <UserAccessControls />
      </Route>
    </Switch>
  );
}
