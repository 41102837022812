const MRMProjectStructure = [
  {
    "name": "Model Risk Management",
    "stages": [
      {
        "name": "Summary",
        "type": "Summary",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "7acd5c69079946b199c8bab692512f27"
      },
      {
        "name": "Findings",
        "type": "Findings",
        "visible": true,
        "notes": true,
        "issues": false,
        "reports": false
      },
      {
        "name": "Recommendations",
        "type": "Recommendations",
        "visible": true,
        "notes": true,
        "issues": false,
        "reports": false
      },
      {
        "name": "Templates",
        "type": "Static",
        "visible": true,
        "notes": false,
        "issues": false,
        "reports": false
      }
    ]
  },
  {
    "name": "Templates",
    "type": "Report",
    "stages": [
      {
        "name": "Scope",
        "type": "Report",
        "visible": true,
        "template": "4a5959e97e3e480690a0a3cbeb4e2483"
      },
      {
        "name": "Quantity",
        "type": "Report",
        "visible": true,
        "template": "0f86a97036c74da3bc3b44e26dedeeb4"
      },
      {
        "name": "Quality",
        "type": "Report",
        "visible": true,
        "template": "2b720b765d1044448d0d16a524d23bf6"
      },
      {
        "name": "Direction",
        "type": "Report",
        "visible": true,
        "template": "24f145d21d334888a3af033c2c0447e6"
      },
      {
        "name": "Internal Control",
        "type": "Report",
        "visible": true,
        "template": "98e4d2214dc94abdafda51c8870aba98"
      }
    ]
  }
]

export default MRMProjectStructure;