import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/alertStyle.js";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
const useStyles = makeStyles(styles);
export default function Alert(props) {
  const classes = useStyles();

  return (
    <div>
      <Dialog justify="center" open={true} onClose={props.onCancel}
      fullWidth={true}
      maxWidth='xs'
      >
        {props.warning && (
          <DialogTitle justify="center">
            <ErrorOutlineIcon className={classes.warning} />
          </DialogTitle>
        )}

        <DialogTitle className={classes.alertTitle}>{props.title}</DialogTitle>
        <DialogContent className={classes.alertContent} >{props.children}</DialogContent>
        <DialogActions className={classes.buttonCenter}>
          {props.showCancel && (
            <Button onClick={props.onCancel} color="info">
              {!!props.cancelBtnText ? <>{props.cancelBtnText}</> : "Cancel"}
            </Button>
          )}
          <Button onClick={props.onConfirm} color="info">
            {!!props.confirmBtnText ? <>{props.confirmBtnText}</> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
Alert.defaultProps = {
  confirmBtnText: "",
  cancelBtnText: "",
  title: "",
  showCancel: false,
  warning: false,
};

Alert.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  title: PropTypes.string,
  showCancel: PropTypes.bool,
  warning: PropTypes.bool,
};
