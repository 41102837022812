import {
  successColor,
  tooltip,
  primaryColor,
  dangerColor,
} from "assets/jss/material-dashboard-pro-react.js";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const riskMetricStyle = (theme) => ({
  ...regularFormsStyle,
  ...hoverCardStyle,
  ...customSelectStyle,
  ...extendedTablesStyle,
  tooltip,

  table: {
    tableLayout: "fixed",
    width: "100%",
    textAlign: "center",
    border: "1px solid black",
    borderCollapse: "collapse"
  },
  td: {
    border: "1px solid black"
  },
  low: {
    backgroundColor: successColor[0]
  },
  moderate: {
    backgroundColor: "#FFFF00"
  },
  high: {
    backgroundColor: dangerColor[0]
  },
  selected: {
    border: "5px solid " + primaryColor[0],
    fontWeight: "bold"
  }
});

export default riskMetricStyle;
