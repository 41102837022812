const AIStructure = [
  {
    "name": "AI and ML Validation",
    "stages": [
      {
        "name": "Executive summary",
        "type": "Summary",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "ca417b266eea47aab7140afdb0826a8c"
      },
      {
        "name": "Validation summary",
        "type": "Environment",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "48e333fa22534a5cae9811265c1d2e77"
      },
      {
        "name": "Framework and theory",
        "type": "Explainability",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "143aabc4382747f59244a562a6c67746"
      },
      {
        "name": "Data",
        "type": "Data",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "588134287a1a4e4aabc232b86f406b3a"
      },
      {
        "name": "Model",
        "type": "Model",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "00c8a5116deb4854aac09a151e4b363e"
      },

      {
        "name": "Model performance and testing",
        "type": "TestPlan",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "dae30d310fdd4f678c696d46705adec9"
      },
      {
        "name": "Model outputs, reports, and uses",
        "type": "Report",
        "visible": true,
        "template": "39deef91d48f4010a3ba56e57e95f8b3"
      },
      {
        "name": "Model implementation",
        "type": "Deployment",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "5312073b089e4c4b8b96d21cb5830ba4"
      },
      {
        "name": "Model management",
        "type": "Monitoring",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "be7bc896fa0d41d2b8ce0bb0aeaa4487"
      }
    ]
  }
]

export default AIStructure;