import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Heading from "components/Heading/Heading.js";
import {
    Card,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function TestPlanStage(props) {
    const classes = useStyles();

    const {
        projectID: projectID,
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
    ]
    if (stages.hasOwnProperty('TestPlan') && stages['TestPlan'].hasOwnProperty('Link')) {
        tmpTabs.push(
            {
                tabButton: "Test",
                tabContent: (

                    <Card className={classes.card}>
                        <CardHeader icon>
                            <CardIcon color="quSandBox">
                                <IconDict.TestPlan />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Test Plan
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="center">
                                <iframe
                                    title="TestPlan"
                                    id="TestPlan"
                                    src={stages['TestPlan'].Link}
                                    width="100%"
                                    frameBorder="0"
                                    height={`${stages['TestPlan'].IframeHeight || 1600}px`}
                                ></iframe>
                            </GridContainer>
                        </CardBody>
                    </Card>
                ),
                tabIcon: IconDict['TestPlan']
            })
    }
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('TestPlan') ? stages['TestPlan'].PK : ""}
                    notes={allNotes.hasOwnProperty("TestPlan")
                        ? allNotes.TestPlan
                        : []}
                    stageType="TestPlan"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("TestPlan")
                        ? allNotes.TestPlan
                        : []}
                    setNotes={setNotesHelper}
                    stageType="TestPlan"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("TestPlan")
                            ? allIssues.TestPlan
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="TestPlan"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}