import {
  successColor,
  tooltip,
  cardTitle,
  grayColor,
  quSandBoxColor,
} from "assets/jss/material-dashboard-pro-react.js";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
const dashboardStyle = (theme) => ({
  ...customSelectStyle,
  ...hoverCardStyle,
  tooltip,
  root: {
    flexGrow: 1,
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    // marginBottom: "20px"
  },
  card: {
    overflow:"visible",
    // paddingBottom: "35px",
    // marginBotton:"50px"
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "30px",
    paddingTop:"25px",
    marginBottom: "0px",
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center",
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0],
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px",
    },
  },
  thumbnail: {
    width: '90%',
    height: '90%',
    maxWidth: '200px',
    maxHeight: '150px'
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0",
  },
  successText: {
    color: successColor[0],
  },
  quSandBoxColor: {
    color: quSandBoxColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  buttons: {
    marginLeft: "15px",
    marginRight: "15px",
    marginBottom: "30px",
  },
  buttonsTopSpace: {
    marginLeft: "15px",
    marginRight: "15px",
    marginBottom:"30px"
    // marginTop: "30px",
  },
  subHeadings: {
    fontSize: "32.85px",
    lineHeight: "1.4em",
    fontWeight: "bold",
    color: "#555555",
    marginTop: "0px",
    textAlign: "center",
  },
  summaryTab: {
    color: "inherit",
    fontSize: "13.3333px",
    "& b": {
      fontSize: "17.3333px",
    },
  },
  htmlContainer: {
    marginRight: "auto",
    marginLeft: "auto",
  },
  gistclass: {
    "& .blob-wrapper": {
      height: "600px",
      overflowX: "auto",
      overflowY: "auto!important",
    },
  },
});

export default dashboardStyle;
