import CreateIcon from "@material-ui/icons/Create";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SearchIcon from "@material-ui/icons/Search";
import BuildIcon from "@material-ui/icons/Build";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import TableChartIcon from "@material-ui/icons/TableChart";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import StorageIcon from "@material-ui/icons/Storage";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ChatIcon from "@material-ui/icons/Chat";
import GavelIcon from '@material-ui/icons/Gavel';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';
import ExploreIcon from "@material-ui/icons/Explore";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import SecurityIcon from '@material-ui/icons/Security';
import FunctionsIcon from '@material-ui/icons/Functions';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import TimelineIcon from '@material-ui/icons/Timeline';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BugReportIcon from '@material-ui/icons/BugReport';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

const IconDict = {
    "Summary": ChatIcon,
    "Pipeline": CompareArrowsIcon,
    "Data": StorageIcon,
    "Model": ViewModuleIcon,
    "Environment": BuildIcon,
    "Explore": ExploreIcon,
    "Explainability": AssessmentIcon,
    "Fairness": ThumbsUpDownIcon,
    "Deployment": GavelIcon,
    "Monitoring": TableChartIcon,
    "TestPlan": EditAttributesIcon,
    "StressTests": FastRewindIcon,
    "ScenarioTests": WallpaperIcon,
    "WhatIfAnalysis": TimelineIcon,
    "Issues": LiveHelpIcon,
    "Findings": SearchIcon,
    "Recommendations": ThumbUpIcon,
    "Report": [ CloudQueueIcon, DoneAllIcon, WarningIcon, SecurityIcon, FunctionsIcon, SupervisedUserCircleIcon],
    "Reports": AssessmentIcon,
    "Notes": CreateIcon,
    "Audit": PlaylistAddCheckIcon,
    "MLThreat":VpnKeyIcon,
    "MLSecurity": EnhancedEncryptionIcon,
    "FailureMode": BugReportIcon,
    "Templates": AccountTreeIcon
}

export default IconDict;