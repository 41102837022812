import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import AccountBalance from "@material-ui/icons/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import { CardActionArea } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function PricingPage() {
  const classes = useStyles();
  const [button1, setbutton1] = React.useState(false);
  const [button2, setbutton2] = React.useState(false);
  const [button3, setbutton3] = React.useState(false);


  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Pick the best plan for you</h2>
          <h5 className={classes.description}>
            You have Free Unlimited Updates and Premium Support on each package.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>
          <Card pricing plain={!button1} raised={button1}>
            <CardActionArea onMouseEnter={()=>setbutton1(!button1)} onMouseLeave={()=>setbutton1(!button1)}>
          {/* <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter} `}
                color="quSandBox"
              >
                <h4 className={classes.cardTitle}>Free</h4>
                </CardHeader> */}
            <CardBody pricing  plain={!button1}>
              <h6 className={`${button1?classes.cardDescription:classes.cardCategory}`}>Free</h6>
              <div className={classes.icon}>
                <Icon className={{[classes.iconWhite]:!button1} + '' + {[classes.iconRose]:button1}}>weekend</Icon>
              </div>
              <h3 className={`${button1?classes.cardTitle:classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                FREE
              </h3>
              <p className={`${button1?classes.cardDescription:classes.cardCategory}`}>
                This is good if your company size is between 2 and 10 Persons.
              </p>
              <Button round color={button1?"rose":"white"} href="https://quantuniversity.com/contact.html" target="_blank">
              Contact Sales
              </Button>
            </CardBody>
            </CardActionArea>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card pricing plain={!button2} raised={button2}>
          <CardActionArea onMouseEnter={()=>setbutton2(!button2)} onMouseLeave={()=>setbutton2(!button2)}>
          {/* <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter} `}
                color="quSandBox"
              >
                <h4 className={classes.cardTitle}>INDIVIDUAL</h4>
                </CardHeader> */}
            <CardBody pricing plain={!button2}>
              <h6 className={`${button2?classes.cardDescription:classes.cardCategory}`}>INDIVIDUAL</h6>
              <div className={classes.icon}>
                <Home className={{[classes.iconWhite]:!button2} + '' +  {[classes.iconRose]:button2}} />
              </div>
              <h3 className={`${button2?classes.cardTitle:classes.cardTitleWhite} ${classes.marginTop30}`}>
                On-Demand
              </h3>
              <p className={`${button2?classes.cardDescription:classes.cardCategory}`}>
                This is good if your company size is between 2 and 10 Persons.
              </p>
              <Button round color={button2?"rose":"white"} href="https://quantuniversity.com/contact.html" target="_blank">
               Contact Sales
              </Button>
            </CardBody>
            </CardActionArea>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={3}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h6 className={classes.cardCategory}>MEDIUM COMPANY</h6>
              <div className={classes.icon}>
                <Business className={classes.iconWhite} />
              </div>
              <h3
                className={`${!button2?classes.cardTitle:classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                $69
              </h3>
              <p className={classes.cardCategory}>
                This is good if your company size is between 11 and 99 Persons.
              </p>
              <Button round color="white">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={4}>
          <Card pricing plain={!button3} raised={button3}>
          <CardActionArea onMouseEnter={()=>setbutton3(!button3)} onMouseLeave={()=>setbutton3(!button3)}>
            <CardBody pricing  plain={!button3}>
              <h6 className={`${button3?classes.cardDescription:classes.cardCategory}`}>ENTERPRISE</h6>
              <div className={classes.icon}>
                <AccountBalance className={{[classes.iconWhite]:button3} + '' +  {[classes.iconRose]:button3}} />
              </div>
              <h3
                className={`${button3?classes.cardTitle:classes.cardTitleWhite} ${classes.marginTop30}`}
              >
                On-Demand
              </h3>
              <p className={`${button3?classes.cardDescription:classes.cardCategory}`}>
                This is good if your company size is 99+ persons.dasdad sadasd adasd
              </p>
              <Button round color={button3?"rose":"white"} href="https://quantuniversity.com/contact.html" target="_blank">
              Contact Sales
              </Button>
            </CardBody>
            </CardActionArea>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
