const defaultProjectStructure = [
  {
    "name": "Model Lifecycle",
    "stages": [
      {
        "name": "Summary",
        "type": "Summary",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "7acd5c69079946b199c8bab692512f27"
      },
      {
        "name": "Environment",
        "type": "Environment",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "cb255c79ddbf4278b908607c6613ad0d"
      },
      {
        "name": "Data",
        "type": "Data",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "ff14ff4225804f6f8f787f22460e1f63"
      },
      {
        "name": "Model",
        "type": "Model",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "000c3a5d36304f4ea1ae9efacdd7d12f"
      },
      {
        "name": "Explainability",
        "type": "Explainability",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "55359fd8bc984fd4928e03d69a3385b7"
      },
      {
        "name": "Fairness",
        "type": "Fairness",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "5624d211c7304a0fa1dde3d5a2942541"
      },
      {
        "name": "Deployment",
        "type": "Deployment",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "ca43f3b438d041278516e5e000bb85e4"
      },
      {
        "name": "Monitoring",
        "type": "Monitoring",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "5e3e607a02dd48df9887a9d8a51b0ea5"
      }
    ]
  },
  {
    "name": "Testing",
    "stages": [
      {
        "name": "TestPlan",
        "type": "TestPlan",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "8e691d84cbd248e193879a140158243c"
      },
      {
        "name": "StressTests",
        "type": "StressTests",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "fae2f711d0f94c3e869e2b5cefaf821e"
      },
      {
        "name": "ScenarioTests",
        "type": "ScenarioTests",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "a93766a91b454605bf9f8ef459f06a5d"
      },
      {
        "name": "WhatIfAnalysis",
        "type": "WhatIfAnalysis",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "ca757f83eb214bcc9c9ad7c39cd6cd26"
      }
    ]
  }, 
  {
    "name": "ML Security Review ",
    "stages": [
      {
        "name": "MLSecurity",
        "type": "MLSecurity",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "e6b7e2e4c58240caac078e4250e2aed1"
      },
      {
        "name": "MLThreat",
        "type": "MLThreat",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "c407a87b618d4493a2397fb95d481fb5"
      },
      {
        "name": "FailureMode",
        "type": "FailureMode",
        "visible": true,
        "notes": true,
        "issues": true,
        "reports": true,
        "template": "a074d26e8f7846a8b0ae339409884363"
      }
    ]
  },
  {
    "name": "Algorithmic Assessment",
    "type": "Report",
    "stages": [
      {
        "name": "Templates",
        "type": "Static",
        "visible": true,
        "notes": false,
        "issues": false,
        "reports": false
      },
      {
        "name": "Issues",
        "type": "Issues",
        "visible": true,
        "notes": true,
        "issues": false,
        "reports": false
      },
      {
        "name": "Audit",
        "type": "Report",
        "visible": true,
        "template": "d83a99296ec64b4fa6c12484256e75dc"
      },
      {
        "name": "AI",
        "type": "Report",
        "visible": true,
        "template": "0243d646c48746fabac34355d0261307"
      },
      {
        "name": "Risk",
        "type": "Report",
        "visible": true,
        "template": "f43c0eff2d254e3fa29e6a41c734c6c4"
      },
      {
        "name": "Findings",
        "type": "Findings",
        "visible": true,
        "notes": true,
        "issues": false,
        "reports": false
      },
      {
        "name": "Recommendations",
        "type": "Recommendations",
        "visible": true,
        "notes": true,
        "issues": false,
        "reports": false
      }
    ]
  }
]

export default defaultProjectStructure;