import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Link,
} from "react-router-dom";

import { Auth } from "aws-amplify";

import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import Table from "components/Table/Table.js";
import Heading from "components/Heading/Heading";
import CustomInput from "components/CustomInput/CustomInput.js";
import Alert from "views/Components/Alert.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import CardFooter from "components/Card/CardFooter.js";
import { Survey } from "survey-react";

import {
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    FormControlLabel,
    TextField,
} from "@material-ui/core";


import VisibilityIcon from "@material-ui/icons/Visibility";
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import AssessmentIcon from '@material-ui/icons/Assessment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Close, AddAlert } from "@material-ui/icons";

import TemplateAPI from "views/APIs/TemplateAPI.js"


import styles from "assets/jss/material-dashboard-pro-react/components/reportStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const usedashboardStyle = makeStyles(dashboardStyle);
const useModalStyles = makeStyles(modalStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);

export default function TemplateEditor(props) {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const dashboardClasses = usedashboardStyle();
    const extendedFormsClasses = useextendedFormsStyles();
    const sweetAlertClasses = usesweetAlertStyle();

    const images = require.context("assets/img", true);
    const [templates, setTemplates] = useState([]);
    const [viewModal, setViewModal] = useState('close');
    const [editorModal, setEditorModal] = useState('close');
    const [editModal, setEditModal] = useState('close');
    const [editTemplate, setEditTemplate] = useState({});
    const [viewTemplate, setViewTemplate] = useState({});
    const [editIndex, setEditIndex] = useState(-1);
    const [templateNameState, setTemplateNameState] = useState()
    const [templateJsonState, setTemplateJsonState] = useState()
    const [editError, setEditError] = useState()

    const [userType, setUserType] = React.useState("");
    const [user, setUser] = React.useState({});

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                console.log(user)
                setUser(user);
                setUserType(
                    user.signInUserSession.accessToken.payload["cognito:groups"] ? user.signInUserSession.accessToken.payload["cognito:groups"][0] : ""
                );
            })
            .catch((err) => {
                console.log(err);
                if (err === "not authenticated") {
                    window.location.href = "/auth/login";
                }
            });
    }, []);

    const [alert, setAlert] = useState()

    const handleCopy = (message) => {
        navigator.clipboard.writeText(message).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        })
    }

    const handleDownload = (item) => {
        let filename = item.templateName + ' ' + item.templateVersion + ".json";
        let contentType = "application/json;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(item.templateValue)))], { type: contentType });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            var a = document.createElement('a');
            a.download = filename;
            a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(item.templateValue));
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    const deleteAlert = (item, i) => {
        setAlert(
            <Alert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleDelete(item, i)}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.info
                }
                cancelBtnCssClass={
                    sweetAlertClasses.button + " " + sweetAlertClasses.info
                }
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover this template!
            </Alert>
        );
    }

    const hideAlert = () => {
        setAlert()
    }

    const handleDelete = (item, i) => {
        showNotification("Template deleting.", "info");
        TemplateAPI.deleteTemplate(item).then(function (result) {
            if (result) {
                let arr = [...templates]
                arr = arr.filter(i => i.SK !== item.SK)
                console.log(arr)
                setTemplates(arr)
                setAlert()
                showNotification("Template deleted.", "quSandBoxColor");
            } else {
                showNotification("Template deleted failed.", "danger");
            }
        }, function (err) {
            showNotification("Template deleted failed.", "danger");
        })
    }

    const handleEdit = (item, i) => {
        setEditTemplate(item)
        setEditIndex(i)
        // setTemplateType(item.templateType)
        setTemplateNameState()
        setTemplateJsonState()
        setEditModal('edit')
    }

    const handleEditTemplate = () => {
        showNotification("Template editing.", "info");
        TemplateAPI.updateTemplate(editTemplate).then(function (result) {
            if (result) {

                let newTemplates = [...templates]
                newTemplates[editIndex] = editTemplate;
                setTemplates(newTemplates)
                setEditModal('close')
                showNotification("Template edited.", "quSandBoxColor");
            } else {
                showNotification("Template edited failed.", "danger");
            }
        }, function (err) {
            showNotification("Template edited failed.", "danger");
        })
    }

    const handleCreate = () => {
        setEditTemplate({})
        setTemplateNameState()
        setTemplateJsonState()
        setEditModal('create')
    }

    const handleCreateTemplate = () => {
        showNotification("Template creating.", "info");
        TemplateAPI.createTemplate(editTemplate).then(function (result) {
            if (result) {
                setTemplates([...templates, result])
                setEditModal('close')
                showNotification("Template created.", "quSandBoxColor");
            } else {

                showNotification("Template created failed.", "danger");
            }
        }, function (err) {
            showNotification("Template created failed.", "danger");
        })
    }

    const handleView = (item) => {
        setViewTemplate(item)
        setViewModal('view')
    }

    const handleViewSample = (item) => {
        setViewTemplate(item)
        getHTML(item.templateSample)
        setViewModal('sample')
    }

    const [HTML, setHTML] = React.useState();
    const [reportHeight, setReportHeight] = React.useState("200");

    const resizeReportIframe = (e) => {
        const frame = e.target;
        setReportHeight(
            frame.contentWindow.document.documentElement.scrollHeight
        );
    };

    const getHTML = (url) => {
        fetch(
            url,
            {
                method: "GET",
                mode: "cors",
                credentials: "omit",
                cache: "no-cache",
            }
        ).then((res) => {
            console.log(res)
            res.text().then((data) => {
                setHTML(data);
            });
        })
    };

    const downloadHTML = () => {
        var element = document.createElement("a");
        var file = new Blob([HTML], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = viewTemplate.templateName + ".html";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const actionHelper = (item, i) => {
        return (
            <div key={i}>
                {item.templateSample && !props.viewOnly &&
                    <Tooltip
                        id="tooltip-top"
                        title="View the template"
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                    >
                        <Button
                            color="transparent"
                            size="sm"
                            onClick={() => handleViewSample(item)}
                        >
                            <AssessmentIcon />
                        </Button>
                    </Tooltip>
                }
                {item.templateSample &&
                    <Tooltip
                        id="tooltip-top"
                        title="Download the template"
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <a style={{ color: "black" }} href={item.templateSample} target="_blank">
                            <Button
                                color="transparent"
                                size="sm"
                            >
                                <GetAppIcon />
                            </Button>
                        </a>
                    </Tooltip>
                }
                {!props.viewOnly &&
                    <Tooltip
                        id="tooltip-top"
                        title="View the template"
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                    >
                        <Button
                            color="transparent"
                            size="sm"
                            onClick={() => handleView(item)}
                        >
                            <VisibilityIcon />
                        </Button>
                    </Tooltip>
                }
                {/* {!props.viewOnly &&
                    <Tooltip
                        id="tooltip-top"
                        title="Download the template"
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                    >
                        <Button
                            color="transparent"
                            size="sm"
                            onClick={() => handleDownload(item)}
                        >
                            <GetAppIcon />
                        </Button>
                    </Tooltip>
                } */}
                {!props.viewOnly &&
                    <Tooltip
                        id="tooltip-top"
                        title='Copy the template'
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                    >
                        <Button
                            color="transparent"
                            size="sm"
                            onClick={() => handleCopy(item.templateValue)}
                        >
                            <FileCopyIcon />
                        </Button>
                    </Tooltip>
                }
                {!props.viewOnly && (userType == 'admin' || item.createdBy == user.username) &&
                    <Tooltip
                        id="tooltip-top"
                        title="Edit the template"
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                    >
                        <Button
                            color="transparent"
                            size="sm"
                            onClick={() => handleEdit(item, i)}
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>
                }
                {!props.viewOnly && (userType == 'admin' || item.createdBy == user.username) &&
                    <Tooltip
                        id="tooltip-top"
                        title="Delete the template"
                        placement="bottom"
                        classes={{ tooltip: dashboardClasses.tooltip }}
                    >
                        <Button
                            color="transparent"
                            size="sm"
                            onClick={() => deleteAlert(item, i)}
                        >
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                }
            </div>
        )
    }

    // const [templateType, setTemplateType] = useState("basic");
    // const [templatePermission, setTemplatePermission] = useState("private");

    useEffect(() => {
        TemplateAPI.getTemplates('access').then(function (result) {
            if (props.viewOnly) {
                result = result.filter((template) => {
                    return template.templateSample
                })
            }
            setTemplates(result);

        });
        // if (props.type !== 'all') {
        //     setTemplateType(props.type)
        // }
    }, [])

    // useEffect(() => {
    //     if (templateType) {
    //         TemplateAPI.getTemplates(templateType).then(function (result) {
    //             setTemplates(result);
    //         });
    //     }
    // }, [templateType]);

    const [notification, setNotification] = React.useState(false);
    const [operationStatus, setOperationStatus] = React.useState("success");
    const [notificationMessage, setNotificationMessage] = React.useState("");

    const showNotification = (message, status) => {
        setOperationStatus(status);
        setNotificationMessage(message);
        setNotification(true);
        setTimeout(function () {
            setNotification(false);
        }, 6000);
    };

    const fakeComplete = () => {
        setViewModal('close')
    }

    return (
        <>
            {alert}
            <Snackbar
                place="br"
                color={operationStatus}
                icon={AddAlert}
                message={notificationMessage}
                open={notification}
                closeNotification={() => setNotification(false)}
                close
            />
            <Dialog
                open={editModal !== 'close'}
                keepMounted
                onClose={() => setEditModal('close')}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle color="quSandBox" style={{ paddingBottom: "0px" }}>
                    <Button
                        justIcon
                        className={modalClasses.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setEditModal('close')}
                        style={{ marginTop: "0px" }}
                    >
                        <Close className={modalClasses.modalClose} />
                    </Button>
                    {editModal === "create" && (
                        <div>Create New Template</div>
                    )}
                    {editModal === "edit" && (
                        <div>Edit Existing Template: {editTemplate.SK ? editTemplate.SK.split('#')[1] : ''}</div>
                    )}
                </DialogTitle>

                <form>
                    <DialogContent
                        id="modal-slide-description"
                        className={modalClasses.modalBody}
                    >
                        <CustomInput
                            labelText="Template Name*"
                            id="Template Name"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            success={templateNameState === "success"}
                            error={templateNameState === "error"}
                            inputProps={{
                                type: "text",
                                onChange: (event) => {
                                    if (event.target.value.length > 0) {
                                        setTemplateNameState("success");
                                    } else {
                                        setTemplateNameState("error");
                                    }
                                    setEditTemplate({ ...editTemplate, templateName: event.target.value });
                                },
                                value: editTemplate.templateName ? editTemplate.templateName : '',
                            }}
                        />
                        <CustomInput
                            labelText="Template Version"
                            id="Template Version"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "text",
                                onChange: (event) => {
                                    setEditTemplate({ ...editTemplate, templateVersion: event.target.value });
                                },
                                value: editTemplate.templateVersion ? editTemplate.templateVersion : '',
                            }}
                        />
                        <CustomInput
                            labelText="Template Description"
                            id="Template Description"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "text",
                                onChange: (event) => {
                                    setEditTemplate({ ...editTemplate, templateDescription: event.target.value });
                                },
                                value: editTemplate.templateDescription ? editTemplate.templateDescription : '',
                            }}
                        />
                        <CustomInput
                            labelText="Template Reference"
                            id="Template Reference"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "text",
                                onChange: (event) => {
                                    setEditTemplate({ ...editTemplate, templateReference: event.target.value });
                                },
                                value: editTemplate.templateReference ? editTemplate.templateReference : '',
                            }}
                        />
                        <CustomInput
                            labelText="Template Sample"
                            id="Template Sample"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "text",
                                onChange: (event) => {
                                    setEditTemplate({ ...editTemplate, templateSample: event.target.value });
                                },
                                value: editTemplate.templateSample ? editTemplate.templateSample : '',
                            }}
                        />
                        {/* <CustomInput
                            labelText="Card Name"
                            id="Card Name"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "text",
                                onChange: (event) => {
                                    setEditTemplate({ ...editTemplate, stageName: event.target.value });
                                },
                                value: editTemplate.stageName ? editTemplate.stageName : '',
                            }}
                        /> */}
                        <FormControl
                            fullWidth
                            className={extendedFormsClasses.selectFormControl}
                        >
                            <InputLabel
                                htmlFor="simple-select"
                                className={extendedFormsClasses.selectLabel}
                            >
                                Choose Permission
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: extendedFormsClasses.selectMenu,
                                }}
                                classes={{
                                    select: extendedFormsClasses.select,
                                }}
                                onChange={(event) => {
                                    setEditTemplate({ ...editTemplate, templatePermission: event.target.value });
                                }}
                                inputProps={{
                                    name: "simpleSelect",
                                    id: "simple-select",
                                    value: editTemplate.templatePermission ? editTemplate.templatePermission : 'private',
                                }}
                            >
                                <MenuItem
                                    key='private'
                                    classes={{
                                        root: extendedFormsClasses.selectMenuItem,
                                        selected: extendedFormsClasses.selectMenuItemSelected,
                                    }}
                                    value='private'
                                >
                                    Private
                                </MenuItem>
                                <MenuItem
                                    key='public'
                                    classes={{
                                        root: extendedFormsClasses.selectMenuItem,
                                        selected: extendedFormsClasses.selectMenuItemSelected,
                                    }}
                                    value='public'
                                >
                                    Public
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {
                            props.type === 'all' && <FormControl
                                fullWidth
                                className={extendedFormsClasses.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="simple-select"
                                    className={extendedFormsClasses.selectLabel}
                                >
                                    Choose Type
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: extendedFormsClasses.selectMenu,
                                    }}
                                    classes={{
                                        select: extendedFormsClasses.select,
                                    }}
                                    onChange={(event) => {
                                        setEditTemplate({ ...editTemplate, templateType: event.target.value });
                                    }}
                                    inputProps={{
                                        name: "simpleSelect",
                                        id: "simple-select",
                                        value: editTemplate.templateType ? editTemplate.templateType : 'basic',
                                    }}
                                >
                                    <MenuItem
                                        key='basic'
                                        classes={{
                                            root: extendedFormsClasses.selectMenuItem,
                                            selected: extendedFormsClasses.selectMenuItemSelected,
                                        }}
                                        value='basic'
                                    >
                                        Basic
                                    </MenuItem>
                                    <MenuItem
                                        key='score'
                                        classes={{
                                            root: extendedFormsClasses.selectMenuItem,
                                            selected: extendedFormsClasses.selectMenuItemSelected,
                                        }}
                                        value='score'
                                    >
                                        Score
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        }
                        <FormControl
                            fullWidth
                            className={extendedFormsClasses.selectFormControl}
                        >
                            <InputLabel
                                htmlFor="simple-select"
                                className={extendedFormsClasses.selectLabel}
                            >
                                Choose nested level
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: extendedFormsClasses.selectMenu,
                                }}
                                classes={{
                                    select: extendedFormsClasses.select,
                                }}
                                onChange={(event) => {
                                    setEditTemplate({ ...editTemplate, nestedLevel: event.target.value });
                                }}
                                inputProps={{
                                    name: "simpleSelect",
                                    id: "simple-select",
                                    value: editTemplate.nestedLevel ? editTemplate.nestedLevel : 1,
                                }}
                            >
                                <MenuItem
                                    key='0'
                                    classes={{
                                        root: extendedFormsClasses.selectMenuItem,
                                        selected: extendedFormsClasses.selectMenuItemSelected,
                                    }}
                                    value='0'
                                >
                                    0
                                </MenuItem>
                                <MenuItem
                                    key='1'
                                    classes={{
                                        root: extendedFormsClasses.selectMenuItem,
                                        selected: extendedFormsClasses.selectMenuItemSelected,
                                    }}
                                    value='1'
                                >
                                    1
                                </MenuItem>
                                <MenuItem
                                    key='2'
                                    classes={{
                                        root: extendedFormsClasses.selectMenuItem,
                                        selected: extendedFormsClasses.selectMenuItemSelected,
                                    }}
                                    value='2'
                                >
                                    2
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl
                            fullWidth
                            className={extendedFormsClasses.selectFormControl}
                        >
                            <InputLabel
                                htmlFor="simple-select"
                                className={extendedFormsClasses.selectLabel}
                            >
                                Choose layout
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: extendedFormsClasses.selectMenu,
                                }}
                                classes={{
                                    select: extendedFormsClasses.select,
                                }}
                                onChange={(event) => {
                                    setEditTemplate({ ...editTemplate, templateLayout: event.target.value });
                                }}
                                inputProps={{
                                    name: "simpleSelect",
                                    id: "simple-select",
                                    value: editTemplate.templateLayout ? editTemplate.templateLayout : 'default',
                                }}
                            >
                                <MenuItem
                                    key='default'
                                    classes={{
                                        root: extendedFormsClasses.selectMenuItem,
                                        selected: extendedFormsClasses.selectMenuItemSelected,
                                    }}
                                    value='default'
                                >
                                    default
                                </MenuItem>
                                <MenuItem
                                    key='spider'
                                    classes={{
                                        root: extendedFormsClasses.selectMenuItem,
                                        selected: extendedFormsClasses.selectMenuItemSelected,
                                    }}
                                    value='spider'
                                >
                                    spider
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {editTemplate.templateType === 'score' &&
                            <FormControl
                                fullWidth
                                className={extendedFormsClasses.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="simple-select"
                                    className={extendedFormsClasses.selectLabel}
                                >
                                    Choose calculate method
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: extendedFormsClasses.selectMenu,
                                    }}
                                    classes={{
                                        select: extendedFormsClasses.select,
                                    }}
                                    onChange={(event) => {
                                        setEditTemplate({ ...editTemplate, calculateMethod: event.target.value });
                                    }}
                                    inputProps={{
                                        name: "simpleSelect",
                                        id: "simple-select",
                                        value: editTemplate.calculateMethod ? editTemplate.calculateMethod : 'average',
                                    }}
                                >
                                    <MenuItem
                                        key='average'
                                        classes={{
                                            root: extendedFormsClasses.selectMenuItem,
                                            selected: extendedFormsClasses.selectMenuItemSelected,
                                        }}
                                        value='average'
                                    >
                                        average
                                    </MenuItem>
                                    <MenuItem
                                        key='weighted'
                                        classes={{
                                            root: extendedFormsClasses.selectMenuItem,
                                            selected: extendedFormsClasses.selectMenuItemSelected,
                                        }}
                                        value='weighted'
                                    >
                                        weighted
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        }
                        {editTemplate.templateType === 'score' &&
                            <FormControl
                                fullWidth
                                className={extendedFormsClasses.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="simple-select"
                                    className={extendedFormsClasses.selectLabel}
                                >
                                    Choose analyze method
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: extendedFormsClasses.selectMenu,
                                    }}
                                    classes={{
                                        select: extendedFormsClasses.select,
                                    }}
                                    onChange={(event) => {
                                        setEditTemplate({ ...editTemplate, analyzeMethod: event.target.value });
                                    }}
                                    inputProps={{
                                        name: "simpleSelect",
                                        id: "simple-select",
                                        value: editTemplate.analyzeMethod ? editTemplate.analyzeMethod : 'bar',
                                    }}
                                >
                                    <MenuItem
                                        key='bar'
                                        classes={{
                                            root: extendedFormsClasses.selectMenuItem,
                                            selected: extendedFormsClasses.selectMenuItemSelected,
                                        }}
                                        value='bar'
                                    >
                                        bar
                                    </MenuItem>
                                    <MenuItem
                                        key='none'
                                        classes={{
                                            root: extendedFormsClasses.selectMenuItem,
                                            selected: extendedFormsClasses.selectMenuItemSelected,
                                        }}
                                        value='none'
                                    >
                                        none
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        }
                        <CustomInput
                            labelText="Template Value(JSON)"
                            id="templateValue"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            success={templateJsonState === "success"}
                            error={templateJsonState === "error"}
                            inputProps={{
                                multiline: true,
                                rows: 5,
                                onChange: (event) => {
                                    try {
                                        JSON.parse(event.target.value);
                                        setEditError("");
                                        setTemplateJsonState("success");
                                    } catch {
                                        setTemplateJsonState("error");
                                        setEditError("Invalid JSON format");
                                    }
                                    setEditTemplate({ ...editTemplate, templateValue: event.target.value });
                                },
                                value: editTemplate.templateValue ? editTemplate.templateValue : '',
                            }}
                        />
                        <CustomInput
                            labelText="Embeded HTML"
                            id="templateValue"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                multiline: true,
                                rows: 3,
                                onChange: (event) => {
                                    setEditTemplate({ ...editTemplate, templateHTML: event.target.value });
                                },
                                value: editTemplate.templateHTML ? editTemplate.templateHTML : '',
                            }}
                        />
                        {editError && <code>{editError}</code>}
                    </DialogContent>
                    <DialogActions
                        className={
                            modalClasses.modalFooter + " " + modalClasses.modalFooterCenter
                        }
                    >
                        {editModal === "create" && (
                            <Button onClick={handleCreateTemplate} color="quSandBoxColor">
                                Create
                            </Button>
                        )}
                        {editModal === "edit" && (
                            <Button onClick={handleEditTemplate} color="quSandBoxColor">
                                Update
                            </Button>
                        )}
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={editorModal !== 'close'}
                maxWidth="xl"
                fullWidth
                scroll='body'
                keepMounted
                onClose={() => setEditorModal('close')}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <CardHeader color="quSandBox" icon>
                    <Button
                        justIcon
                        className={modalClasses.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setEditorModal('close')}
                        style={{ marginTop: "0px" }}
                    >
                        <Close className={modalClasses.modalClose} />
                    </Button>
                    <h4 className={classes.cardIconTitle}>Template editor</h4>
                </CardHeader>
                <DialogContent
                    id="modal-slide-description"
                    className={modalClasses.modalBody}
                >
                    <iframe
                        id="data"
                        src={'https://template.quantuniversity.com/'}
                        width="100%"
                        frameBorder="0"
                        height={`1000px`}
                        scrolling="no"
                    ></iframe>
                </DialogContent>
            </Dialog>

            <Dialog
                open={viewModal !== 'close'}
                maxWidth="xl"
                fullWidth
                scroll='body'
                keepMounted
                onClose={() => setViewModal('close')}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <CardHeader color="quSandBox" icon>
                    <Button
                        justIcon
                        className={modalClasses.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setViewModal('close')}
                        style={{ marginTop: "0px" }}
                    >
                        <Close className={modalClasses.modalClose} />
                    </Button>
                    <h4 className={classes.cardIconTitle}>View Template {viewTemplate ? viewTemplate.templateName : ""}({viewTemplate.SK ? viewTemplate.SK.split('#')[1] : ""} )</h4>
                </CardHeader>
                <DialogContent
                    id="modal-slide-description"
                    className={modalClasses.modalBody}
                >
                    {viewModal == "view" && viewTemplate.templateReference &&
                        <p style={{
                            margin: "auto",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            flexDirection: "column"
                        }}>Reference: {viewTemplate.templateReference}</p>}
                    {viewModal == "view" && <Survey
                        json={viewTemplate.templateValue ? viewTemplate.templateValue : {}}
                        showCompletedPage={false}
                        doComplete={fakeComplete}
                    />
                    }
                    {viewModal == "sample" &&
                        <GridContainer justify="center">
                            <iframe
                                id="data"
                                srcDoc={HTML}
                                width="800px"
                                frameBorder="0"
                                height={`${reportHeight}px`}
                                scrolling="no"
                                onLoad={resizeReportIframe}
                            ></iframe>
                        </GridContainer>
                    }
                </DialogContent>
            </Dialog>
            {!props.viewOnly &&
                <Heading
                    textAlign="left"
                    category={<span>Step 1: Download a sample template or create new</span>}
                />
            }

            {!props.viewOnly && <Card>
                <CardBody>
                    <Table
                        tableHead={["#", "Name", "Permission", "Type", "Version", "Description", "Last Updated", "Actions"]}
                        tableData={templates.map((item, id) => [
                            id + 1,
                            item.templateName,
                            item.templatePermission,
                            item.templateType,
                            item.templateVersion,
                            item.templateDescription,
                            item.UpdatedAt ? new Date(item.UpdatedAt * 1000).toLocaleString() : null,
                            actionHelper(item, id),
                        ])}
                        customCellClasses={[classes.center]}
                        customClassesForCells={[7]}
                        customHeadCellClasses={[classes.center]}
                        customHeadClassesForCells={[7]}
                    />
                    {/* {props.viewOnly &&
                        <Table
                            tableHead={["#", "Name", "Permission", "Version", "Description", "Last Updated", "Actions"]}
                            tableData={templates.map((item, id) => [
                                id + 1,
                                item.templateName,
                                item.templatePermission,
                                item.templateVersion,
                                item.templateDescription,
                                item.UpdatedAt ? new Date(item.UpdatedAt * 1000).toLocaleString() : null,
                                actionHelper(item, id),
                            ])}
                            customCellClasses={[classes.center]}
                            customClassesForCells={[6]}
                            customHeadCellClasses={[classes.center]}
                            customHeadClassesForCells={[6]}
                        />
                    } */}
                </CardBody>
            </Card >

            }
            {props.viewOnly &&
                <GridContainer>
                    {templates.map((item, id) => (
                        <GridItem key={id} xs={12} sm={6} md={6} lg={4}>
                            <Link
                                onClick={() => handleViewSample(item)}>
                                <Card style={{ minHeight: "150px" }}>
                                    <CardHeader stats icon>
                                        <CardIcon color="quSandBox">
                                            {item.templateName}
                                        </CardIcon>

                                        <p className={dashboardClasses.cardCategory}>
                                            Version: {item.templateVersion}
                                        </p>
                                    </CardHeader>
                                    <CardBody
                                        style={{ paddingBottom: "0px" }}
                                    ><h5>{item.templateDescription}</h5></CardBody>
                                    <CardFooter style={{ marginLeft: "auto", marginRight: "0" }}>
                                        {actionHelper(item, id)}
                                    </CardFooter>
                                </Card>
                            </Link>
                        </GridItem>
                    ))}
                </GridContainer>
            }
            {!props.viewOnly &&
                <Heading
                    textAlign="left"
                    category={<span>Step 2: Edit the template in the <a onClick={() => setEditorModal('open')}>template generator</a></span>}
                />
            }
            {!props.viewOnly &&
                <Heading
                    textAlign="left"
                    category={<span>Step 3: <a onClick={() => handleCreate()}>Create</a> new template</span>}
                />
            }
        </>
    )
}