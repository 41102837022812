import React, { useEffect } from "react";
import ReactEmbedGist from "react-embed-gist";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import AdminLinks from "views/Components/AdminLinks.js";
import { useHistory } from "react-router-dom";

// Amplify components
import { Auth } from "aws-amplify";

import { withRouter } from "react-router";

import SweetAlert from "react-bootstrap-sweetalert";

import { CircularProgress } from "@material-ui/core";

import TemplateEditor from "views/Components/TemplateEditor.js"

const thirdParty = ["FactSet", "Moody's"];

function APIDetails(props) {
  const history = useHistory();
  const [valid, setValid] = React.useState("");
  const [iframe, setIframe] = React.useState("");
  const [streamlit, setStreamlit] = React.useState("");
  const [gist, setGist] = React.useState("");
  const [source, setSource] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [head, setHead] = React.useState("");
  const [colab, setColab] = React.useState("");
  const [userType, setUserType] = React.useState("");
  const [user, setUser] = React.useState("");
  const [apiData, setApiData] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [wrong, setWrong] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [iframeHeight, setIframeHeight] = React.useState();

  const [limit, setLimit] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setUserType(
          user.signInUserSession.accessToken.payload["cognito:groups"] ? user.signInUserSession.accessToken.payload["cognito:groups"][0] : ""
        );
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  useEffect(() => {
    var name = props.match.params.name;
    var version = props.match.params.version;
    if (props.match.path.includes("Audit-Checklist")) {
      name = "QuAudit";
      version = "1.0.0";
    }
    if (user) {
      let token = user.signInUserSession.accessToken.jwtToken;
      fetch(
        process.env.REACT_APP_BACK_END_URL +
        "/apiservice/?APIName=" +
        encodeURI(name) +
        "&APIVersion=" +
        version,
        {
          method: "GET",
          mode: "cors",
          credentials: "omit",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          res.json().then((data) => {
            console.log(data.Items);
            if (data.Items.length < 1) {
              console.log("No permission");
              history.push("/admin/error-page");
            } else {
              setApiData(data.Items[0]);
              setIframe(data.Items[0].iframeLink);
              setStreamlit(data.Items[0].streamlitLink);
              (data.Items[0].gistLink && data.Items[0].gistLink.includes("gist.github.com/")) ? setGist(data.Items[0].gistLink.split("gist.github.com/")[1]) : setGist(data.Items[0].gistLink);
              setSource(data.Items[0].sourceLink);
              setDescription(data.Items[0].description);
              setHead(data.Items[0].APIName);
              setColab(data.Items[0].colab);
              setIframeHeight(data.Items[0].iframeHeight || 500);
              if (["NVIDIA", "MathWorks"].includes(data.Items[0].APIType)) {
                // this.setState({ valid: data.Items[0].APIType })
                setValid(data.Items[0].APIType);
                setLimit(true);
              }
              if (["Limit"].includes(data.Items[0].APIType)) {
                setLimit(true);
              }
              console.log(data.Items[0]);
              setLoading(false);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const inputAlert = () => {
    // this.setState({ showAlert: true });
    setShowAlert(true);
  };
  const hideAlert = () => {
    // this.setState({ showAlert: false });
    setShowAlert(false);
  };
  const hideError = () => {
    setWrong(false);
    // this.setState({ wrong: false });
  };
  const checkKey = (e) => {
    fetch(process.env.REACT_APP_BACK_END_URL + "/authservice", {
      method: "POST",
      mode: "cors",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        keyType: valid,
        APIKey: e,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.valid) {
          // this.setState({ showAlert: false });
          setShowAlert(false);
          window.open(colab, "_blank");
        } else {
          throw new Error("invalid key");
        }
      })
      .catch((error) => {
        // this.setState({ wrong: true });
        // this.setState({ showAlert: false });
        setWrong(true);
        setShowAlert(false);
      });
  };

  const resizeIframe = (e) => {
    const frame = e.target;
    try {
      setIframeHeight(frame.contentWindow.document.documentElement.scrollHeight);
    } catch (err) {
      console.log(err)
    }
  }
  // render() {
  return (
    <div>
      {wrong && (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="API key invalid. Please input correct key!"
          onConfirm={() => hideError()}
          onCancel={() => hideError()}
        />
      )}
      {showAlert && (
        <SweetAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Input API key"
          onConfirm={(e) => {
            checkKey(e);
          }}
          onCancel={hideAlert}
        />
      )}

      <GridContainer justify="center">
        <GridItem xs={8} sm={8} md={8}></GridItem>
        <GridItem xs={4} sm={4} md={4}>
          {userType === "admin" && <AdminLinks apiData={apiData} />}
        </GridItem>
      </GridContainer>

      {loading ? (
        <Heading title={<CircularProgress />} textAlign="center" />
      ) : iframe || streamlit || colab || gist || head === "QU Template Catalog" || head === "Score Card Generator" ? (
        <div>
          <Heading
            title={head}
            textAlign="center"
            category={<span>{description}</span>}
          />

          <GridContainer justify="center">
            {iframe && thirdParty.includes(head) && (
              <Button href={iframe} target="_blank" color="info" size="lg">
                Run on {head}
              </Button>
            )}
            {iframe && !thirdParty.includes(head) && (
              <GridItem xs={12} sm={12} md={12}>
                <iframe
                  title="iframe"
                  src={iframe}
                  width="100%"
                  frameBorder="0"
                  height={`${iframeHeight}px`}
                  onLoad={resizeIframe}
                ></iframe>
              </GridItem>
            )}
            {streamlit && (
              <Button href={streamlit} target="_blank" color="info" size="lg">
                Run
              </Button>
            )}
            {source && (
              <Button href={source} target="_blank" color="info" size="lg">
                Project Source
              </Button>
            )}
            {!valid && colab && (
              <Button href={colab} target="_blank" color="info" size="lg">
                Run on QuSandBox
              </Button>
            )}
            {valid && colab && (
              <Button
                onClick={inputAlert}
                target="_blank"
                color="info"
                size="lg"
              >
                Run on QuSandBox
              </Button>
            )}
            {limit && (
              <Button href="https://www.quantuniversity.com/contact.html" target="_blank" color="info" size="lg">
                Request the Key
              </Button>
            )}
            {gist && (
              <GridItem xs={12} sm={12} md={12}>
                <ReactEmbedGist gist={gist} />
              </GridItem>
            )}
          </GridContainer>
        </div>
      ) : (
        <Heading
          title="*Coming Soon*"
          textAlign="center"
          category={<span>{description}</span>}
        />
      )}
      {
        head === "QU Template Catalog" && <TemplateEditor type='all' userType={userType}></TemplateEditor>
      }
      {
        head === "Score Card Generator" && <TemplateEditor type='score' userType={userType}></TemplateEditor>
      }
    </div>
  );
  // }
}

export default withRouter(APIDetails);
