import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Heading from "components/Heading/Heading.js";
import {
    Card,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";
import TemplateEditor from "views/Components/TemplateEditor.js"

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function TemplateStage(props) {
    const classes = useStyles();

    const {
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
        projectID: projectID,
        user: user,
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
        {
            tabButton: "Templates",
            tabContent: (
                // <Card className={classes.card}>
                //     <CardHeader icon>
                //         <CardIcon color="quSandBox">
                //             <IconDict.Templates />
                //         </CardIcon>
                //         <h4 className={classes.cardIconTitle}>Templates</h4>
                //     </CardHeader>
                //     <CardBody>
                //         <GridContainer justify="center">
                //             <TemplateEditor type='all' userType={user.signInUserSession.accessToken.payload["cognito:groups"] ? user.signInUserSession.accessToken.payload["cognito:groups"][0] : ""} viewOnly={true}></TemplateEditor>
                //         </GridContainer>
                //     </CardBody>
                // </Card>
                <TemplateEditor type='all' userType={user.signInUserSession.accessToken.payload["cognito:groups"] ? user.signInUserSession.accessToken.payload["cognito:groups"][0] : ""} viewOnly={true}></TemplateEditor>

            ),
            tabIcon: IconDict['Templates']
        }
    ]
    // if (props.config.reports) {
    //     tmpTabs.push(
    //         {
    //             tabButton: "Reports",
    //             tabIcon: IconDict['Reports'],
    //             tabContent: (<GeneralReportTemplate
    //                 templateId={
    //                     props.config.template
    //                         ? props.config.template
    //                         : ""
    //                 }
    //                 projectId={projectID}
    //                 stageId={stages.hasOwnProperty('Findings') ? stages['Findings'].PK : ""}
    //                 notes={allNotes.hasOwnProperty("Findings")
    //                     ? allNotes.Findings
    //                     : []}
    //                 stageType="Findings"
    //             />)
    //         }
    //     )
    // }
    // if (props.config.notes) {
    //     tmpTabs.push(
    //         {
    //             tabButton: "Notes",
    //             tabIcon: IconDict['Notes'],
    //             tabContent: (<Notes
    //                 notes={allNotes.hasOwnProperty("Findings")
    //                     ? allNotes.Findings
    //                     : []}
    //                 setNotes={setNotesHelper}
    //                 stageType="Findings"
    //             />),
    //         }
    //     )
    // }
    // if (props.config.issues) {
    //     tmpTabs.push(
    //         {
    //             tabButton: "Issues",
    //             tabIcon: IconDict['Issues'],
    //             tabContent: (
    //                 <Issues
    //                     issues={allIssues.hasOwnProperty("Findings")
    //                         ? allIssues.Findings
    //                         : []}
    //                     setIssues={setIssuesHelper}
    //                     stageType="Findings"
    //                 />
    //             ),
    //         })
    // }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}