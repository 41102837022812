import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import Table from "components/Table/Table.js";
import Heading from "components/Heading/Heading";
import CustomInput from "components/CustomInput/CustomInput.js";
import Alert from "views/Components/Alert.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import {
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    FormControlLabel,
    TextField,
    Checkbox,
    FormLabel
} from "@material-ui/core";


import VisibilityIcon from "@material-ui/icons/Visibility";
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import Check from "@material-ui/icons/Check";
import { Close, AddAlert } from "@material-ui/icons";

import { Survey } from "survey-react";
import TagsInput from "react-tagsinput";
import TemplateAPI from "views/APIs/TemplateAPI.js"

import styles from "assets/jss/material-dashboard-pro-react/components/reportStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);
const usedashboardStyle = makeStyles(dashboardStyle);
const useModalStyles = makeStyles(modalStyle);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);
const useextendedFormsStyles = makeStyles(extendedFormsStyle);
const useFormStyles = makeStyles(regularFormsStyle);

// function DropDown(props) {
//     const classes = useStyles();
//     const [select, setSelect] = React.useState(0);

//     const handleItemAdd = () => {
//         props.setItems([...props.selected, select], props.type);
//         setSelect(0);
//     };

//     const handleTagRemoval = (regularTags) => {
//         props.setItems(regularTags, props.type);
//     };

//     const tagToItem = (tag) => {
//         let item = props.items.find(item => item.SK === tag)
//         return item.templateName + '-V-' + item.templateVersion
//     }

//     return (
//         <GridContainer>
//             <GridItem xs={12} sm={6} md={8}>
//                 <FormControl fullWidth className={classes.selectFormControl}>
//                     <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
//                         Choose support {props.type.toUpperCase()} Templates
//                     </InputLabel>
//                     <Select
//                         MenuProps={{
//                             className: classes.selectMenu,
//                         }}
//                         classes={{
//                             select: classes.select,
//                         }}
//                         onChange={(e) => {
//                             setSelect(e.target.value);
//                         }}
//                         value={select}
//                         inputProps={{
//                             name: "noteSelect",
//                             id: "note-select",
//                         }}
//                     >
//                         <MenuItem
//                             disabled
//                             classes={{
//                                 root: classes.selectMenuItem,
//                             }}
//                             value={0}
//                         >
//                             Choose {props.type.toUpperCase()} Template
//                         </MenuItem>
//                         {props.items.map((item, index) => (
//                             !props.selected.includes(item.SK) &&
//                             <MenuItem
//                                 key={index}
//                                 classes={{
//                                     root: classes.selectMenuItem,
//                                     selected: classes.selectMenuItemSelected,
//                                 }}
//                                 value={item.SK}
//                             >
//                                 {item.templateName}-V-{item.templateVersion}
//                             </MenuItem>
//                         ))}
//                     </Select>
//                 </FormControl>
//             </GridItem>

//             <GridItem xs={12} sm={4} md={2}>
//                 <Button color="info" onClick={handleItemAdd} disabled={select === 0}>
//                     Add Item
//                 </Button>
//             </GridItem>
//             <GridItem xs={12}>
//                 <TagsInput
//                     value={props.selected}
//                     onChange={handleTagRemoval}
//                     onlyUnique={true}
//                     tagProps={{ className: "react-tagsinput-tag info" }}
//                     renderInput={(props) => {
//                         return <></>;
//                     }}
//                     renderTag={
//                         (props) => {
//                             let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
//                             return (
//                                 <span key={key} {...other}>
//                                     {tagToItem(tag)}
//                                     {!disabled &&
//                                         <a className={classNameRemove} onClick={(e) => onRemove(key)} />
//                                     }
//                                 </span>
//                             )
//                         }
//                     }
//                 />
//             </GridItem>
//         </GridContainer>
//     );
// }

function NewReports(props) {
    const classes = useStyles();
    const [select, setSelect] = React.useState(0);
    const [name, setName] = React.useState('');
    return (
        <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                    value={name}
                    fullWidth
                    required
                    style={{ paddingTop: "6px" }}
                />
            </GridItem>
            <GridItem xs={12} sm={5} md={5}>
                <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                        Choose Templates
                    </InputLabel>
                    <Select
                        MenuProps={{
                            className: classes.selectMenu,
                        }}
                        classes={{
                            select: classes.select,
                        }}
                        onChange={(e) => {
                            setSelect(e.target.value);
                        }}
                        value={select}
                        inputProps={{
                            name: "noteSelect",
                            id: "note-select",
                        }}
                    >
                        <MenuItem
                            disabled
                            classes={{
                                root: classes.selectMenuItem,
                            }}
                            value={0}
                        >
                            Choose Template
                        </MenuItem>
                        {props.items.map((item, index) => (
                            !props.selected.includes(item.SK.split("TEMPLATE#")[1]) &&
                            <MenuItem
                                key={index}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                }}
                                value={item.SK}
                            >
                                {item.templateName}-V-{item.templateVersion}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                <Button color="info" onClick={() => props.handleAddStage(name, select, props.groupIndex)} disabled={select === 0}>
                    Add Item
                </Button>
            </GridItem>
        </GridContainer>
    )

}

export default function TemplateEditor(props) {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const dashboardClasses = usedashboardStyle();
    const extendedFormsClasses = useextendedFormsStyles();
    const sweetAlertClasses = usesweetAlertStyle();
    const formClasses = useFormStyles();
    const [error, setError] = React.useState()
    const [stages, setStages] = React.useState([])


    const handleToggle = (groupIndex, stageIndex, type) => {
        let tmp = [...props.structure]
        tmp[groupIndex]['stages'][stageIndex][type] = !tmp[groupIndex]['stages'][stageIndex][type]
        props.setStructure(tmp)
    }

    const handleSelect = (event, groupIndex, stageIndex) => {
        let tmp = [...props.structure]
        tmp[groupIndex]['stages'][stageIndex]['template'] = event.target.value
        props.setStructure(tmp)
    }

    // const handleMultiSelect = (input, type, groupIndex) => {
    //     let tmp = [...props.structure]
    //     tmp[groupIndex][type] = input
    //     props.setStructure(tmp)
    // }
    const handleAddStage = (name, template, groupIndex) => {
        if (name && !stages.includes(name)) {
            template = template.split('#')[1]
            setStandAloneTemplates([template, ...standAloneTemplates])
            let tmp = [...props.structure]
            let stage = {
                "name": name,
                "type": "Report",
                "template": template,
                "visible":true
            }
            tmp[groupIndex]["stages"].push(stage)
            props.setStructure(tmp)
        } else {
            if (!name) {

                setError('Please Input the name of new report')
            } else {
                setError('The new report name should be unique')
            }
        }
    }

    const handleDeleteStage = (groupIndex, stageIndex) => {
        let tmp = [...props.structure]

        let array = [...stages]
        var index = array.indexOf(tmp[groupIndex]["stages"][stageIndex]["name"]);
        if (index !== -1) {
            array.splice(index, 1);
        }
        setStages(array)
        tmp[groupIndex]["stages"].splice(stageIndex, 1)
        props.setStructure(tmp)
    }

    // const [basicTemplates, setBasicTemplates] = React.useState([]);
    // const [scoreTemplates, setScoreTemplates] = React.useState([]);
    const [templates, setTemplates] = React.useState([]);
    const [standAloneTemplates, setStandAloneTemplates] = React.useState([]);

    const IDToName = (template) => {
        let item = templates.find(item => item.SK === "TEMPLATE#" + template)
        if (item) {

            return item.templateName + '-V-' + item.templateVersion
        } else {
            return "Not Found"
        }
    }

    useEffect(() => {
        if (props.projectId) {
            TemplateAPI.getTemplates('project', props.projectId).then(function (result) {
                setTemplates(result);
            });
        } else {
            TemplateAPI.getTemplates('access').then(function (result) {
                setTemplates(result);
            });
        }
    }, []);

    useEffect(() => {
        let tmp = []
        let tmpStages = []
        for (let index in props.structure) {
            let group = props.structure[index]
            if (group.name) {
                for (let i = group.stages.length - 1; i >= 0; i--) {
                    // for (let stage in group.stages) {
                    if (group.type === "Report" && group.stages[i].type === "Report" && group.stages[i].template) {
                        tmp.push("TEMPLATE#" + group.stages[i].template)
                    }
                    tmpStages.push(group.stages[i].name)
                }
            }
        }
        console.log(tmpStages)
        setStages(tmpStages)
        setStandAloneTemplates(tmp)
    }, []);

    return (
        <GridContainer>
            {
                props.structure.map((group, groupIndex) => (
                    <GridItem key={groupIndex} xs={12}>
                        <h5 style={{ "textDecoration": "underline" }}>{group.name}</h5>
                        {/* {
                            group.basic &&
                            <DropDown
                                selected={group.basic}
                                type='basic'
                                items={basicTemplates}
                                setItems={(input, type) => handleMultiSelect(input, type, groupIndex)}
                            >

                            </DropDown>
                        }
                        {
                            group.score &&
                            <DropDown
                                selected={group.score}
                                type='score'
                                items={scoreTemplates}
                                setItems={(input, type) => handleMultiSelect(input, type, groupIndex)}
                            ></DropDown>
                        } */}
                        {group.stages.map((stage, stageIndex) => {
                            return (
                                <>
                                    {stage.type !== "Report" && <GridContainer>
                                        <GridItem xs={12} sm={4}>
                                            <FormLabel
                                                className={
                                                    formClasses.labelHorizontal +
                                                    " " +
                                                    formClasses.labelHorizontalRadioCheckbox
                                                }
                                                style={{ "color": "gray", "textDecoration": "underline" }}
                                            >
                                                {stage.name}:
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={8} style={{ display: "flex" }}>
                                            <div className={formClasses.inlineChecks}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onClick={() => handleToggle(groupIndex, stageIndex, 'visible')}
                                                            checked={stage.visible ? true : false}
                                                            checkedIcon={
                                                                <Check className={formClasses.checkedIcon} />
                                                            }
                                                            icon={<Check className={formClasses.uncheckedIcon} />}
                                                            classes={{
                                                                checked: formClasses.checked,
                                                                root: formClasses.checkRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: formClasses.label,
                                                        root: formClasses.labelRoot
                                                    }}
                                                    label="Visible"
                                                />
                                            </div>
                                            {stage.type !== "Static" && <div className={formClasses.inlineChecks}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onClick={() => handleToggle(groupIndex, stageIndex, 'notes')}
                                                            checkedIcon={
                                                                <Check className={formClasses.checkedIcon} />
                                                            }
                                                            checked={stage.notes ? true : false}
                                                            icon={<Check className={formClasses.uncheckedIcon} />}
                                                            classes={{
                                                                checked: formClasses.checked,
                                                                root: formClasses.checkRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: formClasses.label,
                                                        root: formClasses.labelRoot
                                                    }}
                                                    label="Notes"
                                                />
                                            </div>
                                            }
                                            {stage.type !== "Static" &&
                                                <div className={formClasses.inlineChecks}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onClick={() => handleToggle(groupIndex, stageIndex, 'issues')}
                                                                checkedIcon={
                                                                    <Check className={formClasses.checkedIcon} />
                                                                }
                                                                checked={stage.issues ? true : false}
                                                                icon={<Check className={formClasses.uncheckedIcon} />}
                                                                classes={{
                                                                    checked: formClasses.checked,
                                                                    root: formClasses.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        classes={{
                                                            label: formClasses.label,
                                                            root: formClasses.labelRoot
                                                        }}
                                                        label="Issues"
                                                    />
                                                </div>
                                            }
                                            {stage.type !== "Static" &&
                                                <div className={formClasses.inlineChecks}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onClick={() => handleToggle(groupIndex, stageIndex, 'reports')}
                                                                checkedIcon={
                                                                    <Check className={formClasses.checkedIcon} />
                                                                }
                                                                checked={stage.reports ? true : false}
                                                                icon={<Check className={formClasses.uncheckedIcon} />}
                                                                classes={{
                                                                    checked: formClasses.checked,
                                                                    root: formClasses.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        classes={{
                                                            label: formClasses.label,
                                                            root: formClasses.labelRoot
                                                        }}
                                                        label="Reports"
                                                    />
                                                </div>
                                            }
                                        </GridItem>

                                        {stage.reports && <GridItem xs={12}>
                                            <FormControl
                                                fullWidth
                                            >
                                                <InputLabel
                                                    htmlFor="simple-select"
                                                    className={extendedFormsClasses.selectLabel}
                                                >
                                                    Choose Template
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        className: extendedFormsClasses.selectMenu
                                                    }}
                                                    classes={{
                                                        select: extendedFormsClasses.select
                                                    }}
                                                    value={stage.template}
                                                    onChange={(event) => handleSelect(event, groupIndex, stageIndex)}
                                                    inputProps={{
                                                        name: "simpleSelect",
                                                        id: "simple-select"
                                                    }}
                                                >
                                                    {templates.map((template, templateIndex) => (
                                                        <MenuItem
                                                            key={templateIndex}
                                                            classes={{
                                                                root: extendedFormsClasses.selectMenuItem
                                                            }}
                                                            value={template.SK.split('#')[1]}
                                                        >
                                                            {template.templateName + '-V-' + template.templateVersion}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>}

                                    </GridContainer>
                                    }
                                    {
                                        stage.type === "Report" && <GridContainer>
                                            <GridItem xs={12} sm={3} md={3}>
                                                <FormLabel
                                                    className={
                                                        formClasses.labelHorizontal +
                                                        " " +
                                                        formClasses.labelHorizontalRadioCheckbox
                                                    }
                                                    style={{ "color": "gray", "textDecoration": "underline" }}
                                                >
                                                    {stage.name}:
                                                </FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6}>
                                                <FormLabel
                                                    className={
                                                        formClasses.labelHorizontal +
                                                        " " +
                                                        formClasses.labelHorizontalRadioCheckbox
                                                    }
                                                    style={{ "color": "gray" }}
                                                >
                                                    Template:{IDToName(stage.template)}
                                                </FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={3} md={3}>
                                                <Button color="danger" onClick={() => handleDeleteStage(groupIndex, stageIndex)}>
                                                    Delete
                                                </Button>
                                            </GridItem>
                                        </GridContainer>
                                    }
                                </>
                            )
                        })}

                        {
                            group.type === "Report" && <>
                                <NewReports
                                    selected={standAloneTemplates}
                                    items={templates}
                                    handleAddStage={handleAddStage}
                                    groupIndex={groupIndex}
                                >
                                </NewReports>

                                {error && <code>{error}</code>}
                            </>
                        }
                    </GridItem>
                )
                )
            }
        </GridContainer>
    )
}