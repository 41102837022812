import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// Amplify components
import { Auth } from "aws-amplify";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function UpdatePasswordPage(props) {
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("")
  const [keyError, setKeyError] = React.useState("");
  const [keyErrorState, setKeyErrorState] = React.useState(false);
  const [keyMismatch, setKeyMismatch] = React.useState("");
  const [keyMismatchState, setKeyMismatchState] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [timer, setTimer] = React.useState(60);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const hideAlert = () => {
    setAlert(null);
  };

  const updatePassword = () => {
    // console.log("in update")
    if (validate()) {
      Auth.forgotPasswordSubmit(props.location.state.email, confirmationCode, confirmPassword)
        .then(data => {
          setModal(true);
        })
        .catch(err => {
            setErrorMessage(err.message);
            basicAlert();
        });
      }
  }

  const resetPassword = () => {
    Auth.forgotPassword(props.location.state.email)
    .then()
    .catch(err => {
      setErrorMessage(err.message);
      basicAlert();
    });
    resetTimer();
  }

  const validate = () => {
    const err = {};
    const requiredFields = [
     confirmationCode,
     confirmNewPassword,
     confirmPassword
    ];
    requiredFields.forEach(field => {
      if(!field) {
        err[field]="Required"
      }
    });
    setValidationErrors(err);
    if (Object.keys(err).length>0 || keyMismatchState || keyErrorState) {
      return false;
    } else {
      return true
    };
  }
 
  const resetTimer = () => {
    setTimer(60);
  }

  React.useEffect(() => {
    setTimeout(() => {
      setTimer((timer) => {
        if(timer===0) {return timer}
        return timer - 1 
      });
    }, 1000);
  });

  const testPassword = (e)=> {
    const pass = e.target.value
    if (!/[A-Z]+/g.test(pass)){
      setKeyErrorState(true);
      setKeyError("Password must have uppercase characters.")
    } else if (!/\d+/g.test(pass)){
      setKeyErrorState(true);
      setKeyError("Password must have numbers.")
    } else if (!/[!@#$%^&*(),.?":{}|<>]+/g.test(pass)){
      setKeyErrorState(true);
      setKeyError("Password must have special characters.")
    } else if (pass.length<8) {
      setKeyErrorState(true);
      setKeyError("Password must have length greater than or equal to 8.")
    } else if(!/[a-z]+/g.test(pass)){
      setKeyErrorState(true);
      setKeyError("Password must have lowercase characters.")
    } else if (keyErrorState!==false) {
      setKeyErrorState(false);
      setKeyError("")
    }
  }
  
  const basicAlert = async () => {
    let alertMessage;
    await setErrorMessage((errorMessage)=>{
      alertMessage=errorMessage;
      return errorMessage;
    }) 
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={alertMessage}
        onConfirm={() => hideAlert()}
        // onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
  };

  const classes = useStyles();
  if (!props.location.state) {
    console.log(props.location)
    return (<Redirect to="/auth/reset-password-page" />)
  }
  return (
    <div className={classes.container}>
      {alert}
      <GridContainer justify="center">
        <GridItem sm={12} md={8} lg={4}>
          <form>
            <Card login>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter} `}
                color="quSandBox"
              >
                <h4 className={classes.cardTitle}>Update Password</h4>
                
              </CardHeader>
              <CardBody>
              <h5>Please enter the confirmation code that has been sent to your registered email address</h5>
              <CustomInput
                  labelText="Confirmation Code"
                  id="confirmation-code"
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={!!validationErrors[confirmationCode] || false}
                  helperText={validationErrors[confirmationCode]}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ConfirmationNumberIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Confirm Password"
                  id="confirm-password"
                  onChange={(e) => {
                    testPassword(e)
                    setConfirmPassword(e.target.value)
                  }}
                  error={!!validationErrors[confirmPassword] || keyErrorState }
                  formControlProps={{
                    fullWidth: true
                  }}
                  helperText={validationErrors[confirmPassword] || keyError}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  labelText="Confirm New Password"
                  id="confirm-new-password"
                  error={!!validationErrors[confirmNewPassword] || keyMismatchState}
                  onChange={(e) => {
                    if(confirmPassword!== e.target.value) {
                      setKeyMismatch("Passwords don't match")
                      setKeyMismatchState(true);
                    }
                    else if (keyMismatchState!==false) {
                      setKeyMismatch("")
                      setKeyMismatchState(false)
                    }
                    setConfirmNewPassword(e.target.value)}}
                  helperText={validationErrors[confirmNewPassword] || keyMismatch}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Check className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                {/* <p>(Password requires at least 6 characters including all of numbers, special character, uppercase letter, lowercase letter.)</p> */}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button onClick={updatePassword} color="danger" simple size="sm" block>
                 Update Password
                </Button>
                <Button onClick={resetPassword} color="danger" simple size="sm" block disabled={timer!==0}>
                  Resend Code {timer!==0 && "wait "+timer+" sec"}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>

      <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall
          }}
          open={modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModal(false)}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={
              classes.modalBody + " " + classes.modalSmallBody
            }
          >
            <h3>Password updated successfully</h3>
          </DialogContent>
          <DialogActions
            className={
              classes.modalFooter + " " + classes.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                history.push("/auth/login-page");
              }}
              color="danger"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Go To Login
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
