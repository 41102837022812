import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { isChrome } from 'react-device-detect';
import {
    Card,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function DataStage(props) {
    const classes = useStyles();

    const [dataOverviewSelect, setDataOverviewSelect] = React.useState("");
    const [dataDiveSelect, setDataDiveSelect] = React.useState("");
    const [dataProfilingSelect, setDataProfilingSelect] = React.useState("");
    const [dataOverviewHTML, setDataOverviewHTML] = React.useState("");
    const [dataDiveHTML, setDataDiveHTML] = React.useState("");
    const [dataProfilingHTML, setDataProfilingHTML] = React.useState("");
    const [dataDiveHeight, setDataDiveHeight] = React.useState("800");
    const [dataProfilingHeight, setDataProfilingHeight] = React.useState("0");
    const [dataOverviewHeight, setDataOverviewHeight] = React.useState("1100");

    useEffect(() => {
        if (!!stageParam && !!reportid) {
            if (Object.keys(stages['Data']).length !== 0 && stageParam === "Data") {
                getHTML(reportid, setDataOverviewHTML, stages['Data']);
                setDataOverviewSelect(reportid);
            }
        }
    }, [stages]);

    const handleDataOverviewSimple = (event) => {
        getHTML(event.target.value, setDataOverviewHTML, stages['Data']);
        setDataOverviewSelect(event.target.value);
    };

    const handleDataProfilingSimple = (event) => {
        getHTML(event.target.value, setDataProfilingHTML, stages['Data']);
        setDataProfilingSelect(event.target.value);
    };
    const handleDataDiveSimple = (event) => {
        getHTML(event.target.value, setDataDiveHTML, stages['Data']);
        setDataDiveSelect(event.target.value);
    };

    const resizeDataProfilingIframe = (e) => {
        const frame = e.target;
        setDataProfilingHeight(
            frame.contentWindow.document.documentElement.scrollHeight
        );
    };

    const resizeDataOverviewIframe = (e) => {
        const frame = e.target;
        setDataOverviewHeight(
            frame.contentWindow.document.documentElement.scrollHeight
        );
    };
    const resizeDataDiveIframe = (e) => {
        const frame = e.target;
        setDataDiveHeight(
            frame.contentWindow.document.documentElement.scrollHeight
        );
    };

    const {
        projectID: projectID,
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
        stageParam: stageParam,
        reportid: reportid,
        getHTML: getHTML
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
        {
            tabButton: "Data",
            tabContent: (
                <Card className={classes.card}>
                    <CardHeader icon>
                        <CardIcon color="quSandBox">
                            <IconDict.Data />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Data</h4>
                    </CardHeader>
                    <CardBody>
                        {/* here */}
                        <NavPills
                            color="quSandBoxColor"
                            tabs={[
                                {
                                    tabButton: "Superset",
                                    tabContent: (
                                        <GridContainer justify="center">
                                            {isChrome && <iframe
                                                id="data"
                                                title="data"
                                                src={
                                                    !!stages.hasOwnProperty('Explore') && stages['Explore'].hasOwnProperty("Link")
                                                        ? stages['Explore'].Link
                                                        : "https://superset.quantuniversity.com/dashboard/list/?pageIndex=0&sortColumn=changed_on_delta_humanized&sortOrder=desc&viewMode=table"
                                                }
                                                width="100%"
                                                frameBorder="0"
                                                height={`${stages.hasOwnProperty('Explore') && stages['Explore'].hasOwnProperty("IframeHeight")
                                                    ? stages['Explore'].IframeHeight
                                                    : 600
                                                    }px`}
                                            ></iframe>
                                            }

                                            <Button
                                                color="info"
                                                size="lg"
                                                href={
                                                    !!stages.hasOwnProperty('Explore') && stages['Explore'].hasOwnProperty("Link")
                                                        ? stages['Explore'].Link
                                                        : "https://superset.quantuniversity.com/dashboard/list/?pageIndex=0&sortColumn=changed_on_delta_humanized&sortOrder=desc&viewMode=table"
                                                }
                                                target="_blank"
                                            >
                                                Explore Data
                                            </Button>
                                        </GridContainer>
                                    )
                                },
                                {
                                    tabButton: "Facets Overview",
                                    tabContent: (
                                        <span>
                                            <GridItem xs={12} sm={6} md={5} lg={5}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        Choose Report
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu,
                                                        }}
                                                        classes={{
                                                            select: classes.select,
                                                        }}
                                                        value={dataOverviewSelect}
                                                        onChange={handleDataOverviewSimple}
                                                        inputProps={{
                                                            name: "simpleSelect",
                                                            id: "simple-select",
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                            }}
                                                        >
                                                            Choose Report
                                                        </MenuItem>
                                                        {stages.hasOwnProperty('Data') && stages['Data'].hasOwnProperty("Reports") &&
                                                            stages['Data'].Reports.map(
                                                                (report, index) =>
                                                                    report.Type ===
                                                                    "Facets Overview" && (
                                                                        <MenuItem
                                                                            key={index}
                                                                            classes={{
                                                                                root:
                                                                                    classes.selectMenuItem,
                                                                                selected:
                                                                                    classes.selectMenuItemSelected,
                                                                            }}
                                                                            value={index}
                                                                        >
                                                                            {report.Name}
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <CardBody>
                                                <GridContainer>
                                                    <iframe
                                                        id="data"
                                                        title="data"
                                                        srcDoc={dataOverviewHTML}
                                                        width="100%"
                                                        frameBorder="0"
                                                        height={`${dataOverviewHeight}px`}
                                                        scrolling="no"
                                                        onLoad={resizeDataOverviewIframe}
                                                    ></iframe>
                                                </GridContainer>
                                            </CardBody>
                                        </span>
                                    ),
                                },
                                {
                                    tabButton: "Facets Dive",
                                    tabContent: (
                                        <span>
                                            <GridItem xs={12} sm={6} md={5} lg={5}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        Choose Report
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu,
                                                        }}
                                                        classes={{
                                                            select: classes.select,
                                                        }}
                                                        value={dataDiveSelect}
                                                        onChange={handleDataDiveSimple}
                                                        inputProps={{
                                                            name: "simpleSelect",
                                                            id: "simple-select",
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                            }}
                                                        >
                                                            Choose Report
                                                        </MenuItem>
                                                        {stages.hasOwnProperty('Data') && stages['Data'].hasOwnProperty("Reports") &&
                                                            stages['Data'].Reports.map(
                                                                (report, index) =>
                                                                    report.Type ===
                                                                    "Facets Dive" && (
                                                                        <MenuItem
                                                                            key={index}
                                                                            classes={{
                                                                                root:
                                                                                    classes.selectMenuItem,
                                                                                selected:
                                                                                    classes.selectMenuItemSelected,
                                                                            }}
                                                                            value={index}
                                                                        >
                                                                            {report.Name}
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <CardBody>
                                                <GridContainer>
                                                    <iframe
                                                        title="data"
                                                        id="data"
                                                        srcDoc={dataDiveHTML}
                                                        width="100%"
                                                        frameBorder="0"
                                                        height={`${dataDiveHeight}px`}
                                                        scrolling="no"
                                                        onLoad={resizeDataDiveIframe}
                                                    ></iframe>
                                                </GridContainer>
                                            </CardBody>
                                        </span>
                                    ),
                                },
                                {
                                    tabButton: "Pandas Profiling",
                                    tabContent: (
                                        <span>
                                            <GridItem xs={12} sm={6} md={5} lg={5}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        Choose Report
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu,
                                                        }}
                                                        classes={{
                                                            select: classes.select,
                                                        }}
                                                        value={dataProfilingSelect}
                                                        onChange={handleDataProfilingSimple}
                                                        inputProps={{
                                                            name: "simpleSelect",
                                                            id: "simple-select",
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                            }}
                                                        >
                                                            Choose Report
                                                        </MenuItem>
                                                        {stages.hasOwnProperty('Data') && stages['Data'].hasOwnProperty("Reports") &&
                                                            stages['Data'].Reports.map(
                                                                (report, index) =>
                                                                    report.Type ===
                                                                    "Pandas Profiling" && (
                                                                        <MenuItem
                                                                            key={index}
                                                                            classes={{
                                                                                root:
                                                                                    classes.selectMenuItem,
                                                                                selected:
                                                                                    classes.selectMenuItemSelected,
                                                                            }}
                                                                            value={index}
                                                                        >
                                                                            {report.Name}
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <CardBody>
                                                <GridContainer>
                                                    <iframe
                                                        id="data"
                                                        title="data"
                                                        srcDoc={dataProfilingHTML}
                                                        width="100%"
                                                        frameBorder="0"
                                                        height={`${dataProfilingHeight}px`}
                                                        scrolling="no"
                                                        onLoad={resizeDataProfilingIframe}
                                                    ></iframe>
                                                </GridContainer>
                                            </CardBody>
                                        </span>
                                    ),
                                },
                            ]}
                        />
                    </CardBody>
                </Card>
            ),
            tabIcon: IconDict['Data']
        }
    ]
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('Data') ? stages['Data'].PK : ""}
                    notes={allNotes.hasOwnProperty("Data")
                        ? allNotes.Data
                        : []}
                    stageType="Data"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("Data")
                        ? allNotes.Data
                        : []}
                    setNotes={setNotesHelper}
                    stageType="Data"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("Data")
                            ? allIssues.Data
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="Data"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}