import {
  cardTitle,
  grayColor,
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.js";

// import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const userProfileStyles = {
  ...customCheckboxRadioSwitch,
  // cardIconTitle: {
  //   ...cardTitle,
  //   marginTop: "15px",
  //   marginBottom: "0px"
  // },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px"
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative"
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important"
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important"
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardCategory: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center"
  },
  description: {
    color: grayColor[0]
  },
  updateProfileButton: {
    float: "right"
  },
  colorQuSandBox: {
    backgroundColor: "blue"
  },
  thumbnail: {
    maxWidth: '30%',
    maxHeight: '30%'
  },
  // ...buttonStyle
};
export default userProfileStyles;
