import React, { useEffect } from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/notesStyle.js";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import createPlotlyComponent from "react-plotly.js/factory";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { NotificationContext } from "views/Components/Context.js";
const useStyles = makeStyles(styles);
export default function ImageViewer(props) {
  const classes = useStyles();
  const Plotly = window.Plotly;
  const Plot = createPlotlyComponent(Plotly);

  const { showNotification } = React.useContext(NotificationContext);
  const [jsonValid, setJsonValid] = React.useState(false)

  useEffect(() => {
    if (props.image.ArtifactType === "plotly") {
      try {
        JSON.parse(props.image.Artifact)
        setJsonValid(true)
      } catch (error) {
        console.log(error)
        showNotification("Not a valid plotly json", "danger");
      }
    }
  },[])

  return (
    <GridContainer justify="center">
      <GridItem
        sm={12}
      // style={{ textAlign: "center" }}
      >
        {props.image.ArtifactType === "base64" && (
          <img src={props.image.Artifact} className={classes.image} />
        )}
        {props.image.ArtifactType === "plotly" && jsonValid && props.image.Artifact && (
          <Plot
            data={JSON.parse(props.image.Artifact).data}
            className={classes.imageFrame}
          />
        )}
        {props.image.ArtifactType === "embed" && (
          //  <iframe srcDoc=
          // // <>
          // {props.image.Artifact}
          // // </>
          // />
          <div
            dangerouslySetInnerHTML={{ __html: props.image.Artifact }}
            className={classes.imageFrame}
          ></div>
        )}
        {props.image.ArtifactType === "file" && (
          <img src={props.image.Artifact} className={classes.image} />
        )}
      </GridItem>
      {!!props.handleRemove && (
        <GridItem xs={3}>
          <Button
            {...props.removeButtonProps}
            onClick={() => props.handleRemove(props.image)}
          >
            <ClearIcon /> Remove
          </Button>
        </GridItem>
      )}
    </GridContainer>
  );
}
ImageViewer.propTypes = {
  // noteId: PropTypes.number,
  image: PropTypes.object,
  removeButtonProps: PropTypes.object,
  handleRemove: PropTypes.func,
};
