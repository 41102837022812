import styles from "assets/jss/material-dashboard-pro-react/components/notesStyle.js";
import React, { useEffect, useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { makeStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// Amplify components
import { Auth } from "aws-amplify";
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Table from "components/Table/Table.js";
import DeleteIcon from "@material-ui/icons/Delete";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useRouteMatch } from "react-router-dom";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { Assignment } from "@material-ui/icons";
import CardIcon from "components/Card/CardIcon";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { NotificationContext } from "views/Components/Context.js";
import ImageViewer from "views/Components/ImageViewer.js";
import Alert from "views/Components/Alert.js";
const useStyles = makeStyles(styles);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);
const usedashboardStyle = makeStyles(dashboardStyle);
const theme = createMuiTheme({
  overrides: {
    MuiDropzoneSnackbar: {
      infoAlert: {
        backgroundColor: "#435966",
        color: "#FFFFFF"
      },
      successAlert: {
        backgroundColor: "#435966",
        color: "#FFFFFF"
      },
    }
  }
});

export default function Notes(props) {
  const classes = useStyles();
  const { showNotification } = React.useContext(NotificationContext);

  const dashboardClasses = usedashboardStyle();
  const sweetAlertClasses = usesweetAlertStyle();
  const [note, setNote] = useState("");
  const [alert, setAlert] = React.useState();
  const [title, setTitle] = useState("");
  const [imageUploadSelect, setImageUploadSelect] = useState(0);
  const [editImageUploadSelect, setEditImageUploadSelect] = useState(0);
  const [editImage, setEditImage] = useState();
  const [openDropZone, setOpenDropZone] = useState(false);
  const { params } = useRouteMatch();
  const [user, setUser] = useState();
  const [viewNoteModal, setViewNoteModal] = useState(false);
  const [viewModalData, setViewModalData] = useState({
    PK: "",
    Title: "",
    Content: "",
  });
  const [noteImage, setNoteImage] = useState();
  const [deletedImages, setDeletedImages] = useState([]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  const hideAlert = () => {
    setAlert();
  };

  const deleteAlert = (noteData) => {
    setAlert(
      <Alert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteNote(noteData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button
        }
        cancelBtnCssClass={
          sweetAlertClasses.button
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this Note!
      </Alert>
    );
  };

  const handleDeleteNote = (note) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/note/${note.PK.split("#")[1]}`,
      {
        method: "DELETE",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function () {
        let data = props.notes.filter((item) => note.PK !== item.PK);
        props.setNotes(props.stageType, data);
        showNotification("Note deleted successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Delete failed.", "danger");
        console.log(error);
      });
    hideAlert();
  };

  //create a new note
  const handleNoteSubmit = () => {
    let image = [];
    if (imageUploadSelect === 2) {
      try {
        let validCheck = JSON.parse(noteImage)
        image.push({ type: "plotly", image: noteImage });
      } catch (error) {
        showNotification("Not a valid plotly json", "danger");
        return
      }
    }
    if (imageUploadSelect === 1) {
      image.push({ type: "base64", image: noteImage });
    }
    if (imageUploadSelect === 3) {
      image.push({ type: "embed", image: noteImage });
    }
    if (imageUploadSelect === 4) {
      image.push({ type: "file", image: noteImage.name });
    }
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL + `/experiment/${params.exp_id}/note`,
      {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          stageType: props.stageType,
          note: note,
          title: title,
          image: image,
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then((data) => {
        props.setNotes(props.stageType, props.notes.concat(data.Items));

        if (data.hasOwnProperty("S3Links") && data["S3Links"].length > 0) {
          for (let s3Link in data["S3Links"]) {
            const formData = new FormData();
            Object.entries(data["S3Links"][s3Link]["url"]["fields"]).forEach(
              ([k, v]) => {
                formData.append(k, v);
              }
            );
            formData.append("Content-Type", noteImage.type);
            formData.append("file", noteImage);
            fetch(data["S3Links"][s3Link]["url"]["url"], {
              method: "POST",
              body: formData,
            })
              .then((data) => {
                return data.json();
              })
              .catch((err) => {
                return err;
              });
          }
        }

        setNote("");
        setTitle("");
        setNoteImage();
        setImageUploadSelect(0);
        showNotification("Note added.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Note creation failed.", "danger");
        console.log(error);
      });
  };

  const handleViewNote = (note) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/note/${note.PK.split("#")[1]}/artifact`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then(
      function (res) {
        res.json().then(function (data) {
          // console.log(data);
          setViewNoteModal(true);
          setViewModalData({ ...note, artifacts: [...data.Items] });
        });
      },
      function (err) {
        showNotification("Note read failed.", "danger");
        console.log(err);
      }
    );
  };

  const handleDeleteArtifact = (artifact) => {
    setDeletedImages([...deletedImages, artifact.SK]);
    let newData = { ...viewModalData };
    newData.artifacts.find((o, i) => {
      if (o.SK === artifact.SK) {
        newData.artifacts.splice(i, 1);
        return true;
      } else {
        return false;
      }
    });
    setViewModalData(newData);
  };
  const handleEditNote = () => {
    let newImages = [];
    if (editImageUploadSelect === 2) {
      try {
        let validCheck = JSON.parse(editImage)
        newImages.push({ type: "plotly", image: editImage });
      } catch (error) {
        showNotification("Not a valid plotly json", "danger");
        return
      }

    }
    if (editImageUploadSelect === 1) {
      newImages.push({ type: "base64", image: editImage });
    }
    if (editImageUploadSelect === 3) {
      newImages.push({ type: "embed", image: editImage });
    }
    if (editImageUploadSelect === 4) {
      newImages.push({ type: "file", image: editImage.name });
    }
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/note/${viewModalData.PK.split("#")[1]}`,
      {
        method: "PUT",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          title: viewModalData.Title,
          note: viewModalData.Content,
          deletedArtifacts: deletedImages,
          newArtifacts: newImages,
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        let newData = [...props.notes];
        props.notes.find((o, i) => {
          if (o.PK === viewModalData.PK) {
            newData[i] = viewModalData;
            return true;
          } else {
            return false;
          }
        });

        if (data.hasOwnProperty("S3Links") && data["S3Links"].length > 0) {
          for (let s3Link in data["S3Links"]) {
            const formData = new FormData();
            Object.entries(data["S3Links"][s3Link]["url"]["fields"]).forEach(
              ([k, v]) => {
                formData.append(k, v);
              }
            );
            formData.append("Content-Type", editImage.type);
            formData.append("file", editImage);
            fetch(data["S3Links"][s3Link]["url"]["url"], {
              method: "POST",
              body: formData,
            })
              .then((data) => {
                return data.json();
              })
              .catch((err) => {
                return err;
              });
          }
        }

        props.setNotes(props.stageType, newData);
        showNotification("Note updated successfully.", "quSandBoxColor");
      })
      .then(() => handleViewModalClose())
      .catch((error) => {
        console.log(error);
        showNotification("Update failed.", "danger");
      });
  };

  const handleImageSelectChange = (event) => {
    setImageUploadSelect(event.target.value);
    setNoteImage();
  };

  const handleEditImageSelectChange = (event) => {
    setEditImageUploadSelect(event.target.value);
    setEditImage();
  };
  const handleClose = () => {
    setOpenDropZone(false);
  };

  const handleViewModalClose = () => {
    setViewNoteModal(false);
    setViewModalData({});
    setDeletedImages([]);
    setEditImageUploadSelect(0);
    setEditImage();
  };

  const handleSave = (files) => {
    setNoteImage(files[0]);
    setOpenDropZone(false);
  };

  const handleEditSave = (files) => {
    setEditImage(files[0]);
    setOpenDropZone(false);
  };

  const handleOpen = () => {
    setOpenDropZone(true);
  };

  return (
    <div>
      {alert}
      {/* <Snackbar
        place="br"
        color={operationStatus}
        icon={AddAlert}
        message={notificationMessage}
        open={notification}
        closeNotification={() => setNotification(false)}
        close
      /> */}

      <Dialog
        open={viewNoteModal}
        onClose={handleViewModalClose}
        aria-labelledby="form-dialog-note"
      >
        <DialogTitle id="form-dialog-note">Edit Note</DialogTitle>
        <DialogContent>
          <CustomInput
            labelText="Id"
            id="id"
            formControlProps={{
              fullWidth: true,
            }}
            value={viewModalData.PK || ""}
            onChange={(e) => {
              let change = e.target.value;
              setViewModalData((prevState) => ({
                ...prevState,
                PK: change,
              }));
            }}
            inputProps={{
              type: "text",
              disabled: true,
            }}
          />
          <CustomInput
            labelText="Title"
            id="title"
            formControlProps={{
              fullWidth: true,
            }}
            value={viewModalData.Title || ""}
            onChange={(e) => {
              let change = e.target.value;
              setViewModalData((prevState) => ({
                ...prevState,
                Title: change,
              }));
            }}
            inputProps={{
              type: "text",
            }}
          />
          <CustomInput
            labelText="Note"
            id="note"
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(e) => {
              let change = e.target.value;
              setViewModalData((prevState) => ({
                ...prevState,
                Content: change,
              }));
            }}
            value={viewModalData.Content || ""}
            inputProps={{
              type: "text",
              multiline: true,
              rows: 3,
            }}
          />

          {!viewModalData.hasOwnProperty("artifacts") ||
            (viewModalData.artifacts.length === 0 && (
              <>
                <div className={classes.imageLabel}>Add Image</div>
                <GridContainer>
                  <GridItem md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Choose Image Format
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        inputProps={{
                          name: "notesSelect",
                          id: "notes-select",
                        }}
                        value={editImageUploadSelect || 0}
                        onChange={handleEditImageSelectChange}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                          value={0}
                        >
                          Choose Image Format
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={1}
                        >
                          Base64
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={2}
                        >
                          Plotly
                        </MenuItem>

                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={3}
                        >
                          Embed Link
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={4}
                        // disabled
                        >
                          Upload an Image
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem md={6}>
                    {editImageUploadSelect === 2 && (
                      <CustomInput
                        labelText="Plotly Json"
                        id="plotly"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={editImage}
                        onChange={(event) => setEditImage(event.target.value)}
                        inputProps={{
                          type: "text",
                        }}
                      />
                    )}
                    {editImageUploadSelect === 1 && (
                      <CustomInput
                        labelText="Base64 Image"
                        id="base64"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={editImage}
                        onChange={(event) => setEditImage(event.target.value)}
                        inputProps={{
                          type: "text",
                        }}
                      />
                    )}
                    {editImageUploadSelect === 3 && (
                      <CustomInput
                        labelText="Embed Link"
                        id="embed"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={editImage}
                        onChange={(event) => setEditImage(event.target.value)}
                        inputProps={{
                          type: "text",
                        }}
                      />
                    )}
                    {editImageUploadSelect === 4 && (
                      <GridContainer>
                        <GridItem md={4}>
                          <Button onClick={handleOpen} color="info">
                            Add Image
                          </Button>
                        </GridItem>
                        <DropzoneDialog
                          open={openDropZone}
                          onSave={handleEditSave}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                          ]}
                          showPreviews={true}
                          filesLimit={1}
                          onClose={handleClose}
                        // initialFiles={[editImage]}
                        />
                        <GridItem md={8}>
                          {!!editImage && <span>{editImage.name}</span>}
                        </GridItem>
                      </GridContainer>
                    )}
                  </GridItem>
                </GridContainer>
              </>
            ))}

          {viewModalData.hasOwnProperty("artifacts") &&
            viewModalData.artifacts.length > 0 && (
              <>
                <div className={classes.imageLabel}>Artifacts</div>
                <GridContainer justify="center">
                  {viewModalData.artifacts.map((data, index) => {
                    return (
                      <GridItem sm={12} key={index}>
                        <ImageViewer
                          image={data}
                          // imageType={data.ArtifactType}
                          removeButtonProps={{
                            color: "info",
                            round: true,
                          }}
                          handleRemove={handleDeleteArtifact}
                        />
                      </GridItem>
                    );
                  })}
                </GridContainer>
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewModalClose} color="quSandBoxColor">
            Close
          </Button>
          <Button onClick={handleEditNote} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader color="quSandBox" icon>
          <CardIcon color="quSandBox">
            <CreateIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Create Note</h4>
        </CardHeader>
        <CardBody>
          <CustomInput
            labelText="Title"
            id="title"
            formControlProps={{
              fullWidth: true,
            }}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            inputProps={{
              type: "text",
            }}
          />
          <CustomInput
            labelText="Note"
            id="note"
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(event) => setNote(event.target.value)}
            value={note}
            inputProps={{
              type: "text",
              multiline: true,
              rows: 3,
              required: true,
            }}
          />

          <GridContainer>
            <GridItem xs={12} sm={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose Image Format
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  inputProps={{
                    name: "noteSelect",
                    id: "note-select",
                  }}
                  value={imageUploadSelect}
                  onChange={handleImageSelectChange}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value={0}
                  >
                    Choose Image Format
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={1}
                  >
                    Base64
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={2}
                  >
                    Plotly
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={3}
                  >
                    Embed Link
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={4}
                  // disabled
                  >
                    Upload an Image
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6}>
              {imageUploadSelect === 2 && (
                <CustomInput
                  labelText="Plotly Image"
                  id="plotly"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={noteImage}
                  onChange={(event) => setNoteImage(event.target.value)}
                  inputProps={{
                    type: "text",
                  }}
                />
              )}
              {imageUploadSelect === 1 && (
                <CustomInput
                  labelText="Base64 Image"
                  id="base64"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={noteImage}
                  onChange={(event) => setNoteImage(event.target.value)}
                  inputProps={{
                    type: "text",
                  }}
                />
              )}
              {imageUploadSelect === 3 && (
                <CustomInput
                  labelText="Embed Link"
                  id="embed"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={noteImage}
                  onChange={(event) => setNoteImage(event.target.value)}
                  inputProps={{
                    type: "text",
                  }}
                />
              )}
              {imageUploadSelect === 4 && (
                <GridContainer>
                  <GridItem md={4}>
                    <Button onClick={handleOpen} block color="info">
                      Add Image
                    </Button>
                  </GridItem>

                  <MuiThemeProvider theme={theme}>
                    <DropzoneDialog
                      open={openDropZone}
                      onSave={handleSave}
                      acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                      showPreviews={true}
                      filesLimit={1}
                      onClose={handleClose}
                    // initialFiles={[noteImage]}
                    />
                  </MuiThemeProvider>
                  <GridItem md={8}>
                    {!!noteImage && <span>{noteImage.name}</span>}
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <Button
            disabled={note === ""}
            color="quSandBoxColor"
            onClick={handleNoteSubmit}
          >
            Save
          </Button>
        </CardFooter>
      </Card>
      {props.notes.length > 0 && (
        <Card>
          <CardHeader color="quSandBox" icon>
            <CardIcon color="quSandBox">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Notes</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                "#",
                "Title",
                "Id",
                "Author",
                "Created At",
                "Actions",
              ]}
              tableData={props.notes.map((note, id) => [
                id + 1,
                <Button
                  color="transparent"
                  onClick={() => handleViewNote(note)}
                >
                  <div style={{ textTransform: "none" }}>{note.Title}</div>
                </Button>,
                <Button
                  color="transparent"
                  onClick={() => handleViewNote(note)}
                >
                  <div style={{ textTransform: "none" }}>
                    {note.PK.split("#")[1]}
                  </div>
                </Button>,
                <a href={`mailto:${note.CreatedBy.email}`} style={{ textDecoration: "underline" }}>
                  {note.CreatedBy.name}
                </a>,
                new Date(note.timestamp * 1000).toLocaleString(),
                <div key={id}>
                  <Tooltip
                    id="tooltip-top"
                    title="View Note"
                    placement="bottom"
                    classes={{ tooltip: dashboardClasses.tooltip }}
                  >
                    <Button
                      color="transparent"
                      size="sm"
                      onClick={() => handleViewNote(note)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Delete Note"
                    placement="bottom"
                    classes={{ tooltip: dashboardClasses.tooltip }}
                  >
                    <Button
                      color="transparent"
                      size="sm"
                      onClick={() => deleteAlert(note)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </div>,
              ])}
              customCellClasses={[classes.center, classes.right]}
              customClassesForCells={[0, 5]}
              customHeadCellClasses={[classes.center, classes.right]}
              customHeadClassesForCells={[0, 5]}
            />
          </CardBody>
        </Card>
      )}
    </div>
  );
}
