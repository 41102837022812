import {
    tooltip,
    cardTitle,
  } from "assets/jss/material-dashboard-pro-react.js";
  import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";
  import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
  import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
  import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js"
  const reportStyle = (theme) => ({
    ...regularFormsStyle,
    ...hoverCardStyle,
    ...customSelectStyle,
    ...extendedTablesStyle,
    tooltip,
  
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // marginBottom: "20px"
    },
    card: {
      overflow: "visible",
      // marginTop: "30px",
    },
    cardTitle: {
      ...cardTitle,
      marginTop: "0px",
      marginBottom: "3px",
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      paddingTop: "10px",
      marginBottom: "0px",
    },
    imageLabel: {
      top: "10px",
      color: "#AAAAAA !important",
      fontSize: "14px",
      fontFamily: "Roboto, Helvetica, Arial ,sans-serif",
      fontWeight: "400",
      lineHeight: "1.42857",
      letterSpacing: "unset",
    },
    imageFrame: {
      width: "100%",
      marginRight:"auto",
      marginLeft:"auto"
    },
    image:{
      maxWidth:"100%",
      // marginRight:"auto",
      // marginLeft:"auto"
      textAlign:"center"
    }
  });
  
  export default reportStyle;
  