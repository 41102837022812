import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Heading from "components/Heading/Heading.js";
import {
    Card,
} from "@material-ui/core";

import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";

import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function MLSecurityStage(props) {
    const classes = useStyles();

    const {
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
        projectID: projectID,
    } = React.useContext(ExperimentContext)

    let tmpTabs = [
        // {
        //     tabButton: "MLSecurity",
        //     tabContent: (

        //         <Card className={classes.card}>
        //             <CardHeader icon>
        //                 <CardIcon color="quSandBox">
        //                     <IconDict.MLSecurity />
        //                 </CardIcon>
        //                 <h4 className={classes.cardIconTitle}>
        //                     Test Plan
        //                 </h4>
        //             </CardHeader>
        //             <CardBody>
        //                 <Heading title="*Coming Soon*" textAlign="center" />
        //             </CardBody>
        //         </Card>
        //     ),
        //     tabIcon: IconDict['MLSecurity']
        // }
    ]
    if (stages.hasOwnProperty('MLSecurity') && (stages['MLSecurity'].hasOwnProperty('Link')|| stages['MLSecurity'].hasOwnProperty('Notebook'))) {
        tmpTabs.push(
            {
                tabButton: "MLSecurity",
                tabContent: (

                    <Card className={classes.card}>
                        <CardHeader icon>
                            <CardIcon color="quSandBox">
                                <IconDict.MLSecurity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                MLSecurity
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="center">

                                {stages['MLSecurity'].hasOwnProperty('Notebook') && <GridItem xs={12} style={{ textAlign: "center" }}> <Button
                                    color="info"
                                    size="lg"
                                    onClick={() =>
                                        window.open(
                                            stages['MLSecurity']['Notebook']
                                        )
                                    }
                                    className={classes.buttonsTopSpace}
                                >
                                    Run on QuSandbox
                                </Button>
                                </GridItem>

                                }
                                {stages['MLSecurity'].hasOwnProperty('Link') &&
                                    <iframe
                                        title="MLSecurity"
                                        id="MLSecurity"
                                        src={stages['MLSecurity'].Link}
                                        width="100%"
                                        frameBorder="0"
                                        height={`${stages['MLSecurity'].IframeHeight || 1600}px`}
                                    ></iframe>
                                }
                            </GridContainer>
                        </CardBody>
                    </Card>
                ),
                tabIcon: IconDict['MLSecurity']
            })
    }
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('MLSecurity') ? stages['MLSecurity'].PK : ""}
                    notes={allNotes.hasOwnProperty("MLSecurity")
                        ? allNotes.MLSecurity
                        : []}
                    stageType="MLSecurity"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("MLSecurity")
                        ? allNotes.MLSecurity
                        : []}
                    setNotes={setNotesHelper}
                    stageType="MLSecurity"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("MLSecurity")
                            ? allIssues.MLSecurity
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="MLSecurity"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <NavPills
        color="quSandBoxColor"
        alignCenter
        tabs={tabs}
    />
}