import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import {
    Card,
    CardActionArea,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from "@material-ui/core";

import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import IconDict from "variables/IconDict.js"
import Notes from "views/Components/Notes.js";
import Issues from "views/Components/Issues.js";
import GeneralReportTemplate from "views/Components/GeneralReportTemplate.js";
import ImageViewer from "views/Components/ImageViewer.js";
import RiskMetric from "views/Components/RiskMetric.js";


import VisibilityIcon from "@material-ui/icons/Visibility";
import { NotificationContext, ExperimentContext } from "views/Components/Context.js";
import styles from "assets/jss/material-dashboard-pro-react/views/experimentStyle.js";
const useStyles = makeStyles(styles);

export default function IssuesStage(props) {
    const classes = useStyles();

    const {
        allNotes: [allNotes, setNotesHelper],
        allIssues: [allIssues, setIssuesHelper],
        stages: [stages, setStages],
        user: user,
        exp_id: exp_id,
        projectID: projectID,  
    } = React.useContext(ExperimentContext)
    const { showNotification } = React.useContext(NotificationContext);

    const [viewIssueModal, setViewIssueModal] = useState(false);
    const [viewModalData, setViewModalData] = useState({});
    const [viewContent, setViewContent] = useState({
    });

    const [issuesArray, setIssuesArray] = React.useState([]);
    useEffect(() => {
        let tmp = []
        for (var key in allIssues) {
            tmp = [...tmp, ...allIssues[key]]
        }
        setIssuesArray(tmp)
    }
        , [allIssues]
    )

    const referenceMetrics = [
        [1, 2, 3, 4, 5],
        [2, 4, 6, 8, 10],
        [3, 6, 9, 12, 15],
        [4, 8, 12, 16, 20],
        [5, 10, 15, 20, 25]
    ]

    const scoreFormatHelper = (str) => {
        let tmp = parseInt(str)
        if (tmp <= 1) {
            return 0;
        } else if (tmp >= 5) {
            return 4;
        } else {
            return tmp - 1;
        }
    }


    const handleViewIssue = (issue) => {
        let token = user.signInUserSession.accessToken.jwtToken;
        fetch(
            process.env.REACT_APP_BACK_END_URL +
            `/experiment/${exp_id}/issue/${issue.PK.split("#")[1]}/artifact`,
            {
                method: "GET",
                mode: "cors",
                credentials: "omit",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        ).then(
            function (res) {
                res.json().then(function (data) {
                    setViewModalData({ ...issue, artifacts: [...data.Items] });
                    setViewContent(issue.Content ? issue.Content : {})
                    setViewIssueModal(true);
                });
            },
            function (err) {
                showNotification("Issue read failed.", "danger");
                console.log(err);
            }
        );
    };


    let tmpTabs = [
        {
            tabButton: "Issues",
            tabContent: (
                <Card className={classes.card}>
                    <CardHeader icon>
                        <CardIcon color="quSandBox">
                            <IconDict.Issues />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Issues</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            tableHead={[
                                "#",
                                'Board',
                                "ID",
                                "Aspects",
                                // "Impacts",
                                // "Impact Score",
                                // "Probability of Occurrence",
                                "Risk Score",
                                // "",
                                // "Risk Controls",
                                // "New Impact Score",
                                // "New Probability of Occurrence",
                                "New Risk Score",
                                // "",
                                "Operations"
                            ]}
                            tableData={issuesArray.map((issue, id) => [
                                id + 1,
                                issue.StageType,
                                issue.PK.split("#")[1],
                                issue.Content.aspects,
                                // issue.Content.impacts,
                                // issue.Content.score,
                                // issue.Content.occurrence,
                                (issue && issue.hasOwnProperty('Content') && issue.Content.score && issue.Content.occurrence) ? referenceMetrics[scoreFormatHelper(issue.Content.score)][scoreFormatHelper(issue.Content.occurrence)] : "",
                                // (issue && issue.hasOwnProperty('Content') && issue.Content.score && issue.Content.occurrence) ? <div className={cssMetric[scoreFormatHelper(issue.Content.score)][scoreFormatHelper(issue.Content.occurrence)]}>{"a"}</div> : "",
                                // issue.Content.controls,
                                // issue.Content.newScore,
                                // issue.Content.newOccurrence,
                                (issue && issue.hasOwnProperty('Content') && issue.Content.newScore && issue.Content.newOccurrence) ? referenceMetrics[scoreFormatHelper(issue.Content.newScore)][scoreFormatHelper(issue.Content.newOccurrence)] : "",
                                // (issue && issue.hasOwnProperty('Content') && issue.Content.newScore && issue.Content.newOccurrence) ? <div className={cssMetric[scoreFormatHelper(issue.Content.newScore)][scoreFormatHelper(issue.Content.newOccurrence)]}>{"a"}</div> : "",
                                <div key={id}>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="View Issue"
                                        placement="bottom"
                                    >
                                        <Button
                                            color="transparent"
                                            size="sm"
                                            onClick={() => handleViewIssue(issue)}
                                        >
                                            <VisibilityIcon />
                                        </Button>
                                    </Tooltip>
                                </div>,
                            ])}
                        />
                    </CardBody>
                </Card>
            ),
            tabIcon: IconDict['Issues']
        }
    ]
    if (props.config.reports) {
        tmpTabs.push(
            {
                tabButton: "Reports",
                tabIcon: IconDict['Reports'],
                tabContent: (<GeneralReportTemplate
                    templateId={
                        props.config.template
                            ? props.config.template
                            : ""
                    }
                    projectId={projectID}
                    stageId={stages.hasOwnProperty('Issues') ? stages['Issues'].PK : ""}
                    notes={allNotes.hasOwnProperty("Issues")
                        ? allNotes.Issues
                        : []}
                    stageType="Issues"
                />)
            }
        )
    }
    if (props.config.notes) {
        tmpTabs.push(
            {
                tabButton: "Notes",
                tabIcon: IconDict['Notes'],
                tabContent: (<Notes
                    notes={allNotes.hasOwnProperty("Issues")
                        ? allNotes.Issues
                        : []}
                    setNotes={setNotesHelper}
                    stageType="Issues"
                />),
            }
        )
    }
    if (props.config.issues) {
        tmpTabs.push(
            {
                tabButton: "Issues",
                tabIcon: IconDict['Issues'],
                tabContent: (
                    <Issues
                        issues={allIssues.hasOwnProperty("Issues")
                            ? allIssues.Issues
                            : []}
                        setIssues={setIssuesHelper}
                        stageType="Issues"
                    />
                ),
            })
    }

    const tabs = tmpTabs
    return <>
        <Dialog
            open={viewIssueModal}
            onClose={() => setViewIssueModal(false)}
            aria-labelledby="form-dialog-issue"
        >
            <DialogTitle id="form-dialog-issue">Edit Issue</DialogTitle>
            <DialogContent>
                <CustomInput
                    labelText="Id"
                    id="id"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    value={viewModalData.PK || ""}
                    inputProps={{
                        type: "text",
                        disabled: true,
                    }}
                />
                <CustomInput
                    labelText="Aspects"
                    id="Aspects"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    value={viewContent.aspects || ""}
                    onChange={(event) => setViewContent({ ...viewContent, aspects: event.target.value })}
                    inputProps={{
                        required: true,
                        type: "text",
                        disabled: true,
                    }}
                />
                <CustomInput
                    labelText="Impacts"
                    id="Impacts"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    onChange={(event) => setViewContent({ ...viewContent, impacts: event.target.value })}
                    value={viewContent.impacts || ""}
                    inputProps={{
                        type: "text",
                        multiline: true,
                        rows: 3,
                        required: true,
                        disabled: true,
                    }}
                />
                <GridContainer>
                    <GridItem xs={6}><CustomInput
                        labelText="Impact Score (1-5)"
                        id="score"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={viewContent.score || "1"}
                        onChange={(event) => setViewContent({ ...viewContent, score: event.target.value })}
                        inputProps={{
                            type: "number",
                            disabled: true,
                        }}
                    /></GridItem>
                    <GridItem xs={6}><CustomInput
                        labelText="Probability of Occurrence (1-5)"
                        id="occurrence"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={viewContent.occurrence || "1"}
                        onChange={(event) => setViewContent({ ...viewContent, occurrence: event.target.value })}
                        inputProps={{
                            type: "number",
                            disabled: true,
                        }}
                    /></GridItem>
                    <GridItem>
                        <RiskMetric row={scoreFormatHelper(viewContent.score || "1")} col={scoreFormatHelper(viewContent.occurrence || "1")}></RiskMetric>
                    </GridItem>
                </GridContainer>
                <CustomInput
                    labelText="Controls"
                    id="Controls"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    onChange={(event) => setViewContent({ ...viewContent, controls: event.target.value })}
                    value={viewContent.controls || ""}
                    inputProps={{
                        type: "text",
                        multiline: true,
                        rows: 3,
                        required: true,
                        disabled: true,
                    }}
                />
                <GridContainer>
                    <GridItem xs={6}><CustomInput
                        labelText="New Impact Score (1-5)"
                        id="newScore"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={viewContent.newScore || "1"}
                        onChange={(event) => setViewContent({ ...viewContent, newScore: event.target.value })}
                        inputProps={{
                            type: "number",
                            disabled: true,
                        }}
                    /></GridItem>
                    <GridItem xs={6}><CustomInput
                        labelText="New Probability of Occurrence (1-5)"
                        id="newOccurrence"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        value={viewContent.newOccurrence || "1"}
                        onChange={(event) => setViewContent({ ...viewContent, newOccurrence: event.target.value })}
                        inputProps={{
                            type: "number",
                            disabled: true,
                        }}
                    /></GridItem>
                    <GridItem>
                        <RiskMetric row={scoreFormatHelper(viewContent.newScore || "1")} col={scoreFormatHelper(viewContent.newOccurrence || "1")}></RiskMetric>
                    </GridItem>
                </GridContainer>

                {viewModalData.hasOwnProperty("artifacts") &&
                    viewModalData.artifacts.length > 0 && (
                        <>
                            <div className={classes.imageLabel}>Artifacts</div>
                            <GridContainer justify="center">
                                {viewModalData.artifacts.map((data, index) => {
                                    return (
                                        <GridItem sm={12} key={index}>
                                            <ImageViewer
                                                image={data}
                                            />
                                        </GridItem>
                                    );
                                })}
                            </GridContainer>
                        </>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setViewIssueModal(false)} color="quSandBoxColor">
                    Close
                </Button>
                <Button href={'?stage=' + viewModalData.StageType} color="quSandBoxColor">
                    Edit
                </Button>
            </DialogActions>
        </Dialog>

        <NavPills
            color="quSandBoxColor"
            alignCenter
            tabs={tabs}
        /></>
}