import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Amplify components
import { Auth } from "aws-amplify";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import QUApi from "views/Pages/user/QUApi.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { NotificationContext } from "views/Components/Context.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import { AddAlert } from "@material-ui/icons";
var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(
    require("assets/img/homescreen.jpeg")
  );
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [user, setUser] = React.useState();
  const [userType, setUserType] = React.useState();
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(
    require("assets/img/qusandbox_logo_draft_3__1_ (1).png")
  );
  const [notification, setNotification] = React.useState(false);
  const [operationStatus, setOperationStatus] = React.useState("success");
  const [notificationMessage, setNotificationMessage] = React.useState("");
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (
          user.signInUserSession.accessToken.payload.hasOwnProperty(
            "cognito:groups"
          )
        ) {
          setUserType(
            user.signInUserSession.accessToken.payload["cognito:groups"][0]
          );
        }
        return user;
      })
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  // ref for main panel div
  const mainPanel = React.createRef();

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1 && mainPanel.current) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1 && mainPanel.current) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  // functions for changeing the states from components
  // const handleImageClick = (image) => {
  //   setImage(image);
  // };
  // const handleColorClick = (color) => {
  //   setColor(color);
  // };
  // const handleBgColorClick = (bgColor) => {
  //   switch (bgColor) {
  //     case "white":
  //       setLogo(require("assets/img/logo.svg"));
  //       break;
  //     default:
  //       setLogo(require("assets/img/logo-white.svg"));
  //       break;
  //   }
  //   setBgColor(bgColor);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Page Not Found";
    if (window.location.href.includes("QuToolBox/all")) {
      return "All";
    }
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    // console.log(user)
    return routes.map((prop, key) => {
      if (
        prop.hasOwnProperty("userTypes") &&
        !prop["userTypes"].includes(userType)
      ) {
        // console.log(userType)
        // console.log(prop['userTypes'].includes(userType))
        return null;
      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const showNotification = (message, status, timer = 6000) => {
    setOperationStatus(status);
    setNotificationMessage(message);
    setNotification(true);
    if (timer) {
      setTimeout(function () {
        setNotification(false);
      }, timer);
    }
  };

  return (
    <div className={classes.wrapper}>
      <NotificationContext.Provider value={{ showNotification }}>
        <Snackbar
          place="br"
          color={operationStatus}
          icon={AddAlert}
          message={notificationMessage}
          open={notification}
          closeNotification={() => setNotification(false)}
          close
        />
        {user && (
          <>
            <Sidebar
              routes={routes}
              logoText={""}
              logo={logo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              bgColor={bgColor}
              miniActive={miniActive}
              {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel} id='mainPanel'>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText={getActiveRoute(routes)}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {getRoutes(routes)}
                      <Route path="/admin/QuToolBox/all" component={QUApi} />
                      <Redirect from="/admin" to="/admin/projects" />
                    </Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {getRoutes(routes)}
                    <Route path="/admin/QuToolBox/all" component={QUApi} />
                    <Redirect from="/admin" to="/admin/projects" />
                  </Switch>
                </div>
              )}
              {getRoute() ? <Footer fluid /> : null}
            </div>
          </>
        )}
      </NotificationContext.Provider>
    </div>
  );
}
