import styles from "assets/jss/material-dashboard-pro-react/components/notesStyle.js";
import React, { useEffect, useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { makeStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// Amplify components
import { Auth } from "aws-amplify";
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
// import riskMetricStyle from "assets/jss/material-dashboard-pro-react/components/riskMetricStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Table from "components/Table/Table.js";
import DeleteIcon from "@material-ui/icons/Delete";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useRouteMatch } from "react-router-dom";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { Assignment } from "@material-ui/icons";
import CardIcon from "components/Card/CardIcon";
import CreateIcon from "@material-ui/icons/Create";
import ImageViewer from "views/Components/ImageViewer.js";
import RiskMetric from "views/Components/RiskMetric.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {NotificationContext} from "views/Components/Context.js";
import Alert from "views/Components/Alert.js";
const useStyles = makeStyles(styles);
const usesweetAlertStyle = makeStyles(sweetAlertStyle);
const usedashboardStyle = makeStyles(dashboardStyle);
// const useRiskMetricStyle = makeStyles(riskMetricStyle);
const theme = createMuiTheme({
  overrides: {
    MuiDropzoneSnackbar: {
      infoAlert: {
        backgroundColor: "#435966",
        color: "#FFFFFF"
      },
      successAlert: {
        backgroundColor: "#435966",
        color: "#FFFFFF"
      },
    }
  }
});

export default function Issues(props) {
  const classes = useStyles();
  // const metricClasses = useRiskMetricStyle()
  const { showNotification } = React.useContext(NotificationContext);
  const referenceMetrics = [
    [1, 2, 3, 4, 5],
    [2, 4, 6, 8, 10],
    [3, 6, 9, 12, 15],
    [4, 8, 12, 16, 20],
    [5, 10, 15, 20, 25]
  ]
  // const cssMetric = [
  //   [metricClasses.low, metricClasses.low, metricClasses.low, metricClasses.low, metricClasses.moderate,],
  //   [metricClasses.low, metricClasses.low, metricClasses.moderate, metricClasses.moderate, metricClasses.high,],
  //   [metricClasses.low, metricClasses.moderate, metricClasses.moderate, metricClasses.high, metricClasses.high,],
  //   [metricClasses.low, metricClasses.high, metricClasses.high, metricClasses.high, metricClasses.high,],
  //   [metricClasses.moderate, metricClasses.high, metricClasses.high, metricClasses.high, metricClasses.high,]
  // ]

  const dashboardClasses = usedashboardStyle();
  const sweetAlertClasses = usesweetAlertStyle();
  const [issue, setIssue] = useState({});
  const [alert, setAlert] = React.useState();
  const [imageUploadSelect, setImageUploadSelect] = useState(0);
  const [editImageUploadSelect, setEditImageUploadSelect] = useState(0);
  const [editImage, setEditImage] = useState();
  const [openDropZone, setOpenDropZone] = useState(false);
  const [issueImage, setIssueImage] = useState();
  const [deletedImages, setDeletedImages] = useState([]);

  const { params } = useRouteMatch();
  const [user, setUser] = useState();
  const [viewIssueModal, setViewIssueModal] = useState(false);
  const [viewModalData, setViewModalData] = useState({});
  const [viewContent, setViewContent] = useState({
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
        if (err === "not authenticated") {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  const hideAlert = () => {
    setAlert();
  };

  const deleteAlert = (issueData) => {
    setAlert(
      <Alert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteIssue(issueData)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={
          sweetAlertClasses.button
        }
        cancelBtnCssClass={
          sweetAlertClasses.button
        }
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this Issue!
      </Alert>
    );
  };

  const handleDeleteIssue = (issue) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/issue/${issue.PK.split("#")[1]}`,
      {
        method: "DELETE",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function () {
        let data = props.issues.filter((item) => issue.PK !== item.PK);
        props.setIssues(props.stageType, data);
        showNotification("Issue deleted successfully.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Delete failed.", "danger");
        console.log(error);
      });
    hideAlert();
  };

  //create a new issue
  const handleIssueSubmit = () => {
    let image = [];
    if (imageUploadSelect === 2) {
      image.push({ type: "plotly", image: issueImage });
    }
    if (imageUploadSelect === 1) {
      image.push({ type: "base64", image: issueImage });
    }
    if (imageUploadSelect === 3) {
      image.push({ type: "embed", image: issueImage });
    }
    if (imageUploadSelect === 4) {
      image.push({ type: "file", image: issueImage.name });
    }
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL + `/experiment/${params.exp_id}/issue`,
      {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          stageType: props.stageType,
          issue: issue,
          image: image,
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then((data) => {
        props.setIssues(props.stageType, props.issues.concat(data.Items));
        if (data.hasOwnProperty("S3Links") && data["S3Links"].length > 0) {
          for (let s3Link in data["S3Links"]) {
            const formData = new FormData();
            Object.entries(data["S3Links"][s3Link]["url"]["fields"]).forEach(
              ([k, v]) => {
                formData.append(k, v);
              }
            );
            formData.append("Content-Type", issueImage.type);
            formData.append("file", issueImage);
            fetch(data["S3Links"][s3Link]["url"]["url"], {
              method: "POST",
              body: formData,
            })
              .then((data) => {
                return data.json();
              })
              .catch((err) => {
                return err;
              });
          }
        }
        setIssue({});
        setIssueImage();
        setImageUploadSelect(0);
        showNotification("Issue added.", "quSandBoxColor");
      })
      .catch((error) => {
        showNotification("Issue creation failed.", "danger");
        console.log(error);
      });
  };

  const handleViewIssue = (issue) => {
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/issue/${issue.PK.split("#")[1]}/artifact`,
      {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then(
      function (res) {
        res.json().then(function (data) {
          setViewModalData({ ...issue, artifacts: [...data.Items] });
          setViewContent(issue.Content ? issue.Content : {})
          setViewIssueModal(true);
        });
      },
      function (err) {
        showNotification("Issue read failed.", "danger");
        console.log(err);
      }
    );
  };
  const scoreFormatHelper = (str) => {
    let tmp = parseInt(str)
    if (tmp <= 1) {
      return 0;
    } else if (tmp >= 5) {
      return 4;
    } else {
      return tmp - 1;
    }
  }

  const handleDeleteArtifact = (artifact) => {
    setDeletedImages([...deletedImages, artifact.SK]);
    let newData = { ...viewModalData };
    newData.artifacts.find((o, i) => {
      if (o.SK === artifact.SK) {
        newData.artifacts.splice(i, 1);
        return true;
      } else {
        return false;
      }
    });
    setViewModalData(newData);
  };
  const handleEditIssue = () => {
    let newImages = [];
    if (editImageUploadSelect === 2) {
      newImages.push({ type: "plotly", image: editImage });
    }
    if (editImageUploadSelect === 1) {
      newImages.push({ type: "base64", image: editImage });
    }
    if (editImageUploadSelect === 3) {
      newImages.push({ type: "embed", image: editImage });
    }
    if (editImageUploadSelect === 4) {
      newImages.push({ type: "file", image: editImage.name });
    }
    let token = user.signInUserSession.accessToken.jwtToken;
    fetch(
      process.env.REACT_APP_BACK_END_URL +
      `/experiment/${params.exp_id}/issue/${viewModalData.PK.split("#")[1]}`,
      {
        method: "PUT",
        mode: "cors",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          issue: viewContent,
          deletedArtifacts: deletedImages,
          newArtifacts: newImages,
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        let newData = [...props.issues];
        props.issues.find((o, i) => {
          if (o.PK === viewModalData.PK) {
            newData[i] = viewModalData;
            newData[i].Content = viewContent;
            return true;
          } else {
            return false;
          }
        });
        if (data.hasOwnProperty("S3Links") && data["S3Links"].length > 0) {
          for (let s3Link in data["S3Links"]) {
            const formData = new FormData();
            Object.entries(data["S3Links"][s3Link]["url"]["fields"]).forEach(
              ([k, v]) => {
                formData.append(k, v);
              }
            );
            formData.append("Content-Type", editImage.type);
            formData.append("file", editImage);
            fetch(data["S3Links"][s3Link]["url"]["url"], {
              method: "POST",
              body: formData,
            })
              .then((data) => {
                return data.json();
              })
              .catch((err) => {
                return err;
              });
          }
        }
        props.setIssues(props.stageType, newData);
        showNotification("Issue updated successfully.", "quSandBoxColor");
      })
      .then(() => handleViewModalClose())
      .catch((error) => {
        console.log(error);
        showNotification("Update failed.", "danger");
      });
  };

  const handleImageSelectChange = (event) => {
    setImageUploadSelect(event.target.value);
    setIssueImage();
  };

  const handleEditImageSelectChange = (event) => {
    setEditImageUploadSelect(event.target.value);
    setEditImage();
  };
  const handleClose = () => {
    setOpenDropZone(false);
  };

  const handleViewModalClose = () => {
    setViewIssueModal(false);
    setViewModalData({});
    setViewContent({});
    setDeletedImages([]);
    setEditImageUploadSelect(0);
    setEditImage();
  };

  const handleSave = (files) => {
    setIssueImage(files[0]);
    setOpenDropZone(false);
  };

  const handleEditSave = (files) => {
    setEditImage(files[0]);
    setOpenDropZone(false);
  };

  const handleOpen = () => {
    setOpenDropZone(true);
  };

  return (
    <div>
      {alert}
      <Dialog
        open={viewIssueModal}
        onClose={handleViewModalClose}
        aria-labelledby="form-dialog-issue"
      >
        <DialogTitle id="form-dialog-issue">Edit Issue</DialogTitle>
        <DialogContent>
          <CustomInput
            labelText="Id"
            id="id"
            formControlProps={{
              fullWidth: true,
            }}
            value={viewModalData.PK || ""}
            inputProps={{
              type: "text",
              disabled: true,
            }}
          />
          <CustomInput
            labelText="Aspects"
            id="Aspects"
            formControlProps={{
              fullWidth: true,
            }}
            value={viewContent.aspects || ""}
            onChange={(event) => setViewContent({ ...viewContent, aspects: event.target.value })}
            inputProps={{
              required: true,
              type: "text",
            }}
          />
          <CustomInput
            labelText="Impacts"
            id="Impacts"
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(event) => setViewContent({ ...viewContent, impacts: event.target.value })}
            value={viewContent.impacts || ""}
            inputProps={{
              type: "text",
              multiline: true,
              rows: 3,
              required: true,
            }}
          />
          <GridContainer>
            <GridItem xs={6}><CustomInput
              labelText="Impact Score (1-5)"
              id="score"
              formControlProps={{
                fullWidth: true,
              }}
              value={viewContent.score || "1"}
              onChange={(event) => setViewContent({ ...viewContent, score: event.target.value })}
              inputProps={{
                type: "number",
              }}
            /></GridItem>
            <GridItem xs={6}><CustomInput
              labelText="Probability of Occurrence (1-5)"
              id="occurrence"
              formControlProps={{
                fullWidth: true,
              }}
              value={viewContent.occurrence || "1"}
              onChange={(event) => setViewContent({ ...viewContent, occurrence: event.target.value })}
              inputProps={{
                type: "number",
              }}
            /></GridItem>
            <GridItem>
              <RiskMetric row={scoreFormatHelper(viewContent.score || "1")} col={scoreFormatHelper(viewContent.occurrence || "1")}></RiskMetric>
            </GridItem>
          </GridContainer>
          <CustomInput
            labelText="Controls"
            id="Controls"
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(event) => setViewContent({ ...viewContent, controls: event.target.value })}
            value={viewContent.controls || ""}
            inputProps={{
              type: "text",
              multiline: true,
              rows: 3,
              required: true,
            }}
          />
          <GridContainer>
            <GridItem xs={6}><CustomInput
              labelText="New Impact Score (1-5)"
              id="newScore"
              formControlProps={{
                fullWidth: true,
              }}
              value={viewContent.newScore || "1"}
              onChange={(event) => setViewContent({ ...viewContent, newScore: event.target.value })}
              inputProps={{
                type: "number",
              }}
            /></GridItem>
            <GridItem xs={6}><CustomInput
              labelText="New Probability of Occurrence (1-5)"
              id="newOccurrence"
              formControlProps={{
                fullWidth: true,
              }}
              value={viewContent.newOccurrence || "1"}
              onChange={(event) => setViewContent({ ...viewContent, newOccurrence: event.target.value })}
              inputProps={{
                type: "number",
              }}
            /></GridItem>
            <GridItem>
              <RiskMetric row={scoreFormatHelper(viewContent.newScore || "1")} col={scoreFormatHelper(viewContent.newOccurrence || "1")}></RiskMetric>
            </GridItem>
          </GridContainer>
          {!viewModalData.hasOwnProperty("artifacts") ||
            (viewModalData.artifacts.length === 0 && (
              <>
                <div className={classes.imageLabel}>Add Image</div>
                <GridContainer>
                  <GridItem md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Choose Image Format
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        inputProps={{
                          name: "issuesSelect",
                          id: "issues-select",
                        }}
                        value={editImageUploadSelect || 0}
                        onChange={handleEditImageSelectChange}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                          value={0}
                        >
                          Choose Image Format
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={1}
                        >
                          Base64
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={2}
                        >
                          Plotly
                        </MenuItem>

                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={3}
                        >
                          Embed Link
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={4}
                        // disabled
                        >
                          Upload an Image
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem md={6}>
                    {editImageUploadSelect === 2 && (
                      <CustomInput
                        labelText="Plotly Json"
                        id="plotly"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={editImage}
                        onChange={(event) => setEditImage(event.target.value)}
                        inputProps={{
                          type: "text",
                        }}
                      />
                    )}
                    {editImageUploadSelect === 1 && (
                      <CustomInput
                        labelText="Base64 Image"
                        id="base64"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={editImage}
                        onChange={(event) => setEditImage(event.target.value)}
                        inputProps={{
                          type: "text",
                        }}
                      />
                    )}
                    {editImageUploadSelect === 3 && (
                      <CustomInput
                        labelText="Embed Link"
                        id="embed"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={editImage}
                        onChange={(event) => setEditImage(event.target.value)}
                        inputProps={{
                          type: "text",
                        }}
                      />
                    )}
                    {editImageUploadSelect === 4 && (
                      <GridContainer>
                        <GridItem>
                          <Button onClick={handleOpen} color="info">
                            Add Image
                          </Button>
                        </GridItem>
                        <DropzoneDialog
                          open={openDropZone}
                          onSave={handleEditSave}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                          ]}
                          showPreviews={true}
                          filesLimit={1}
                          onClose={handleClose}
                        // initialFiles={[editImage]}
                        />
                      </GridContainer>
                    )}
                  </GridItem>
                  <GridItem>
                    {editImageUploadSelect === 4 && !!editImage && <span>{editImage.name}</span>}
                  </GridItem>
                </GridContainer>
              </>
            ))}

          {viewModalData.hasOwnProperty("artifacts") &&
            viewModalData.artifacts.length > 0 && (
              <>
                <div className={classes.imageLabel}>Artifacts</div>
                <GridContainer justify="center">
                  {viewModalData.artifacts.map((data, index) => {
                    return (
                      <GridItem sm={12} key={index}>
                        <ImageViewer
                          image={data}
                          // imageType={data.ArtifactType}
                          removeButtonProps={{
                            color: "info",
                            round: true,
                          }}
                          handleRemove={handleDeleteArtifact}
                        />
                      </GridItem>
                    );
                  })}
                </GridContainer>
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewModalClose} color="quSandBoxColor">
            Close
          </Button>
          <Button onClick={handleEditIssue} color="quSandBoxColor">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader color="quSandBox" icon>
          <CardIcon color="quSandBox">
            <CreateIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Create Issue</h4>
        </CardHeader>
        <CardBody>
          <CustomInput
            labelText="Aspects"
            id="Aspects"
            formControlProps={{
              fullWidth: true,
            }}
            value={issue.aspects}
            onChange={(event) => setIssue({ ...issue, aspects: event.target.value })}
            inputProps={{
              required: true,
              type: "text",
            }}
          />
          <CustomInput
            labelText="Impacts"
            id="Impacts"
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(event) => setIssue({ ...issue, impacts: event.target.value })}
            value={issue.impacts}
            inputProps={{
              type: "text",
              multiline: true,
              rows: 3,
              required: true,
            }}
          />
          <GridContainer>
            <GridItem xs={6}><CustomInput
              labelText="Impact Score (1-5)"
              id="score"
              formControlProps={{
                fullWidth: true,
              }}
              value={issue.score}
              onChange={(event) => setIssue({ ...issue, score: event.target.value })}
              inputProps={{
                type: "number",
              }}
            /></GridItem>
            <GridItem xs={6}><CustomInput
              labelText="Probability of Occurrence (1-5)"
              id="occurrence"
              formControlProps={{
                fullWidth: true,
              }}
              value={issue.occurrence}
              onChange={(event) => setIssue({ ...issue, occurrence: event.target.value })}
              inputProps={{
                type: "number",
              }}
            /></GridItem>
          </GridContainer>
          <CustomInput
            labelText="Controls"
            id="Controls"
            formControlProps={{
              fullWidth: true,
            }}
            onChange={(event) => setIssue({ ...issue, controls: event.target.value })}
            value={issue.controls}
            inputProps={{
              type: "text",
              multiline: true,
              rows: 3,
              required: true,
            }}
          />
          <GridContainer>
            <GridItem xs={6}><CustomInput
              labelText="New Impact Score (1-5)"
              id="newScore"
              formControlProps={{
                fullWidth: true,
              }}
              value={issue.newScore}
              onChange={(event) => setIssue({ ...issue, newScore: event.target.value })}
              inputProps={{
                type: "number",
              }}
            /></GridItem>
            <GridItem xs={6}><CustomInput
              labelText="New Probability of Occurrence (1-5)"
              id="newOccurrence"
              formControlProps={{
                fullWidth: true,
              }}
              value={issue.newOccurrence}
              onChange={(event) => setIssue({ ...issue, newOccurrence: event.target.value })}
              inputProps={{
                type: "number",
              }}
            /></GridItem>
          </GridContainer>
          <GridContainer> <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Choose Image Format
                </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                inputProps={{
                  name: "issueSelect",
                  id: "issue-select",
                }}
                value={imageUploadSelect}
                onChange={handleImageSelectChange}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                  value={0}
                >
                  Choose Image Format
                  </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={1}
                >
                  Base64
                  </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={2}
                >
                  Plotly
                  </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={3}
                >
                  Embed Link
                  </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={4}
                // disabled
                >
                  Upload an Image
                  </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
            <GridItem xs={12} sm={6}>
              {imageUploadSelect === 2 && (
                <CustomInput
                  labelText="Plotly Image"
                  id="plotly"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={issueImage}
                  onChange={(event) => setIssueImage(event.target.value)}
                  inputProps={{
                    type: "text",
                  }}
                />
              )}
              {imageUploadSelect === 1 && (
                <CustomInput
                  labelText="Base64 Image"
                  id="base64"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={issueImage}
                  onChange={(event) => setIssueImage(event.target.value)}
                  inputProps={{
                    type: "text",
                  }}
                />
              )}
              {imageUploadSelect === 3 && (
                <CustomInput
                  labelText="Embed Link"
                  id="embed"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={issueImage}
                  onChange={(event) => setIssueImage(event.target.value)}
                  inputProps={{
                    type: "text",
                  }}
                />
              )}
              {imageUploadSelect === 4 && (
                <GridContainer>
                  <GridItem md={4}>
                    <Button onClick={handleOpen} block color="info">
                      Add Image
                    </Button>
                  </GridItem>

                  <MuiThemeProvider theme={theme}>
                    <DropzoneDialog
                      open={openDropZone}
                      onSave={handleSave}
                      acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                      showPreviews={true}
                      filesLimit={1}
                      onClose={handleClose}
                    />
                  </MuiThemeProvider>
                  <GridItem md={8}>
                    {!!issueImage && <span>{issueImage.name}</span>}
                  </GridItem>
                </GridContainer>
              )}
            </GridItem></GridContainer>
        </CardBody>
        <CardFooter>
          <Button
            disabled={issue === ""}
            color="quSandBoxColor"
            onClick={handleIssueSubmit}
          >
            Save
          </Button>
        </CardFooter>
      </Card>
      {props.issues.length > 0 && (
        <Card>
          <CardHeader color="quSandBox" icon>
            <CardIcon color="quSandBox">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Issues</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                "#",
                "ID",
                "Last Update",
                "Aspects",
                // "Impacts",
                // "Impact Score",
                // "Probability of Occurrence",
                "Risk Score",
                // "",
                // "Risk Controls",
                // "New Impact Score",
                // "New Probability of Occurrence",
                "New Risk Score",
                // "",
                "Operations"
              ]}
              tableData={props.issues.map((issue, id) => [
                id + 1,
                issue.PK.split("#")[1],
                new Date(issue.UpdatedAt * 1000).toLocaleString(),
                issue.Content.aspects,
                // issue.Content.impacts,
                // issue.Content.score,
                // issue.Content.occurrence,
                (issue && issue.hasOwnProperty('Content') && issue.Content.score && issue.Content.occurrence) ? referenceMetrics[scoreFormatHelper(issue.Content.score)][scoreFormatHelper(issue.Content.occurrence)] : "",
                // (issue && issue.hasOwnProperty('Content') && issue.Content.score && issue.Content.occurrence) ? <div className={cssMetric[scoreFormatHelper(issue.Content.score)][scoreFormatHelper(issue.Content.occurrence)]}>{"a"}</div> : "",
                // issue.Content.controls,
                // issue.Content.newScore,
                // issue.Content.newOccurrence,
                (issue && issue.hasOwnProperty('Content') && issue.Content.newScore && issue.Content.newOccurrence) ? referenceMetrics[scoreFormatHelper(issue.Content.newScore)][scoreFormatHelper(issue.Content.newOccurrence)] : "",
                // (issue && issue.hasOwnProperty('Content') && issue.Content.newScore && issue.Content.newOccurrence) ? <div className={cssMetric[scoreFormatHelper(issue.Content.newScore)][scoreFormatHelper(issue.Content.newOccurrence)]}>{"a"}</div> : "",
                <div key={id}>
                  <Tooltip
                    id="tooltip-top"
                    title="View Issue"
                    placement="bottom"
                    classes={{ tooltip: dashboardClasses.tooltip }}
                  >
                    <Button
                      color="transparent"
                      size="sm"
                      onClick={() => handleViewIssue(issue)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Delete Issue"
                    placement="bottom"
                    classes={{ tooltip: dashboardClasses.tooltip }}
                  >
                    <Button
                      color="transparent"
                      size="sm"
                      onClick={() => deleteAlert(issue)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </div>,
              ])}
            />
          </CardBody>
        </Card>
      )}
    </div>
  );
}
